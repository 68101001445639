import React from "react";

import Dict from "models/Dict";
import { DepartmentFilter } from "models/Department";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useDepartmentUtils from "hooks/utils/UseDepartmentUtils";

export default function DepartmentFilterModal({
  filter,
  onSubmit,
}: {
  filter: DepartmentFilter;
  onSubmit: (data: Dict) => void;
}) {
  const departmentUtils = useDepartmentUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Input name="name" label="Name" maxLength={256} />

        <div className="flex gap-2">
          <DatePickerInputRange name="dateAdd" label="Date Add" />
        </div>

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={departmentUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
