import htmlReactParse, {
  Element,
  DOMNode,
  attributesToProps,
} from "html-react-parser";

import { NetworkImage } from "components/common/NetworkImage";

import { Command, TextEditorType } from "components/common/Forms/TextEditor";
import Dict from "models/Dict";
import Attachment from "models/Attachment";
import { base64toFile, listLast, getFileType } from "services/UtilServices";
import useApiServices, { ApiVariables } from "./api/UseApiServices";
import useExportApi from "./api/UseExportApi";

export default function useTextEditorUtils() {
  const apiServices = useApiServices();
  const exportApi = useExportApi();

  const hasLargeFiles = ({ value }: { value?: TextEditorType }) => {
    let _result = false;
    var parser = new DOMParser();
    const doc = parser.parseFromString(value?.content ?? "", "text/html");
    const imageNodes = doc.getElementsByTagName("img");

    _result = Array.from(imageNodes)
      .filter((e) => e.src.includes("base64") && !e.alt.startsWith("efforts/"))
      .some((e) => {
        let ex = e.src.split(";")[0].split("/")[1];
        var file = base64toFile(e.src, ex);

        return file.size > 5000000;
      });

    if (!_result) {
      _result =
        value?.attachments
          ?.filter((e) => e.url.includes("base64"))
          .some((e) => {
            let ex = e.url.split(";")[0].split("/")[1];
            var file = base64toFile(e.url, ex);

            return file.size > 5000000;
          }) ?? false;
    }

    return _result;
  };

  const server2Dict = (v?: string | Dict | TextEditorType | null) => {
    let _result = v as TextEditorType | null | undefined;

    if (typeof _result === "string") {
      try {
        _result = JSON.parse(_result) as TextEditorType;
      } catch (_) {
        _result = {
          content: _result,
          attachments: [] as Attachment[],
        } as TextEditorType;
      }
    }

    return _result;
  };

  const uploadImages = async ({
    value,
    fieldName,
    effortId,
  }: {
    value?: TextEditorType;
    fieldName: string;
    effortId: number;
  }) => {
    var parser = new DOMParser();
    const doc = parser.parseFromString(value?.content ?? "", "text/html");
    const images = doc.getElementsByTagName("img");

    for (let index = 0; index < images.length; index++) {
      const imageSrc = images[index].src;

      if (
        imageSrc.includes("base64") &&
        !images[index].alt.startsWith("efforts/")
      ) {
        let ex = imageSrc.split(";")[0].split("/")[1];
        var file = base64toFile(imageSrc, ex);

        let _url = await apiServices.sendFile({
          file: file,
          fieldName,
          effortId,
        });

        images[index].setAttribute("alt", _url);
      }

      images[index].src = "";
    }

    for (const eachAttachment of value?.attachments ?? []) {
      if (eachAttachment.url.includes("base64")) {
        let ex = eachAttachment.url.split(";")[0].split("/")[1];
        var file = base64toFile(eachAttachment.url, ex);
        eachAttachment.url = await apiServices.sendFile({
          file,
          fieldName,
          effortId,
        });
      }
    }

    return {
      ...value,
      content: doc.documentElement.getElementsByTagName("body")[0].innerHTML,
    };
  };

  const downloadImages = async ({ content }: { content: string }) => {
    var parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.getElementsByTagName("img");

    for (let index = 0; index < images.length; index++) {
      const imageSrc = images[index].getAttribute("alt");

      if (imageSrc) {
        // var base64ImageContent = imageSrc.replace(/^data:image\/(.+?);base64,/, "");
        // let ex = imageSrc.split(";")[0].split("/")[1];
        // var image = base64toFile(imageSrc, ex);

        let _blob = "";
        try {
          _blob = await exportApi.getImageSecurly(imageSrc);
        } catch (e) {
          _blob = "/images/default.jpg";
        }

        images[index].src = _blob;
      }
    }

    return doc.documentElement.getElementsByTagName("body")[0].innerHTML;
  };

  const parse = (htmlContent?: string, imageProps?: Dict) => {
    return htmlContent
      ? htmlReactParse(htmlContent, {
        replace: (domNode) => {
          let _result: DOMNode | React.ReactNode = domNode;
          let _domNode = domNode as Element;

          let _dataSrc = _domNode.attributes?.find((e) => e.name === "alt");
          if (_domNode.tagName === "img" && _dataSrc) {
            const props = attributesToProps(_domNode.attribs);
            _result = (
              <NetworkImage
                {...props}
                src={ApiVariables.SECURE_FILE_URL + _dataSrc.value}
                className="object-contain max-w-[300px] min-h-[100px] inline-block"
                style={{
                  ...props.style,
                }}
                {...imageProps}
              />
            );
          }

          return _result;
        },
      })
      : undefined;
  };

  const getImages = (content?: TextEditorType) => {
    var parser = new DOMParser();
    const doc = parser.parseFromString(content?.content ?? "", "text/html");

    const imgs = Array.from(doc.getElementsByTagName("img"))
      .map((e) => e.getAttribute("alt"))
      .filter((e) => e)
      .map((e) => ({ url: e as string, name: listLast(e?.split("/")) }));

    const attachments = content?.attachments
      ?.filter((e) => getFileType(e.url) === "image")
      .map((e) => ({ url: e.url, name: e.name }));

    return [...(imgs ?? []), ...(attachments ?? [])];
  };

  return {
    uploadImages,
    downloadImages,
    parse,
    getImages,
    server2Dict,
    hasLargeFiles,
  };
}
