import useAuthUtils from "hooks/UseAuthUtils";
import { CommentFilter } from "models/Comment";
import Dict from "models/Dict";
import { MemberAccessTypes } from "models/Member";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { isDateUntil, hasOtherKeysExcept } from "services/UtilServices";
import Comment from "models/Comment"
import useTextEditorUtils from "hooks/UseTextEditorUtils";


export function useCommentAuthUtils(effortId?: number | null) {
  const _authUtils = useAuthUtils();
  const _currentUserContext = useCurrentUserContext();

  const canCreate = () => {
    let accessType = _authUtils!.authorize(effortId);
    return accessType >= MemberAccessTypes.Create;
  }

  const canEdit = (item: Comment) => {
    let accessType = _authUtils!.authorize(effortId);
    return accessType >= MemberAccessTypes.Edit ||
      (
        accessType >= MemberAccessTypes.Create &&
        _currentUserContext.user?.id === item.userId &&
        isDateUntil({ date: item.dateAdd, minutes: 2 })
      );
  }

  const canDelete = (items: Comment[]) => {
    let accessType = _authUtils!.authorize(effortId);
    return accessType >= MemberAccessTypes.Delete ||
      (
        accessType >= MemberAccessTypes.Create && //Edit
        items.every(e => _currentUserContext.user?.id === e.userId) &&
        items.every(e => isDateUntil({ date: e.dateAdd, minutes: 2 }))
      );
  }

  return {
    canCreate,
    canEdit,
    canDelete,
  }
}

export default function useCommentUtils() {
  const textEditorUtils = useTextEditorUtils();


  const isFilterActive = ({ filter }: { filter: CommentFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }



  const getParentCommentsDeep = (comments: Comment[], effortId: number) => {
    let _result: Comment[] = [];
    let comment = getCommentById(comments, effortId)!;

    let _currentParent = getCommentById(comments, comment.parentId)!;
    while (_currentParent !== undefined) {
      _result.push(_currentParent);
      _currentParent = getCommentById(comments, _currentParent.parentId)!;
    }

    return _result;
  }




  const getCommentById = (comments: Comment[], commentId?: number | null) => {
    return comments.find(e => e.id === commentId);
  }



  const sortByReply = (comments: Comment[], parentId?: number | null) => {
    parentId = parentId ?? null;
    let result: Comment[] = [];

    let currentSubs = comments.filter(e => e.parentId === parentId);
    for (const eachSub of currentSubs) {
      result = [
        ...result,
        eachSub,
        ...sortByReply(comments, eachSub.id),
      ];
    }

    return result;
  }



  const getSubComments = (comments: Comment[], commentId: number) => {
    return comments.filter(e => e.parentId === commentId);
  }




  const getSubCommentsDeep = (comments: Comment[], commentId: number) => {
    let comment = comments.find(e => e.id === commentId)!;
    let _result: Comment[] = [comment];

    const subWfforts = getSubComments(comments, commentId);
    for (const eachComment of subWfforts) {
      _result = [
        ..._result,
        ...getSubCommentsDeep(comments, eachComment.id)
      ];
    }

    return _result;
  }




  const getAllRepliesIds = (comments?: Comment[]) => {
    let _result: number[] = [];

    comments?.forEach(e => {
      _result = [
        ..._result,
        e.id,
        ...getAllRepliesIds(e.children)
      ];
    })

    return _result;
  }




  const getCommentsTree = (comments: Comment[]) => {
    return connectComments(comments)
      .filter(e => e.repliedTo === undefined || e.repliedTo === null);
  }




  const connectComments = (comments: Comment[]) => {
    return comments.map(eachComment => {

      eachComment.children = [];

      return eachComment;
    }).map(eachComment => {

      let _repliedTo = comments.find(
        e => eachComment.repliedTo?.id === e.id
      );

      if (_repliedTo) {
        _repliedTo.children.push(eachComment);
        eachComment.repliedTo = _repliedTo;
      }

      return eachComment;
    });
  }


  const serverResponse2Comment = (d: Dict) => {
    return {
      ...d,
      content: textEditorUtils.server2Dict(d.content),
    } as Comment;
  }

  return {
    isFilterActive,
    getParentCommentsDeep,
    getCommentById,
    sortByReply,
    getSubComments,
    getSubCommentsDeep,
    getAllRepliesIds,
    getCommentsTree,
    connectComments,
    serverResponse2Comment,
  }
}
