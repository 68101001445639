import React from "react";

import Dict from "models/Dict";
import Effort, { EffortFilter, EffortSortTypes } from "models/Effort";

import { isEmpty } from "services/UtilServices";
import useEffortApi from "hooks/api/UseEffortApi";

import { EffortsContext } from "./EffortsProvider";
import { MainContext } from "./MainProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

interface EachEffortContextProps {
  effort: Effort | undefined | null;

  set: (data: Dict) => void;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  isLoading: boolean;
}

const EachEffortContext = React.createContext({} as EachEffortContextProps);
EachEffortContext.displayName = "EachEffortContext";

function EachEffortProvider({
  children,
  projTitle,
  taskUrl,
  subtaskUrl,
}: {
  children: React.ReactNode;
  projTitle?: string;
  taskUrl?: number;
  subtaskUrl?: number;
}) {
  const itemRef = React.useRef<Effort | undefined | null>(undefined);
  const globalFilterRef = React.useRef<EffortFilter>({
    pageNumber: -1,
    ascOrder: false,
    numberInPage: 999,
    sortBy: EffortSortTypes.ADDWHEN,
  });

  const effortApi = useEffortApi();
  const _effortUtils = useEffortUtils();

  const _currentUserContext = React.useContext(CurrentUserContext);
  const _effortsContext = React.useContext(EffortsContext);
  const _mainContext = React.useContext(MainContext);

  const [isLoading, setIsLoading] = React.useState(true);

  // const [_effort, _setEffort] = React.useState<Effort | null | undefined>(undefined);

  let _f = _effortUtils.getEffortByUrl({
    projTitle,
    taskUrl,
    subtaskUrl,
  });
  let _parents = [_f, ...(_effortUtils.getParentEffortsDeep(_f?.id) ?? [])];
  let _effort: Effort | null | undefined = null;
  if (
    _currentUserContext.isSudo() ||
    _parents?.some((a) =>
      a?.members?.some(
        (e) => e.userId === _currentUserContext.user?.id && !e.isDeleted
      )
    )
  ) {
    _effort = _f ?? null;
  }

  React.useEffect(() => {
    setEffort(undefined);
    globalFilterRef.current = {
      pageNumber: -1,
      ascOrder: false,
      sortBy: EffortSortTypes.ADDWHEN,
    };
    return () => {
      setEffort(undefined);
      globalFilterRef.current = {
        pageNumber: -1,
        ascOrder: false,
        sortBy: EffortSortTypes.ADDWHEN,
      };
    };
  }, [projTitle, taskUrl, subtaskUrl]);

  const setEffort = (o?: Effort | null) => {
    itemRef.current = o;

    if (o) {
      _mainContext.setEffort({
        ..._effort,
        ...o,
      } as Effort);
    }
  };

  React.useEffect(() => {
    if (_effort === undefined || _effort === null) {
      console.log(
        _mainContext.efforts,
        projTitle,
        taskUrl,
        subtaskUrl,
        "not found"
      );
    } else {
      effortApi
        .getById(_effort.id)
        .then((response) => {
          response.level = _effortUtils.getParentEffortsDeep(
            response.id
          ).length;
          setEffort(response);
          setIsLoading(false);
        })
        .catch(() => {
          setEffort(null);
          setIsLoading(false);
        });
    }
  }, [projTitle, taskUrl, subtaskUrl]);

  const update = async (formData: Dict) => {
    let _errors = await _effortsContext.update(formData);

    if (isEmpty(_errors)) {
      setEffort({
        ..._effort,
        ...formData,
      } as Effort);
    }

    return _errors;
  };

  const set = (data: Dict) => {
    setEffort({
      ..._effort,
      ...data,
    } as Effort);

    _mainContext.setEffort({
      ..._effort,
      ...data,
    } as Effort);
  };

  const removeMany = async (itemsToDelete: Effort[]) => {
    return await _effortsContext.removeMany(itemsToDelete);
  };

  return (
    <EachEffortContext.Provider
      value={
        {
          effort: _effort ? { ...(_effort ?? {}) } : _effort,
          isLoading,

          set,
          update,
          removeMany,
        } as EachEffortContextProps
      }
    >
      {children}
    </EachEffortContext.Provider>
  );
}

export function useEachEffortContext() {
  const _context = React.useContext(EachEffortContext);

  if (!_context) {
    throw new Error("cannot use EachEffortContext outside of its provider.");
  }

  return _context;
}

export { EachEffortContext, EachEffortProvider };
export type { EachEffortContextProps };
