import { VariantProps } from "class-variance-authority";
import { Button, buttonVariants } from "components/ui/button";
import React from "react";

import {
  Link as ReactLink,
  LinkProps as RouterLinkProps,
  To,
  useLocation,
} from "react-router-dom";
import { cn } from "services/UtilServices";

export function useLinkUtils() {
  const location = useLocation();

  const trimUrl = (url: string = "") => {
    let result = url.endsWith("/") ? url.slice(0, -1) : url;
    return result.split("#")[0].split("?")[0];
  };

  const decodeUrl = (url: string) => {
    let _result = url;

    try {
      _result = decodeURI(url);
    } catch (e) {
      // Catches a malformed URI
      console.error(e);
    }

    return _result;
  };

  const isMatch = (
    targetUrl: string,
    options?: {
      exact?: boolean;
    }
  ) => {
    let currentUrlSplitted = decodeUrl(trimUrl(location.pathname)).split("/");

    const { exact = false } = options ?? {};

    return exact
      ? trimUrl(location.pathname) === trimUrl(targetUrl)
      : trimUrl(targetUrl)
          .split("/")
          .every(
            (e, i) =>
              currentUrlSplitted.length > i && currentUrlSplitted[i] === e
          );
  };

  return {
    trimUrl,
    decodeUrl,
    isMatch,
  };
}

export interface LinkProps
  extends Omit<RouterLinkProps, "to">,
    VariantProps<typeof buttonVariants> {
  activeClassName?: string;
  activeVariant?: "primary" | "light";
  deactiveClassName?: string;
  exact?: boolean;
  href?: string;
  to?: To;
  disabled?: boolean;
}

function Link({
  href,
  exact,
  activeClassName,
  deactiveClassName,
  variant,
  activeVariant,
  to,
  className,
  size,
  ...props
}: LinkProps) {
  const linkUtils = useLinkUtils();

  const _href = href ?? to?.toString() ?? "/";
  const _isActive = linkUtils.isMatch(_href, { exact });

  return (
    <Button
      asChild
      size={size}
      variant={_isActive ? activeVariant ?? "link" : variant ?? "link"}
      className={cn(
        "hover:no-underline",
        className,
        _isActive ? activeClassName : deactiveClassName
      )}
    >
      <ReactLink to={_href} {...props}>
        {props.children}
      </ReactLink>
    </Button>
  );
}

export default Link;
