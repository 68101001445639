import React from "react";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
  useFormContext,
} from "components/common/Forms/Form";
import {
  dateGetOnlyDate,
  dateGetToday,
  objList2Options,
} from "services/UtilServices";
import TicketsCountInputField, {
  ticketIsRequired,
} from "components/pages/EffortsSection/TicketsCountInputField";
import TicketCount from "models/TicketCount";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import Activity from "models/Activity";
import useActivityUtils from "hooks/utils/UseActivityUtils";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";

function FormBody() {
  const _formContext = useFormContext();
  const _effortUtils = useEffortUtils();
  const _activityUtils = useActivityUtils(_formContext.data?.effortId);

  let _project = _effortUtils.getParentProject(_formContext.data?.effortId);

  const _canUpdateApproved = _activityUtils.canUpdateApproved(
    _formContext.data as Activity
  );

  const _replaceCountApprovedWithCount =
    _canUpdateApproved &&
    _formContext.data?.ticketsCount?.every(
      (e: TicketCount) => e.countApproved === 0
    );

  React.useEffect(() => {
    if (_formContext.data && "id" in _formContext.data) {
      _formContext.setData({
        ticketsApproved: [
          ...((_replaceCountApprovedWithCount
            ? _formContext.data.ticketsCount.map((e: TicketCount) => ({
                ...e,
                countApproved: e.count,
              }))
            : _formContext.data.ticketsCount) ?? []),
        ],
      });
    }
  }, [_formContext.data?.effortId]);

  return (
    <>
      <Select
        name="typeId"
        label="Type"
        required
        options={objList2Options(_project?.types)}
      />

      <DatePickerInput
        name="dateDone"
        label="Date"
        required
        toDate={dateGetToday()}
      />

      <Input name="description" label="Description" textarea />

      {_canUpdateApproved && (
        <Switch name="isApproved" label="Approved" value={false} />
      )}

      <div className="flex">
        <div className="flex-1">
          <TicketsCountInputField
            name="ticketsCount"
            label="Ticket"
            required
            tickets={_project?.ticketsAvailable}
            validations={[ticketIsRequired]}
          />
        </div>

        {_canUpdateApproved && (
          <div className="flex-1">
            <TicketsCountInputField
              name="ticketsApproved"
              label="Approved Ticket"
              tickets={_project?.ticketsAvailable}
              ticketCountFieldName="countApproved"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default function ActivityCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();
  const _effortsContext = useEffortsContext();
  // const _activityUtils = useActivityUtils(effortId);

  // let _project = _effortUtils.getParentProject(effortId);
  // let _tickets = _project?.ticketsAvailable;

  console.log(formData);

  // const _canUpdateApproved = _activityUtils.canUpdateApproved(
  //   formData as Activity
  // );
  // const _replaceCountApprovedWithCount =
  //   _canUpdateApproved &&
  //   formData?.ticketsCount.every((e: TicketCount) => e.countApproved === 0);

  return (
    <DialogContent title="Activities">
      <Form
        // allowInitSubmit={_replaceCountApprovedWithCount}
        data={
          formData !== undefined && "id" in formData
            ? {
                ...formData,
                dateDone: dateGetOnlyDate(formData.dateDone),
                // typeId: _project?.types?.some((e) => e.id === formData.typeId) ?
                //             formData.typeId :
                //             listFirst( _project?.types)?.id,

                // ticketsApproved: [
                //   ...((_replaceCountApprovedWithCount
                //     ? formData.ticketsCount.map((e: TicketCount) => ({
                //         ...e,
                //         countApproved: e.count,
                //       }))
                //     : formData.ticketsCount) ?? []),
                // ],
              }
            : {
                // typeId: listFirst(_project?.types)?.id,
                ...formData,
                dateDone: dateGetOnlyDate(dateGetToday()),
              }
        }
        onSubmit={async (d) => {
          const _d = { ...d };

          let _project = _effortUtils.getParentProject(_d.effortId);
          let _tickets = _project?.ticketsAvailable;

          _d.type = _project?.types?.find((e) => e.id === _d.typeId);

          _d.ticketsCount = _tickets?.map((eachTicket) => ({
            ticketId: eachTicket.id,
            ticket: eachTicket,

            count:
              _d.ticketsCount?.find(
                (a: TicketCount) => eachTicket.id === a.ticketId
              )?.count ?? 0,

            countApproved:
              _d.ticketsApproved?.find(
                (a: TicketCount) => eachTicket.id === a.ticketId
              )?.countApproved ?? 0,
          }));

          return await onSubmit(_d);
        }}
      >
        <Select
          name="effortId"
          label="Task"
          required
          options={objList2Options(
            _mainContext.efforts?.filter((e) => e.parentId),
            "title"
          )}
          disabled={
            (formData && "id" in formData) || _effortsContext.currentEffort
          }
        />

        <FormBody />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
