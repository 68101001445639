import React from "react";

import Dict from "models/Dict";
import { AxiosError } from "axios";
import Comment from "models/Comment";
import useCommentUtils from "hooks/utils/UseCommentUtils";

import useCommentApi from "hooks/api/UseCommentApi";

import { CurrentUserContext } from "./CurrentUserProvider";
import { EachEffortContext } from "./EachEffortProvider";
import useTextEditorUtils from "hooks/UseTextEditorUtils";

interface CommentsContextProps {
  comments: Comment[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Comment[]) => Promise<boolean>;

  // get: (filter: CommentFilter) => Promise<void>;
  // filter: CommentFilter;
  // hasNext: boolean,
}

const CommentsContext = React.createContext({} as CommentsContextProps);
CommentsContext.displayName = "CommentsContext";

// var items: Comment[] | undefined = undefined;
// var globalFilter: CommentFilter = {
//   pageNumber: -1,
//   ascOrder: false,
//   sortBy: CommentSortTypes.ADDWHEN,
//   effortId: "0",
// };

function CommentsProvider({
  children,
  effortId,
}: {
  children: React.ReactNode;
  effortId: number;
}) {
  // const [_items, _setComments] = React.useState<Comment[] | undefined>(
  //   _eachEffortContext.effort?.comments
  // );
  // const [_filter, _setFilter] = React.useState<CommentFilter>({
  //   pageNumber: -1,
  //   ascOrder: false,
  //   sortBy: CommentSortTypes.ADDWHEN,
  //   effortId,
  // });
  // const [hasNext, setHasNext] = React.useState<boolean>(true);

  const _currentUserContext = React.useContext(CurrentUserContext);
  const _eachEffortContext = React.useContext(EachEffortContext);

  const commentApi = useCommentApi();
  const textEditorUtils = useTextEditorUtils();
  const _commentUtils = useCommentUtils();

  // React.useEffect(() => {
  //   globalFilter = {
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: CommentSortTypes.ADDWHEN,
  //     effortId,
  //   };
  // }, [effortId]);

  // const setComments = (o?: Comment[]) => {
  //   items = o;
  //   _setComments(o);
  // };

  // const setFilter = (o: CommentFilter) => {
  //   globalFilter = o;
  //   _setFilter(o);
  // };

  // const get = async (filter: CommentFilter) => {
  //   if(JSON.stringify(filter) === JSON.stringify(globalFilter)) return;

  //     filter.effortId = effortId;
  //     setFilter(filter);

  // if(filter.pageNumber < 0)
  // {
  //   setHasNext(true);
  //   setComments(undefined);
  //   return;
  // }
  //     try {

  //       let response = await commentApi.get(filter);

  //       setHasNext(response?.length >= 20);

  //       response = response.filter((eachRes: Dict) =>
  //         !items?.find(e => e.id === eachRes.id)
  //       );

  //       setComments([
  //         ...items ?? [],

  //         ...response as Comment[]
  //       ]);
  //     }
  //     catch(e) {
  // toast({
  //
  //             description: (e as AxiosError).message,
  //             variant: "destructive",
  // });
  //       setHasNext(false);
  //       if(items === undefined) {
  //         setComments([]);
  //       }
  //     }
  // }

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.userId = _currentUserContext.user!.id;
      formData.effortId = effortId;

      formData.content = await textEditorUtils.uploadImages({
        value: formData.content,
        fieldName: "content",
        effortId,
      });

      formData = await commentApi.create(formData);

      formData.user = _currentUserContext.user;
      _eachEffortContext.set({
        comments: [
          ...(_eachEffortContext.effort?.comments ?? []),
          formData as Comment,
        ],
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.content = await textEditorUtils.uploadImages({
        value: formData.content,
        fieldName: "content",
        effortId,
      });

      await commentApi.update(formData);

      _eachEffortContext.set({
        comments: _eachEffortContext.effort?.comments?.map((e) =>
          e.id !== formData.id ? e : formData
        ),
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Comment[]) => {
    let _errors = {};

    try {
      await commentApi.remove(itemsToDelete.map((e) => e.id));

      const deletedComments = itemsToDelete.reduce(
        (result, e) => [
          ...result,
          ..._commentUtils.getSubCommentsDeep(
            _eachEffortContext.effort!.comments ?? [],
            e.id
          ),
        ],
        [] as Comment[]
      );

      // setComments(
      //   items?.filter(e => !itemsToDelete.includes(e))
      // );

      _eachEffortContext.set({
        comments: _eachEffortContext.effort?.comments?.filter(
          (e) => !deletedComments.some((a) => a.id === e.id)
        ),
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <CommentsContext.Provider
      value={
        {
          comments: _eachEffortContext.effort!.comments,
          // _commentUtils.sortByReply(_eachEffortContext.effort!.comments ?? []),
          create,
          update,
          removeMany,

          // get,
          // filter: _filter,
          // hasNext,
        } as CommentsContextProps
      }
    >
      {children}
    </CommentsContext.Provider>
  );
}

export function useCommentsContext() {
  const _context = React.useContext(CommentsContext);

  if (!_context) {
    throw new Error("cannot use CommentsContext outside of its provider.");
  }

  return _context;
}

export { CommentsContext, CommentsProvider };
export type { CommentsContextProps };
