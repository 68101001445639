import React from "react";




export default function useOnScreen(ref: React.RefObject<HTMLDivElement>) {

    const [isIntersecting, setIntersecting] = React.useState(false);


    React.useEffect(() => {
        let observer: IntersectionObserver;

        if(ref.current !== null) {
            observer = new IntersectionObserver(([entry]) => 
                            setIntersecting(entry.isIntersecting)
                        );

            observer.observe(ref.current)
        }

        return () => { 
            observer?.disconnect() 
        }
    }, [ref.current])



    return isIntersecting;
}