import React from "react";
import { useNavigate } from "react-router-dom";

import useUserUtils from "hooks/utils/UseUserUtils";

import { cn, isBright, isFarsi } from "services/UtilServices";

import { useMainContext } from "providers/MainProvider";
import { useModalContext } from "providers/ModalProvider";
import CollapseText from "components/common/CollapseText";
import { useSideMenuContext } from "providers/SideMenuProvider";

import Link from "../../common/Link";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import SearchSection, { SearchModalBody } from "./SearchSection";
import ProfileSection from "./ProfileSection";
import { useThemeContext } from "providers/ThemeProvider";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import NotificationButton from "./NotificationSection";

import { FaArrowLeft, FaBars, FaMagnifyingGlass } from "react-icons/fa6";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import MobileHamburgerButton from "../MobileHamburgerButton";
import AppLogo from "components/pages/AppLogo";

function MobileMainHeader({ children, title, back }: Props) {
  // const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _sliderContext = useSideMenuContext();
  const _currentUserContext = useCurrentUserContext();

  const navigate = useNavigate();

  return (
    <div className="flex md:hidden items-center relative -mx-3">
      <div className="absolute left-0 right-0 bottom-0 top-0 text-center flex items-center justify-center">
        {typeof title === "string" ? (
          <span
            className={cn("h6 mb-0 truncate w-1/2 inline-block ", {
              "direction-rtl": typeof title === "string" && isFarsi(title),
            })}
          >
            {/* { 
              typeof title === "string" ? 
                <CollapseText text={ title } afterNthChar={15} /> :
                title
            } */}
            {title}
          </span>
        ) : (
          title
        )}
      </div>

      {back && (
        <Button
          variant={"light"}
          className=" relative  "
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
        </Button>
      )}

      {children}

      {/* <Button
        onClick={() =>
          _sliderContext.show({
            dir: "left",
            content: <MobileSideMenu />,
          })
        }
        variant={"light"}
        className=" relative "
      >
        <FaBars />
      </Button> */}

      <MobileHamburgerButton />

      <div className="flex-1"></div>

      <NotificationButton />

      <Button
        variant={"light"}
        className=" relative"
        onClick={() =>
          _modalContext.open(
            <DialogContent onBgClickClosable>
              <div className=" -m-4 h-[95vh]">
                <SearchModalBody onDismiss={() => _modalContext.dismiss()} />
              </div>
            </DialogContent>
          )
        }
      >
        <FaMagnifyingGlass />
      </Button>

      <Link
        href="/profile/"
        variant={"light"}
        className=" mx-2 p-0 rounded-full relative "
      >
        <ProfileAvatar user={_currentUserContext.user} />
      </Link>
    </div>
  );
}

function LaptopMainHeader({ children, title }: Props) {
  const _themeContext = useThemeContext();

  return (
    <div className="hidden md:block">
      <div className="flex items-center ">
        <Link
          href="/"
          variant={"outline-light"}
          className="border-0 p-0 bg-transparent "
        >
          <AppLogo className="hover:brightness-100 hover:invert-0 h-7 " />
        </Link>

        <div className="mx-auto w-full px-4 max-w-lg h-7 ">
          <SearchSection />
        </div>

        <NotificationButton />

        <ProfileSection />
      </div>
    </div>
  );
}

interface Props {
  children?: React.ReactNode;
  title?: React.ReactNode;
  sideMenu?: React.ReactNode;
  back?: boolean;
}

export default function MainHeader({ children, title, back = false }: Props) {
  const _themeContext = useThemeContext();

  return (
    <header
      className={cn("w-full top-0 right-0 left-0 z-100 border-b")}
      // style={{
      //   background: _themeContext.themeColor,
      // }}
    >
      <div className="px-3 py-2 ">
        {children ? (
          <div className="flex lg:hidden items-center relative h-10 -my-3">
            <div className="absolute left-0 right-0 bottom-0 top-0 text-center flex items-center justify-center">
              <span className="mb-0">
                {typeof title === "string" ? (
                  <CollapseText text={title} maxLength={15} />
                ) : (
                  title
                )}
              </span>
            </div>

            {children}
          </div>
        ) : (
          <MobileMainHeader title={title} back={back} />
        )}

        <LaptopMainHeader />
      </div>
    </header>
  );
}
