import React from "react";
import { useNavigate } from "react-router";

import useUserUtils from "hooks/utils/UseUserUtils";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import TagsSection from "./TagsSection";
import TypesSection from "./TypesSection";
import StatesSection from "./StatesSection";
import PrefixsSection from "./PrefixsSection";
import TicketsAvailableSection from "./TicketsAvailableSection";
import Skeleton from "components/common/Skeleton";
import PrioritiesSection from "./PrioritiesSection";
import MembersSection from "../MembersSection/MembersSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import { TextEditorValue } from "components/common/Forms/TextEditor";
import AttachmentsSection from "../AttachmentsSection/AttachmentsSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import useSingleFieldUpdate, {
  SingleFieldUpdatePen,
} from "components/pages/EffortsSection/SingleFieldUpdateButton";
import LocalDateTime from "components/common/LocalDateTime";
import TicketsCountSection from "./TicketsCountSection";
import EffortMenuButton from "../EffortMenuButton";
import { LuPencil } from "react-icons/lu";
import { Button } from "components/ui/button";

export default function SettingSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();
  const singleFieldUpdate = useSingleFieldUpdate();

  return (
    <div>
      <div className="row ">
        <div className="flex items-center text-sm pb-3 w-full text-foreground">
          <EffortMenuButton effort={effort} />

          {effort.creator ? (
            <span>
              <ProfileAvatar user={effort.creator} needName />
            </span>
          ) : (
            <Skeleton />
          )}

          <div className="flex-1"></div>
          <LocalDateTime date={effort.dateAdd} className="text-xs" />

          {/* <MenuButton
            ellipsisResponsiveBreakPoint={"never"}
            onEdit={
              _effortUtils.canEdit({
                currentUser: _currentUserContext.user!,
                allEfforts: _mainContext.efforts!,
                items: [effort],
              })
                ? openCreateUpdateModal
                : undefined
            }
            onDelete={
              _effortUtils.canDelete({
                currentUser: _currentUserContext.user!,
                allEfforts: _mainContext.efforts!,
                items: [effort],
              })
                ? async () => {
                    let nextUrl = effort.parentId
                      ? _effortUtils.getUrl(
                          _mainContext.efforts!,
                          effort.parentId
                        )
                      : "/";
                    const _errors = await _eachEffortContext.removeMany([
                      effort,
                    ]);

                    if (isEmpty(_errors) && nextUrl) {
                      navigate(nextUrl);
                    }

                    return _errors;
                  }
                : undefined
            }
            items={[
              <ClipboardTrigger content={window.location.href}>
                <LuCopy />
              </ClipboardTrigger>,
            ]}
          /> */}
        </div>

        <div className="col-12 p-0 mb-1">
          {effort.description?.content || effort.description?.attachments ? (
            <div className="bg-card p-2 rounded border relative group ">
              <TextEditorValue value={effort.description} />
              <SingleFieldUpdatePen
                effort={effort}
                fieldName="description"
                variant={"light"}
                className="absolute right-0 top-0 m-3 bg-muted aspect-square rounded-full border"
              />
            </div>
          ) : (
            <Button
              variant={"faded"}
              onClick={() =>
                singleFieldUpdate.onClick({
                  effort,
                  fieldName: "description",
                })
              }
              className="text-sm p-4 border bg-muted w-full"
            >
              Add Description <LuPencil className="!text-base" />
            </Button>
          )}
        </div>

        <TicketsCountSection effort={effort} />
        <MembersSection effort={effort} />
        <AttachmentsSection effort={effort} />

        {_effortUtils.isProject(effort) && (
          <>
            <StatesSection effort={effort} />
            <PrefixsSection effort={effort} />
            <PrioritiesSection effort={effort} />
            <TicketsAvailableSection effort={effort} />
          </>
        )}

        <TypesSection effort={effort} />
        <TagsSection effort={effort} />
      </div>

      <MobileFooterPadding />
    </div>
  );
}
