import { AuditLogFilter } from "models/AuditLog";
import { hasOtherKeysExcept } from "services/UtilServices";

export default function useAuditLogUtils() {

  const isFilterActive = ({ filter, pageType = "profile" }: { filter: AuditLogFilter, pageType: string | undefined }) => {
    return hasOtherKeysExcept(filter,
      ["pageNumber", "ascOrder", "sortBy", "numberInPage",
        ...pageType === "profile" ? ["userIds"] : [],
      ]
    );
  }

  return {
    isFilterActive
  }
}
