import React from "react";

import Dict from "../../../models/Dict";

import { useFormContext } from "./Form";
import DeleteButton from "../DeleteButton";
import FormFieldBase, { FormFieldBaseInputProps } from "./FormFieldBase";

import { FaPen, FaTrashCan } from "react-icons/fa6";
import { LuPlusCircle } from "react-icons/lu";
import { cn, listFirst } from "services/UtilServices";
import { Button } from "components/ui/button";
import {
  OptionType,
  OptionValueType,
  SelectPrimitive,
} from "components/ui/select";

export interface SelectInputProps extends FormFieldBaseInputProps {
  wrapperClassName?: string;
  options?: OptionType[];
  multiple?: boolean;
  inline?: boolean;

  onEdit?: (selectedOptionValue: OptionValueType) => void;
  onDelete?: (selectedOptionValue: OptionValueType) => void;
}

export default function Select(props: SelectInputProps) {
  const _formContext = useFormContext();
  const [_searchedValue, _setSearchedValue] = React.useState(["", ""]);

  const {
    name,
    label,
    wrapperClassName = "mb-3",
    value,
    options,
    validations,
    required = false,
    multiple,
    inline = false,
    disabled,

    onCreate,
    onEdit,
    onDelete,

    ...rest
  } = props;

  React.useEffect(() => {
    // console.log(name, required ,
    //   multiple ,
    //   options,
    //   name in _formContext.data,
    //   _formContext.data[name])

    if (value !== undefined && !(name in _formContext.data)) {
      _formContext.setData({ [name]: value });
    } else if (
      required &&
      !multiple &&
      options !== undefined &&
      options.length > 0 &&
      (!(name in _formContext.data) ||
        _formContext.data[name] === undefined ||
        _formContext.data[name] === null ||
        !options.some((e) => e.value === _formContext.data[name]))
    ) {
      // console.log("ehsannnnnnnnnnnnnnnnnn", { [name]: options[0].value })
      _formContext.setData({ [name]: options[0].value });
    }
  }, [value, options]);

  // React.useEffect(() => {

  //   if(value !== undefined && !(name in _formContext.data)) {
  //     _formContext.setData({ [name]: value });
  //   }
  //   else if(
  //     required &&
  //     !multiple &&
  //     options.length > 0 &&
  //     (
  //       !(name in _formContext.data) ||
  //       _formContext.data[name] === undefined
  //     )
  //   ) {
  //     _formContext.setData({
  //       [name]: options[0].value,
  //     });
  //   }

  // }, [options]);

  const handleChange = (selectedOptions?: OptionType[] | null) => {
    if (!selectedOptions) return;

    if (multiple) {
      _setSearchedValue([_searchedValue[1], _searchedValue[1]]);
    }

    let _selectedOptionValue: OptionValueType[] | OptionValueType | null;
    if (multiple) {
      _selectedOptionValue = (selectedOptions as OptionType[]).map(
        (eachOpt) => eachOpt.value
      );
    } else {
      _selectedOptionValue = listFirst(selectedOptions)?.value;
    }

    _formContext.setData({ [name]: _selectedOptionValue });
  };

  let _selectedOption: OptionType[] | null = null;
  if (name in _formContext.data && options !== undefined) {
    if (multiple) {
      _selectedOption =
        _formContext.data[name]?.map((eachData: string | Dict) =>
          options.find((e) => e.value === eachData)
        ) ?? [];
    } else {
      _selectedOption = options.filter(
        (e) => e.value === _formContext.data[name]
      );
    }
  }

  // console.log(_formContext.data[name], _selectedOption)

  return (
    <FormFieldBase
      {...props}
      needNullBtn={
        props.needNullBtn === undefined
          ? !required && !multiple
          : props.needNullBtn
      }
      actions={[
        ...(props.actions ?? []),
        ...(onCreate
          ? [
            {
              icon: <LuPlusCircle />,
              text: "جدید",
              onClick: onCreate,
            },
          ]
          : []),
      ]}
      description={
        multiple || listFirst(_selectedOption)?.description === undefined
          ? props.description
          : listFirst(_selectedOption)?.description
      }
    >
      {(baseProps) => (
        <SelectPrimitive
          id={name}
          name={name}
          className={cn("capitalize ", { "is-invalid": baseProps.error })}
          placeholder={options === undefined ? "Loading..." : "Select..."}
          value={_selectedOption}
          options={options?.map((e) => ({
            ...e,
            onEdit: onEdit ? () => onEdit(e.value) : undefined,
            onDelete: onDelete ? () => onDelete(e.value) : undefined,
          }))}
          onChange={handleChange}
          multiple={multiple}
          // closeMenuOnSelect={!multiple}
          // hideSelectedOptions={false}
          // isSearchable={true}
          // openMenuOnFocus={true}
          // onInputChange={
          //   multiple
          //     ? (v) => {
          //         _setSearchedValue([_searchedValue[1], v]);
          //       }
          //     : undefined
          // }
          // inputValue={multiple ? _searchedValue[1] : undefined}
          // components={{ Option }}
          disabled={disabled || options === undefined || baseProps.isLoading}
          {...rest}
        />
      )}
    </FormFieldBase>
  );
}
