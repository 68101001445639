import React from "react";

import Dict from "models/Dict";
import Activity, { ActivitySortType } from "models/Activity";

import {
  cn,
  convert2Date,
  getOnlyFields,
  isEmpty,
  isFarsi,
} from "services/UtilServices";

import { useMainContext } from "providers/MainProvider";
import { TypesProvider } from "providers/TypesProvider";
import { useEachEffortContext } from "providers/EachEffortProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import {
  useActivitysContext,
  ActivitysProvider,
} from "providers/ActivitysProvider";

import ExportModal from "./ExportModal";
import Cheeps from "components/common/Cheeps";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import LocalDateTime from "components/common/LocalDateTime";
import { FieldType } from "components/common/ItemsListSection/ItemsListProvider";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";

import { Button } from "components/ui/button";
import { GrUserAdmin } from "react-icons/gr";
import { LuCheck, LuCopy, LuDownload, LuShirt } from "react-icons/lu";
import useActivityUtils, {
  useActivityViewUtils,
} from "hooks/utils/UseActivityUtils";
import { Separator } from "components/ui/separator";
import EffortBanner from "components/pages/EffortsSection/EffortBanner";
import { UsersProvider, useUsersContext } from "providers/UsersProvider";
import useUserUtils from "hooks/utils/UseUserUtils";
import UserProfileHoverCard from "../../admin/UsersSection/UserProfileHoverCard";

function EachActivityTitleRow({
  activity,
  index,
}: {
  activity: Activity;
  index: number;
}) {
  const _mainContext = useMainContext();
  const _eachEffortContext = useEachEffortContext();
  const _userUtils = useUserUtils();

  const _effort = _mainContext.efforts?.find((e) => e.id === activity.effortId);
  // const _approverUser = _usersContext.users?.find((e) => e.id === activity.approverList[0].userId);

  return (
    <div className="w-full">
      {activity.isApproved && (
        <>
          <div className="absolute -right-3 -top-3 -left-3 rounded-t bg-green-400/20 p-2 text-xs text-green-500 ">
            <div className="flex items-center gap-1">
              <LuCheck className=" w-4" /> Approved
              <div className="flex-1"></div>
              {!isEmpty(activity.approverList) && (
                <LocalDateTime
                  date={activity.approverList[0]?.dateCreated}
                  className="text-xs"
                />
              )}
            </div>
            {!isEmpty(activity.approverList) && (
              <div className="flex items-center gap-1 mt-1">
                <GrUserAdmin className="!text-base w-4" />
                By
                <span className="hover:underline">
                  <ProfileAvatar
                    side="left"
                    needName
                    needAvatar={false}
                    user={activity.approverList[0]?.user}
                  />
                </span>
              </div>
            )}
          </div>

          <div
            className={isEmpty(activity.approverList) ? "py-4" : "py-7"}
          ></div>
        </>
      )}

      {_effort && _eachEffortContext.effort?.id !== _effort.id && (
        <div className="flex items-center gap-1 mb-2 text-xs font-normal">
          <EffortBanner
            effort={_effort}
            className="object-cover aspect-square border rounded h-7 w-7"
          />

          {_effort.prefix && (
            <span className="mb-0 inline-block ">{_effort.prefix?.name}</span>
          )}

          <span
            className={cn("mb-0 inline-block text-start whitespace-normal ", {
              "direction-rtl": isFarsi(_effort.title),
            })}
          >
            {_effort.title}
          </span>
        </div>
      )}
      <div className="flex items-center w-full gap-1 font-normal text-xs">
        <span>{activity.id} - </span>
        <ProfileAvatar size={30} user={activity.user} needName />

        <div className="flex-1"></div>
        <LocalDateTime date={activity.dateAdd} />
      </div>
    </div>
  );
}

function EachActivityItem({
  activity,
  index,
}: {
  activity: Activity;
  index: number;
}) {
  const _anyCountApproved = activity.ticketsCount.some((e) => e.countApproved);
  const _isCountApproved = activity.ticketsCount.every(
    (e) => e.count === e.countApproved
  );

  return (
    <div className="w-full mt-1">
      <div className="flex justify-between">
        <Cheeps borderColor={activity.type.color}>{activity.type.name}</Cheeps>
      </div>

      <Separator className="my-2" />

      <div className="my-1">
        {_isCountApproved ? (
          <TicketsCheepsSection
            fieldName={"count"}
            ticketCounts={activity.ticketsCount}
            wrap
            needIcon
            icon={
              <>
                <LuShirt />
                <GrUserAdmin />
              </>
            }
          />
        ) : (
          <TicketsCheepsSection
            fieldName={"count"}
            ticketCounts={activity.ticketsCount}
            hideOverritens={_anyCountApproved}
            wrap
            needIcon
          />
        )}
      </div>

      {!_isCountApproved && (
        <TicketsCheepsSection
          fieldName={"countApproved"}
          ticketCounts={activity.ticketsCount}
          wrap
          icon={<GrUserAdmin />}
        />
      )}
    </div>
  );
}

function ActivitysPageBody() {
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _activitysContext = useActivitysContext();
  const _eachEffortContext = useEachEffortContext();
  const activityUtils = useActivityUtils(_eachEffortContext.effort?.id);
  const activityViewUtils = useActivityViewUtils(_eachEffortContext.effort?.id);

  const _isHome = _eachEffortContext.effort === undefined;
  const _isProject = _eachEffortContext.effort?.parentId === null;

  return (
    <div className="">
      <ItemsListSection<Activity>
        title="Activities"
        data={_activitysContext.activitys}
        tableBreakPointSize={"never"}
        ellipsisResponsiveBreakPoint="always"
        fields={[
          {
            value: "",
            label: "#",
            className: "text-start whitespace-nowrap",
            width: "70px",
            isSortable: false,
            getUiContent: (e, i) => i + 1,
          },
          {
            value: "id",
            label: "Id",
            className: "text-start whitespace-nowrap",
            width: "70px",
            // isSortable: false,
            // getUiContent: (e, i) => i+1,
          },
          {
            value: "user",
            label: "user",
            width: "auto",
            // isSortable: false,
            getUiContent: (e, i) => <ProfileAvatar user={e.user} />,
            className: "whitespace-nowrap",
          },

          ...(_isProject
            ? ([
                {
                  value: "effortId",
                  label: "task",
                  width: "auto",
                  isSortable: false,
                  className: "text-start whitespace-nowrap",
                  getUiContent: (eachItem, i) => {
                    const _effort = _mainContext.efforts?.find(
                      (e) => e.id === eachItem.effortId
                    );

                    return (
                      <span
                        className={cn("capitalize inline-block ", {
                          "direction-rtl": isFarsi(_effort?.title),
                        })}
                      >
                        {_effort?.title}
                      </span>
                    );
                  },
                },
              ] as FieldType<Activity>[])
            : []),

          {
            value: "description",
            label: "Description",
            width: "200px",
            // isSortable: false,
          },
          {
            value: "type",
            label: "type",
            width: "auto",
            // isSortable: false,
            getUiContent: (e, i) => (
              <Cheeps borderColor={e.type.color}>{e.type.name}</Cheeps>
            ),
          },
          {
            value: "ticketsCount",
            label: "tickets",
            width: "30%",
            isSortable: false,
            getUiContent: (p) => (
              <TicketsCheepsSection ticketCounts={p.ticketsCount} />
            ),
          },
          {
            value: "ticketApproved",
            label: "Ticket Approved",
            width: "auto",
            isSortable: false,
            getUiContent: (p) => (
              <TicketsCheepsSection
                ticketCounts={p.ticketsCount}
                fieldName="countApproved"
              />
            ),
          },
          {
            value: "dateAdd",
            label: "dateAdd",
            width: "auto",
            // isSortable: false,
            getUiContent: (e, i) =>
              convert2Date(e.dateAdd)?.toLocaleDateString(),
            className: "whitespace-nowrap",
          },

          {
            value: "dateDone",
            label: "dateDone",
            width: "auto",
            // isSortable: false,
            getUiContent: (e, i) => <LocalDateTime date={e.dateDone} />,
            className: "whitespace-nowrap",
          },

          {
            value: "isApproved",
            label: "Approved",
            width: "auto",
            isSortable: true,
            getUiContent: (p) => (p.isApproved ? <LuCheck /> : null),
            // getValue: (p) => p.isApproved == true ? 1 : 0
          },
        ]}
        onLoadMore={
          !_activitysContext.hasNext
            ? undefined
            : async () => {
                await _activitysContext.get({
                  ..._activitysContext.filter,
                  pageNumber: _activitysContext.filter.pageNumber + 1,
                });
              }
        }
        onSortField={(v) => {
          _activitysContext.get({
            ..._activitysContext.filter,
            sortBy: v as ActivitySortType,
            pageNumber: -1,
          });
        }}
        onSortDir={(v) => {
          _activitysContext.get({
            ..._activitysContext.filter,
            ascOrder: v === 1,
            pageNumber: -1,
          });
        }}
        initialSelectedSortFieldValue="dateAdd"
        buildCardTitleRow={(v, i) => (
          <EachActivityTitleRow activity={v} index={i} />
        )}
        buildCardBodyItem={(v, i) => (
          <EachActivityItem activity={v} index={i} />
        )}
        onItemEdit={(item) =>
          activityUtils.canEdit(item)
            ? activityViewUtils.openCreateUpdateModal
            : undefined
        }
        onItemClick={(item) =>
          activityUtils.canEdit(item)
            ? activityViewUtils.openCreateUpdateModal
            : undefined
        }
        onItemDelete={() =>
          activityUtils.canDelete() ? _activitysContext.removeMany : undefined
        }
        getItemActions={(item) => [
          {
            text: "Duplicate",
            icon: <LuCopy />,
            onClick: () =>
              activityViewUtils.openCreateUpdateModal(
                getOnlyFields(item, [
                  "userId",
                  "effortId",
                  "typeId",
                  "description",
                  { ticketsCount: ["ticketId", "ticket", "count"] },
                ]) as { effortId: number } & Dict
              ),
          },
        ]}
        // onItemSelect={() =>
        //   activityUtils.canDelete({ currentUser: _currentUserContext.user! }) ?
        //     ()=>{} :
        //     undefined
        // }

        actionsSection={
          <>
            {_activitysContext.activitys !== undefined &&
              _activitysContext.activitys!.length > 0 && (
                <Button
                  variant={"light"}
                  className="px-2"
                  onClick={() => _modalContext.open(<ExportModal />)}
                >
                  <LuDownload />
                  {/* <span className="hidden lg:inline">Export</span> */}
                </Button>
              )}

            <FilterButton
              needText={false}
              className="border-0 px-2"
              count={_activitysContext.totalNumber}
              isActive={activityUtils.isFilterActive({
                filter: _activitysContext.filter,
              })}
              onClick={activityViewUtils.openFilterModal}
            />

            {activityUtils.canCreate(_eachEffortContext.effort) && (
              <NewButton
                needText={false}
                className="border-0 px-2"
                onClick={() =>
                  activityViewUtils.openCreateUpdateModal({
                    effortId: _eachEffortContext.effort!.id,
                  })
                }
              />
            )}
          </>
        }
      />
    </div>
  );
}

export default function ActivitysPage() {
  return (
    <UsersProvider>
      <ActivitysProvider>
        <TypesProvider>
          <ModalProvider>
            <ActivitysPageBody />
          </ModalProvider>
        </TypesProvider>
      </ActivitysProvider>
    </UsersProvider>
  );
}
