import React from "react";

import TicketCount from "models/TicketCount";
import useTicketCountUtils from "hooks/utils/UseTicketCountUtils";

import { LuShirt } from "react-icons/lu";
import { cn, isBright } from "services/UtilServices";

export function TicketCountCheeps({
  ticketCount,
  ticketCountFieldName = "count",
  className,
  hideOverritens,
}: {
  ticketCount: TicketCount;
  ticketCountFieldName?: "count" | "countApproved";
  className?: string;
  hideOverritens?: boolean;
}) {
  return (
    <div
      className={cn(
        "inline-flex items-center rounded border px-1 pt-[2px] whitespace-nowrap gap-1 ",
        isBright(ticketCount.ticket.color) ? "text-dark" : "text-white",
        {
          "opacity-50 line-through":
            ticketCountFieldName === "count" &&
            hideOverritens &&
            ticketCount.count !== ticketCount.countApproved,
        },
        className
      )}
      style={{
        background: ticketCount.ticket.color,
      }}
    >
      {ticketCount[ticketCountFieldName]}
      <b className="uppercase">{ticketCount.ticket.name ?? "no name"}</b>
    </div>
  );
}

export default function TicketsCheepsSection({
  ticketCounts,
  fieldName = "count",
  children,
  needTotal = true,
  needIcon,
  className = "",
  wrap,
  visibleIfEmpty,
  icon,
  hideOverritens = false,
}: {
  ticketCounts?: TicketCount[];
  fieldName?: "count" | "countApproved";
  children?: React.ReactNode;
  needTotal?: boolean;
  needIcon?: boolean;
  className?: string;
  wrap?: boolean;
  visibleIfEmpty?: boolean;
  icon?: React.ReactNode;
  hideOverritens?: boolean;
}) {
  const ticketCountUtils = useTicketCountUtils();
  const _total = ticketCountUtils.sumTotal(ticketCounts, fieldName);
  const _notEmptyTickets = ticketCounts?.filter((e) => e[fieldName] != 0);

  return (
    <>
      {(visibleIfEmpty ? true : _total > 0) && (
        <div
          className={cn(
            "inline-flex items-center gap-2 whitespace-nowrap ",
            className
          )}
        >
          {(needIcon || icon) && <div>{icon ?? <LuShirt />}</div>}

          <div className={cn("flex gap-1 small ", { "flex-wrap": wrap })}>
            {ticketCountUtils
              .sortByTicketName(_notEmptyTickets)
              ?.map((e, i) => (
                <TicketCountCheeps
                  key={"eachTicket" + i}
                  ticketCount={e}
                  ticketCountFieldName={fieldName}
                  hideOverritens={hideOverritens}
                />
              ))}

            {needTotal && _notEmptyTickets!.length > 1 && (
              <span
                className="inline-block pt-[2px]"
                title="sum of the tickets "
              >
                = {_total}
              </span>
            )}
          </div>

          {children}
        </div>
      )}
    </>
  );
}
