import React from "react";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import useOutsideClick from "components/common/useOutsideClick";
import { UserRoles } from "models/User";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useMainContext } from "providers/MainProvider";
import { LuBell, LuListTree, LuUserCog } from "react-icons/lu";
import { AiOutlineHome } from "react-icons/ai";
import { Separator } from "components/ui/separator";
import { PiHeadset } from "react-icons/pi";
import { Badge } from "components/ui/badge";
import { cn } from "services/UtilServices";
import { Slot } from "@radix-ui/react-slot";
import Link from "components/common/Link";
import { Button } from "components/ui/button";
import { useSideMenuContext } from "providers/SideMenuProvider";

function EachPanelItemBody({
  icon = <></>,
  text = "",
  notifCount = 0,
  isMini = false,
  small = false,
}) {
  return (
    <>
      {isMini ? (
        <div title={text}>
          {notifCount > 0 && (
            <Badge className="absolute right-1 top-1 ">{notifCount}</Badge>
          )}

          {icon}
        </div>
      ) : (
        <div className="flex items-center w-full">
          {icon}

          <span
            className={cn("ms-2 truncate mb-0 inline-block capitalize ", {
              small,
            })}
          >
            {text}
            {/* <CollapseText text={ text } afterNthChar={35} /> */}
          </span>

          {notifCount > 0 && <Badge className="ms-auto">{notifCount}</Badge>}
        </div>
      )}
    </>
  );
}

export function EachPanelItem({
  to = "",
  exact = false,
  icon = <></>,
  text = "",
  notifCount = 0,
  isMini = false,
  small = false,
  type = "LINK",
  onClick = () => {},
  isActive = false,
  className = "",
  activeClassName = "",
}) {
  const _sideMenuContext = useSideMenuContext();

  return type === "LINK" ? (
    <Link
      to={to}
      exact={exact}
      onClick={_sideMenuContext.dismiss}
      // activeClassName={
      //   "bg-primary text-primary-foreground hover:text-foreground"
      // }
      // deactiveClassName="text-primary-foreground hover:text-foreground"
      activeClassName={cn(
        "rounded-s-none border-foreground box-border",
        activeClassName
      )}
      activeVariant="light"
      variant={"light"}
      className={cn(
        "relative border-s-4 p-1 ps-0 w-full mb-1 min-h-[50px] border-transparent",
        className
      )}
    >
      <EachPanelItemBody
        icon={icon}
        text={text}
        notifCount={notifCount}
        isMini={isMini}
        small={small}
      />
    </Link>
  ) : type === "BUTTON" ? (
    <Button
      onClick={onClick}
      variant={"light"}
      className={cn(
        "relative border-s-4 p-1 ps-0 w-full mb-1 min-h-[50px] border-transparent",
        // isActive
        //   ? "bg-primary text-primary-foreground hover:text-foreground"
        //   : "", //text-primary-foreground hover:text-foreground
        className,
        isActive
          ? cn("rounded-s-none border-foreground box-border", activeClassName)
          : ""
      )}
    >
      <EachPanelItemBody
        icon={icon}
        text={text}
        notifCount={notifCount}
        isMini={isMini}
        small={small}
      />
    </Button>
  ) : type === "A" ? (
    <Button
      asChild
      variant={isActive ? "primary" : "light"}
      className={cn(
        "relative border-s-4 p-1 ps-0 w-full mb-1 min-h-[50px] border-transparent",
        // isActive
        //   ? "bg-primary text-primary-foreground hover:text-foreground"
        //   : "", //text-primary-foreground hover:text-foreground
        className
      )}
    >
      <a href={to} target="_blank">
        <EachPanelItemBody
          icon={icon}
          text={text}
          notifCount={notifCount}
          isMini={isMini}
          small={small}
        />
      </a>
    </Button>
  ) : null;
}

export default function DesktopLeftPannel() {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();

  return (
    <div className="flex flex-col h-full p-1 w-14 py-2">
      {_currentUserContext.user!.role === UserRoles.ADMIN && (
        <EachPanelItem
          to="/admin"
          isMini={true}
          icon={<LuUserCog className="text-2xl" />}
          text="Admin"
        />
      )}

      <EachPanelItem
        to="/"
        exact
        isMini={true}
        icon={<AiOutlineHome className="text-2xl" />}
        text="Home"
      />

      <EachPanelItem
        to="/news"
        exact
        isMini={true}
        icon={<LuBell />}
        text="News"
        notifCount={
          _currentUserContext.user?.logs?.filter((e) =>
            _mainContext.efforts?.some((a) => a.id === e.effortId)
          ).length
        }
      />

      <Separator className="mt-0" />

      <EachPanelItem
        type="BUTTON"
        isMini={true}
        icon={<LuListTree className="text-2xl" />}
        text="Tree"
        isActive={!_mainContext.sideMenuIsMini}
        onClick={() =>
          _mainContext.setSideMenuIsMini(!_mainContext.sideMenuIsMini)
        }
      />

      <div className="flex-1" />

      <EachPanelItem
        to="/about"
        icon={<PiHeadset className="text-2xl" />}
        text="Support"
        isMini={true}
      />
    </div>
  );
}
