import React from "react";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import User from "models/User";
import useUserUtils from "hooks/utils/UseUserUtils";
import { UserLogActionType, UserLogFilter, UserLogType } from "models/UserLog";

import { useMainContext } from "providers/MainProvider";

import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { useUsersContext } from "providers/UsersProvider";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserLogUtils from "hooks/utils/UseUserLogUtils";

export default function UserLogFilterModal({
  filter,
  onSubmit,
  effortId,
}: {
  filter: UserLogFilter;
  onSubmit: (data: Dict) => void;
  effortId?: number;
}) {
  const _usersContext = useUsersContext();
  const _effortUtils = useEffortUtils();
  const _userUtils = useUserUtils();
  const userLogUtils = useUserLogUtils();

  let _users = _usersContext.users;

  if (effortId) {
    let _project = _effortUtils.getParentProject(effortId);

    _users = _project?.members?.map((e) => e.user) ?? [];
  }

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Select
          name="userId"
          label="User"
          options={_users?.map((e) => ({
            value: e.id,
            label: _userUtils.getFullName(e),
          }))}
        />

        {/* <div className="flex gap-2">
          <div className="flex-1"> */}
        <Select
          name="actionType"
          // label="UserLogActionType"
          options={UserLogActionType.toLabelValues()}
        />
        {/* </div>
          <div className="flex-1">
            <Select
              name="entityNames"
              label="UserLogType"
              options={UserLogType.toLabelValues()}
            />
          </div> */}
        {/* </div> */}

        <Input name="description" label="What Kind of Action?" />

        <DatePickerInputRange name="dateAdd" label="Date" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={userLogUtils.isFilterActive({ filter })}
            onClick={() =>
              onSubmit({
                pageNumber: -1,
                ascOrder: filter.ascOrder,
                sortBy: filter.sortBy,
                effortId: filter.effortId,
              })
            }
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
