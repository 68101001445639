import Dict from "./Dict";
import Type, { typeSchema } from "./Type";
import User, { userSchema } from "./User";
import { MemberAccessTypes } from "./Member";
import { hasOtherKeysExcept, isDateUntil } from "services/UtilServices";
import TicketCount, { ticketCountSchema } from "./TicketCount";
import { z } from "zod";


export type ActivitySortType = "duration" | "dateadd" | "datedone" | "user" | "type"



export const ActivitySortTypes: { [key: string]: ActivitySortType } = {

  duration: "duration",
  dateadd: "dateadd",
  datedone: "datedone",
  user: "user",
  type: "type",

};

export const approverSchema = z.object({
  dateCreated: z.string(),
  isApproved: z.boolean(),
  user: userSchema,
});



export interface ActivityFilter {
  effortIds?: number[],
  typeIds?: number[],
  userIds?: number[],


  "pageNumber": number,
  "sortBy": ActivitySortType,
  "ascOrder": boolean,
  numberInPage?: number,

  "createdOnFrom"?: Date,
  "createdOnTo"?: Date,

  "userId"?: number,
  // "effortId"?: number,
  "typeId"?: number,
  // "ticketList"?: ListTicketObject,
  // "duration"?: string,
  "dateAddFrom"?: string,
  "dateAddTo"?: string,

  "dateDoneFrom"?: string,
  "dateDoneTo"?: string,

  "isApproved"?: boolean,
  // "ticketApproved"?: boolean,

}


export const activitySchema = z.object({
  id: z.string(),
  effortId: z.number(),
  isApproved: z.boolean(),
  duration: z.string(),
  dateAdd: z.string(),
  dateDone: z.string(),
  description: z.string().optional(),

  ticketsCount: ticketCountSchema.array(),

  userId: z.string(),
  user: userSchema,

  typeId: z.string(),
  type: typeSchema,
  approverList : approverSchema.array()
});

type Activity = z.infer<typeof activitySchema>;
export default Activity;

// export default interface Activity {
//   id: string,
//   effortId: number,

//   ticketsCount: TicketCount[],
//   // ticketApproved?: TicketCount[],

//   isApproved: boolean,
  
//   duration: string,
//   dateAdd: string,
//   dateDone: string,


//   userId: string,
//   user: User,

//   typeId: string,
//   type: Type,

//   description?: string,

// }

