import React from "react";

import Dict from "models/Dict";
import { AxiosError } from "axios";
import Attachment, {
  AttachmentFilter,
  AttachmentSortTypes,
} from "models/Attachment";

import useApiServices, { ApiVariables } from "hooks/api/UseApiServices";
import { getItem, isEmpty, listFirst } from "services/UtilServices";
import useAttachmentApi from "hooks/api/UseAttachmentApi";

import { EachEffortContext } from "./EachEffortProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface AttachmentsContextProps {
  attachments: Attachment[] | undefined;
  createMany: (item: Dict) => Promise<Dict>;
  // update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Attachment[]) => Promise<Dict>;
  // download: (item: Attachment) => Promise<Dict>;

  // get: (filter: AttachmentFilter) => Promise<void>;
  // filter: AttachmentFilter;
  // hasNext: boolean,
}

const AttachmentsContext = React.createContext({} as AttachmentsContextProps);
AttachmentsContext.displayName = "AttachmentsContext";

// var items: Attachment[] | undefined = undefined;
// var globalFilter: AttachmentFilter = {
//   pageNumber: -1,
//   ascOrder: false,
//   sortBy: AttachmentSortTypes.ADDWHEN,
//   effortId: "0",
// };

function AttachmentsProvider({ children }: { children: React.ReactNode }) {
  // const [_items, _setAttachments] = React.useState<Attachment[] | undefined>(
  //   _eachEffortContext.effort?.attachments
  // );
  // const [_filter, _setFilter] = React.useState<AttachmentFilter>({
  //   pageNumber: -1,
  //   ascOrder: false,
  //   sortBy: AttachmentSortTypes.ADDWHEN,
  //   effortId,
  // });
  // const [hasNext, setHasNext] = React.useState<boolean>(true);

  const { toast } = useToast();
  const attachmentApi = useAttachmentApi();
  const apiServices = useApiServices();

  const _currentUserContext = React.useContext(CurrentUserContext);
  const _eachEffortContext = React.useContext(EachEffortContext);

  // React.useEffect(() => {
  //   globalFilter = {
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: AttachmentSortTypes.ADDWHEN,
  //     effortId,
  //   };
  // }, [effortId]);

  // const setAttachments = (o?: Attachment[]) => {
  //   items = o;
  //   _setAttachments(o);
  // };

  // const setFilter = (o: AttachmentFilter) => {
  //   globalFilter = o;
  //   _setFilter(o);
  // };

  // const get = async (filter: AttachmentFilter) => {
  //   if(JSON.stringify(filter) === JSON.stringify(globalFilter)) return;

  //     filter.effortId = effortId;
  //     setFilter(filter);

  // if(filter.pageNumber < 0)
  // {
  //   setHasNext(true);
  //   setAttachments(undefined);
  //   return;
  // }
  //     try {

  //       let response = await attachmentApi.get(filter);

  //       setHasNext(response?.length >= 20);

  //       response = response.filter((eachRes: Dict) =>
  //         !items?.find(e => e.id === eachRes.id)
  //       );

  //       setAttachments([
  //         ...items ?? [],

  //         ...response as Attachment[]
  //       ]);
  //     }
  //     catch(e) {
  // toast({
  //
  //             description: (e as AxiosError).message,
  //             variant: "destructive",
  // });
  //       setHasNext(false);
  //       if(items === undefined) {
  //         setAttachments([]);
  //       }
  //     }
  // }

  const createMany = async (formData: Dict) => {
    let _errors = {};

    try {
      let newAttachments: Attachment[] = [];

      for (const eachFile of formData.files) {
        let newAttachment = {
          id: "",
          userId: _currentUserContext.user!.id,
          effortId: _eachEffortContext.effort!.id,
          name: "",
          url: "",
          dateAdd: new Date().toISOString(),
        };

        console.log(eachFile);

        newAttachment.name =
          eachFile.name +
          (eachFile.name.includes(".")
            ? ""
            : "." + eachFile.type.split("/")[1]);
        newAttachment.url = (await apiServices.sendFile({
          file: eachFile,
          fieldName: "url",
          effortId: _eachEffortContext.effort!.id,
        })) as string;
        newAttachment.id = await attachmentApi.create(newAttachment);

        newAttachments.push(newAttachment);

        _eachEffortContext.set({
          attachments: [
            ...newAttachments,
            ...(_eachEffortContext.effort?.attachments ?? []),
          ],
        });
      }
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);

      if (!isEmpty(_errors)) {
        let _message = listFirst(listFirst(Object.values(_errors) as Dict[]));
        if (_message) {
          toast({
            description: _message,
            variant: "destructive",
          });
        }
      }
    }

    return _errors;
  };

  // const update = async (formData: Dict) => {
  //   let _errors = false;

  //   try {

  //     await attachmentApi.update(formData);

  //     setAttachments(
  //       items?.map(e =>
  //         e.id !== formData.id ? e :
  //         {
  //           ...formData,

  //         } as Attachment,
  //       )
  //     );

  //     _ok = true;
  //   }
  //   catch(e) {
  //     console.log(e);
  //   }

  //   return _errors;
  // };

  const removeMany = async (itemsToDelete: Attachment[]) => {
    let _errors = {};

    try {
      await attachmentApi.remove(itemsToDelete.map((e) => e.id));

      // setAttachments(
      //   items?.filter(e => !itemsToDelete.includes(e))
      // );

      _eachEffortContext.set({
        attachments: _eachEffortContext.effort?.attachments?.filter(
          (e) => !itemsToDelete.includes(e)
        ),
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  // const download = async (item: Attachment) => {
  //   let _errors = {};

  //   try {

  //     await fileApi.download(item.url);

  //   }
  //   catch(e) {
  //     _errors = e as AxiosError;
  //     console.log(e);
  //   }

  //   return _errors;
  // };

  return (
    <AttachmentsContext.Provider
      value={
        {
          attachments: _eachEffortContext.effort?.attachments,
          createMany,
          // update,
          removeMany,
          // download,

          // get,
          // filter: _filter,
          // hasNext,
        } as AttachmentsContextProps
      }
    >
      {children}
    </AttachmentsContext.Provider>
  );
}

export function useAttachmentsContext() {
  const _context = React.useContext(AttachmentsContext);

  if (!_context) {
    throw new Error("cannot use AttachmentsContext outside of its provider.");
  }

  return _context;
}

export { AttachmentsContext, AttachmentsProvider };
export type { AttachmentsContextProps };
