import React from "react";

import Dict from "models/Dict";
import Tag, { TagFilter, TagSortType } from "models/Tag";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";

import { useTagsContext, TagsProvider } from "providers/TagsProvider";
import { useModalContext } from "providers/ModalProvider";

import TagFilterModal from "./TagFilterModal";
import TagCreateUpdateModal from "./TagCreateUpdateModal";
import NewButton from "components/common/AdminSection/NewButton";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import useTagUtils from "hooks/utils/UseTagUtils";

function EachTagItem({ tag, index }: { tag: Tag; index: number }) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 w-5 h-5"
        )}
        style={{
          background: tag.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{tag.name}</h6>
      </div>
    </div>
  );
}

function TagsPageBody() {
  const _modalContext = useModalContext();
  const _tagsContext = useTagsContext();
  const tagUtils = useTagUtils();

  const onRemove = async (datas: Tag[]) => {
    _tagsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _tagsContext.update(data)
        : await _tagsContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <TagCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _tagsContext.get({
      ...formData,
      pageNumber: -1,
    } as TagFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <TagFilterModal filter={_tagsContext.filter} onSubmit={onFilterSubmit} />
    );
  };

  return (
    <main role="main" className="md:p-4 p-3 h-full">
      <ItemsListSection<Tag>
        title="Tags"
        data={_tagsContext.tags}
        // ellipsisResponsiveBreakPoint=""

        fields={[
          // {
          //   value: "",
          //   label: "#",
          //   className: "text-start whitespace-nowrap",
          //   width: "70px",
          //   isSortable: false,
          //   getUiContent: (e, i) => i + 1,
          // },

          {
            value: "name",
            label: "name",
            isTitleField: true,
            width: "auto",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "color",
            label: "color",
            width: "auto",
            isSortable: false,
            isSearchable: false,
            getUiContent: (e, i) => (
              <div
                className={cn("rounded-full aspect-square w-7 h-7 ")}
                style={{
                  background: e.color,
                }}
              />
            ),
          },

          {
            value: "dateAdd",
            label: "Created on",
            width: "auto",
            // isSortable: false,
            getValue: (e) => dateGetTime(e.dateAdd),
            getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
            className: "whitespace-nowrap",
          },
        ]}
        onLoadMore={
          !_tagsContext.hasNext
            ? undefined
            : async () => {
                await _tagsContext.get({
                  ..._tagsContext.filter,
                  pageNumber: _tagsContext.filter.pageNumber + 1,
                });
              }
        }
        onSortField={(v) => {
          _tagsContext.get({
            ..._tagsContext.filter,
            sortBy: v as TagSortType,
            pageNumber: -1,
          });
        }}
        onSortDir={(v) => {
          _tagsContext.get({
            ..._tagsContext.filter,
            ascOrder: v === 1,
            pageNumber: -1,
          });
        }}
        initialSelectedSortFieldValue="dateAdd"
        buildCardTitleRow={(v, i) => <EachTagItem tag={v} index={i} />}
        onItemEdit={(u) => openCreateUpdateModal}
        onItemClick={(u) => openCreateUpdateModal}
        onItemDelete={() => _tagsContext.removeMany}
        actionsSection={
          <>
            <FilterButton
              count={_tagsContext.totalNumber}
              isActive={tagUtils.isFilterActive({
                filter: _tagsContext.filter,
              })}
              onClick={openFilterModal}
            />

            <NewButton onClick={() => openCreateUpdateModal()} />
          </>
        }
      />
    </main>
  );
}

export default function TagsPage() {
  return (
    <TagsProvider>
      <TagsPageBody />

      <MobileFooterPadding />
    </TagsProvider>
  );
}
