import React from "react";
import { useLocation } from "react-router";
import { Keyboard } from "@capacitor/keyboard";
import { Device } from "@capacitor/device";

import Link, { useLinkUtils } from "components/common/Link";

import {
  FaEnvelope,
  FaIndustry,
  FaLinkedin,
  FaLocationDot,
  FaPhone,
  FaTwitter,
  FaXing,
} from "react-icons/fa6";
import { RiUser6Line } from "react-icons/ri";
import { cn } from "services/UtilServices";
import { Badge } from "components/ui/badge";
import AppLogo from "components/pages/AppLogo";

export function MobileFooterPadding() {
  return <div className="py-5 md:py-0"></div>;
}

function MobileFooterEachItem({
  text = "",
  icon = <></>,
  href = "",
  activeOnExactOnly = false,
  count = 0,
}) {
  const linkUtils = useLinkUtils();

  const _isActive = linkUtils.isMatch(href, {
    exact: activeOnExactOnly,
  });

  return (
    <Link
      href={href}
      exact
      className="flex-1 text-white border-0 rounded-none text-center bg-transparent cursor-pointer no-underline block h-full flex items-center justify-center flex-col overflow-hidden"
      // activeClassName="text-warning"
      // deactiveClassName="text-white "
    >
      <i className={cn(icon, " block relative")}>
        {count > 0 && (
          <Badge className="absolute -top-4 -left-4">{count}</Badge>
        )}
      </i>

      {_isActive && <span className="block mt-2 -mb-4 text-xs">{text}</span>}
    </Link>
  );
}

const MobileMainFooter = () => {
  const [_isKeyboardOpen, _setIsKeyboardOpen] = React.useState(false);
  // const _currentUserContext = useCurrentUserContext();
  // const _mainContext = useMainContext();
  // const _modalContext = useModalContext();

  React.useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform === "android") {
        Keyboard.addListener("keyboardWillShow", () =>
          _setIsKeyboardOpen(true)
        );
        Keyboard.addListener("keyboardWillHide", () =>
          _setIsKeyboardOpen(false)
        );

        return () => {
          Keyboard.removeAllListeners();
        };
      }
    });
  }, []);

  return (
    <>
      <div
        className={cn(
          "fixed flex items-center right-0 left-0 text-light md:hidden z-100 h-16 -bottom-1 ",
          { hidden: _isKeyboardOpen }
        )}
      >
        <div className="border-t border-light flex-1 h-full flex items-center bg-primary bg-dark  ">
          <MobileFooterEachItem
            href="/"
            text="Projects"
            icon={<FaIndustry />}
            activeOnExactOnly
          />

          <MobileFooterEachItem
            href="/profile/"
            icon={<RiUser6Line className="-mb-2" />}
            text="Account"
          />
        </div>
      </div>
    </>
  );
};

const LaptopMainFooter = () => {
  return (
    <div className="w-full bg-primary bg-dark hidden lg:block border-e">
      <div className="text-light">
        <div className="row pt-5 pb-4 px-5">
          <div className="col-12 md:col-9 text-end ">
            <h1 className="text-light">PerTask</h1>

            <p className="text-justify ">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
              facilis cum impedit odit vitae adipisci nesciunt nobis eum quidem
              nisi ullam voluptas culpa maxime nam molestiae, animi quo sequi
              dolorem!
            </p>

            <div className="flex flex-col md:flex-row justify-end">
              <h6 className="text-light inline me-4 mt-2">
                +41 232 4334
                <FaPhone className="ms-2" />
              </h6>

              <h6 className="text-light inline me-4 mt-2">
                info@PerTask.com
                <FaEnvelope className="ms-2" />
              </h6>

              <h6 className="text-light inline me-4 mt-2">
                München
                <FaLocationDot className="ms-2" />
              </h6>
            </div>
          </div>

          <div className="col-12 md:col-3 ">
            <div className="text-center">
              <AppLogo className="md:mx-0 mx-auto max-w-full max-h-48" />
            </div>
          </div>
        </div>

        <div className="flex items-center px-5 py-3">
          <span className="text-xs">Designed By PerCAE</span>

          <Link href="#" className="ms-auto text-light">
            <FaTwitter />
          </Link>

          <Link href="#" className="ms-4 text-light">
            <FaXing />
          </Link>

          <Link href="#" className="ms-4 text-light">
            <FaLinkedin />
          </Link>
        </div>
      </div>
    </div>
  );
};

const MainFooter = () => {
  return (
    <>
      {/* <MobileMainFooter /> */}
      {/* <LaptopMainFooter /> */}
    </>
  );
};

export default MainFooter;
