import React from "react";

import useUserUtils from "hooks/utils/UseUserUtils";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import Link from "../../common/Link";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import { LuLogOut } from "react-icons/lu";
import { Button } from "components/ui/button";
import { PROFILE_PAGE_TABS } from "pages/ProfilePage";
import { Separator } from "components/ui/separator";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { UserRoles } from "models/User";

export default function ProfileSection() {
  const _currentUserContext = useCurrentUserContext();
  const _userUtils = useUserUtils();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"light"}
          className=" border-0 p-0 ps-4 flex items-center ms-1 rounded-full gap-1"
        >
          <span className="mb-0 hidden lg:inline">
            {_userUtils.getFullName(_currentUserContext.user!)}
          </span>
          <ProfileAvatar user={_currentUserContext.user} />
        </Button>
      </PopoverTrigger>

      <PopoverContent align="end" className="w-72">
        <Link
          exact
          to="/profile/"
          variant={"light"}
          className=" w-full flex flex-col gap-1"
        >
          <ProfileAvatar user={_currentUserContext.user} size={100} />

          <span className="font-bold text-lg">
            {_userUtils.getFullName(_currentUserContext.user!)}
          </span>
          <span className="text-muted-foreground">
            {UserRoles.options[_currentUserContext.user!.role]}
          </span>
        </Link>

        {PROFILE_PAGE_TABS.filter((e) => e.url !== "").map((eachItem, i) => (
          <React.Fragment key={"eachProfileDropDownItem" + i}>
            <Separator className="m-0 p-0 bg-muted" />

            <Link
              variant={"light"}
              to={"/profile/" + eachItem.url}
              className=" justify-start ps-5 h-12 w-full"
            >
              {eachItem.icon}
              {eachItem.text}
            </Link>
          </React.Fragment>
        ))}

        <Separator className="m-0 p-0 bg-muted" />

        <Button
          variant={"light"}
          onClick={() => _currentUserContext.logout()}
          className=" justify-start ps-5 h-12 w-full"
        >
          <LuLogOut /> Logout
        </Button>
      </PopoverContent>
    </Popover>
  );
}
