import React from "react";
import { useNavigate, useParams } from "react-router";

import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { cn, isNumeric } from "services/UtilServices";

import { useMainContext } from "providers/MainProvider";
import { TagsProvider } from "providers/TagsProvider";
import { ModalProvider } from "providers/ModalProvider";
import { TypesProvider } from "providers/TypesProvider";
import { UsersProvider } from "providers/UsersProvider";
import { StatesProvider } from "providers/StatesProvider";
import { MembersProvider } from "providers/MembersProvider";
import { PrefixsProvider } from "providers/PrefixsProvider";
import { EffortsProvider } from "providers/EffortsProvider";
import { TicketsProvider } from "providers/TicketsProvider";
import { PrioritiesProvider } from "providers/PrioritiesProvider";
import { DepartmentsProvider } from "providers/DepartmentsProvider";
import { AttachmentsProvider } from "providers/AttachmentsProvider";
import {
  useEachEffortContext,
  EachEffortProvider,
} from "providers/EachEffortProvider";

import MainLayout from "layouts/MainLayout";
import Skeleton from "components/common/Skeleton";
import InfoSection from "components/pages/EachEffort/InfoSection";
import EffortsSection from "components/pages/EffortsSection/EffortsSection";
import SettingSection from "components/pages/EachEffort/SettingSection/SettingSection";
import { TabContents } from "components/common/Tabs";
import SidePanel, {
  useSidePanelSections,
} from "components/pages/EachEffort/SidePanel";
import { ViewTypeSelectProvider } from "components/pages/EffortsSection/ViewTypeSelectButton";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";

function EachEffortPageBody() {
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery();
  const _eachEffortContext = useEachEffortContext();
  const _effortUtils = useEffortUtils();
  const SECTIONS = useSidePanelSections(_eachEffortContext.effort, true);
  const ref = React.useRef<HTMLDivElement>(null);

  const params = useParams();

  let effortUrl = _effortUtils.getUrl(_eachEffortContext.effort?.id);
  let possibleInitilialTabIndex = [params.task, params.subtask, params.index];

  let _tabIndexes = SECTIONS.map((e) => e.url);
  if (
    _eachEffortContext.effort === undefined ||
    _eachEffortContext.effort === null ||
    _eachEffortContext.effort?.level >= 2
  ) {
    _tabIndexes = _tabIndexes.filter((e) => e !== "");
  }

  let _initIndex = _tabIndexes.find((eachTabIndex) =>
    possibleInitilialTabIndex.includes(eachTabIndex)
  );
  if (!_initIndex) {
    _initIndex =
      _eachEffortContext.effort !== undefined &&
      _eachEffortContext.effort !== null &&
      _eachEffortContext.effort?.level < 1
        ? ""
        : "setting";
  }

  if (_eachEffortContext.effort === null) {
    navigate("/404");
    return null;
  }

  return (
    <MainLayout
      back
      title={_eachEffortContext.effort?.title ?? "Loading..."}
      sidePanel={<SidePanel effort={_eachEffortContext.effort} />}
    >
      <main className="min-h-[calc(100%-5rem)]">
        {_eachEffortContext.effort === undefined ? (
          <div className="p-3">
            <div className="flex rounded border p-3 mb-3">
              <Skeleton className="w-1/2 lg:w-1/4 h-5" />
            </div>

            <div className="flex rounded border p-3">
              <Skeleton className="w-1/2 lg:w-1/4 h-7" />
              <div className="flex-1"></div>
              <Skeleton className=" w-24 h-7" />
            </div>

            <div className="flex items-center py-2 gap-2">
              <Skeleton className=" w-24 h-7" />
              <div className="rounded-full border p-3 w-[15%]"></div>
              <div className="flex-1"></div>
              <Skeleton count={4} className=" w-20 h-10" />
            </div>

            <div className="row">
              <div className="col-12 md:col-6 lg:col-3 p-2">
                <Skeleton className="w-full h-72" />
              </div>
              <div className="col-12 md:col-6 lg:col-3 p-2">
                <Skeleton className="w-full h-72" />
              </div>
              <div className="col-12 md:col-6 lg:col-3 p-2">
                <Skeleton className="w-full h-72" />
              </div>
              <div className="col-12 md:col-6 lg:col-3 p-2">
                <Skeleton className="w-full h-72" />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full">
            <InfoSection effort={_eachEffortContext.effort} />

            <div
              ref={ref}
              className="p-2 md:py-6"
              style={{
                height: `calc(100vh - ${ref.current?.offsetTop}px)`,
              }}
            >
              {mediaQuery <= MediaQuery2Num.md ? (
                <TabContents items={SECTIONS} activeUrl={_initIndex} />
              ) : _eachEffortContext.effort.level >= 2 ? (
                <SettingSection effort={_eachEffortContext.effort} />
              ) : (
                <EffortsSection parentId={_eachEffortContext.effort.id} />
              )}
            </div>
          </div>
        )}
      </main>
    </MainLayout>
  );
}

export default function EachEffortPage() {
  const params = useParams();
  const _effortUtils = useEffortUtils();

  const projTitle = params.proj;
  const taskUrl =
    params.task === undefined || !isNumeric(params.task)
      ? undefined
      : parseInt(params.task);

  const subtaskUrl =
    params.subtask === undefined || !isNumeric(params.subtask)
      ? undefined
      : parseInt(params.subtask);

  let _f = _effortUtils.getEffortByUrl({
    projTitle,
    taskUrl,
    subtaskUrl,
  });

  return (
    <EffortsProvider parentId={_f?.id}>
      <TicketsProvider>
        <PrioritiesProvider>
          <PrefixsProvider>
            <DepartmentsProvider>
              <StatesProvider>
                <TagsProvider>
                  <TypesProvider>
                    <UsersProvider>
                      <ModalProvider>
                        <EachEffortProvider
                          projTitle={projTitle}
                          taskUrl={taskUrl}
                          subtaskUrl={subtaskUrl}
                        >
                          <AttachmentsProvider>
                            <MembersProvider>
                              <ViewTypeSelectProvider>
                                <EachEffortPageBody />
                              </ViewTypeSelectProvider>
                            </MembersProvider>
                          </AttachmentsProvider>
                        </EachEffortProvider>
                      </ModalProvider>
                    </UsersProvider>
                  </TypesProvider>
                </TagsProvider>
              </StatesProvider>
            </DepartmentsProvider>
          </PrefixsProvider>
        </PrioritiesProvider>
      </TicketsProvider>
    </EffortsProvider>
  );
}
