import React from "react";

import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { useMainContext } from "providers/MainProvider";

import Link from "components/common/Link";
import useOutsideClick from "components/common/useOutsideClick";
import EmptyListIndicator from "components/common/EmptyListIndicator";

import { FaMagnifyingGlass } from "react-icons/fa6";
import { cn, listFirst } from "services/UtilServices";
import { LuX } from "react-icons/lu";
import { Button } from "components/ui/button";
import { useNavigate } from "react-router";
import { NetworkImage } from "components/common/NetworkImage";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import { Input } from "components/ui/input";

function EachSearchItem({
  href = "",
  title = "",
  onClick = () => {},
  imgUrl = "",
}) {
  return (
    <Link
      to={href}
      onClick={onClick}
      variant={"light"}
      className="flex items-center justify-start h-12 "
    >
      {imgUrl && (
        <NetworkImage
          secure
          src={imgUrl}
          className="object-cover rounded border w-7 h-7"
        />
      )}
      <span className="inline-block truncate">{title}</span>
    </Link>
  );
}

export function SearchModalBody({ onDismiss }: { onDismiss: () => void }) {
  const [value, setValue] = React.useState<string>();

  const _mainContext = useMainContext();
  const _textEditorUtils = useTextEditorUtils();
  const _effortUtils = useEffortUtils();
  const navigate = useNavigate();

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setValue(undefined);
      onDismiss();
    } else if (e.key === "Enter" && searchResult && searchResult?.length > 0) {
      setValue(undefined);
      onDismiss();
      navigate(_effortUtils.getUrl(listFirst(searchResult)!.id)!);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyPress, false);

    return () => {
      document.removeEventListener("keydown", onKeyPress, false);
    };
  }, []);

  const searchResult = _mainContext.efforts?.filter((e) =>
    e.title.toLowerCase().includes(value?.toLowerCase() ?? "")
  );

  return (
    <div className="direction-ltr h-full flex flex-col">
      <div className="flex items-center w-full p-2 py-2 relative">
        <Button
          variant={"faded"}
          onClick={() => {
            setValue(undefined);
            onDismiss();
          }}
          className=" rounded-full aspect-square p-1 flex items-center justify-center absolute right-4 w-6 h-6 "
        >
          <LuX />
        </Button>

        <Input
          ref={(input) => {
            input?.focus();
          }}
          value={value}
          placeholder="Search all Tasks"
          onChange={(e) => setValue(e.target.value)}
          className="rounded-full form-control w-full px-9 h-8 bg-transparent"
        />

        <FaMagnifyingGlass className="absolute left-5 text-foreground" />
      </div>

      <div className="px-2 py-1 overflow-y-auto flex-1  ">
        <EmptyListIndicator>
          {searchResult?.map((e, index) => (
            <EachSearchItem
              key={"searchResult" + index}
              // href={"/projects/" + e.id}
              title={e.title}
              onClick={onDismiss}
              href={_effortUtils.getUrl(e.id)}
              imgUrl={listFirst(_textEditorUtils.getImages(e.description))?.url}
            />
          ))}
        </EmptyListIndicator>
      </div>
    </div>
  );
}

export default function SearchSection() {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  useOutsideClick(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div ref={ref} className="relative px-2">
      <Input
        placeholder="Search all Tasks"
        onClick={() => setIsOpen(true)}
        className="form-control bg-transparent rounded-full px-9 text-start direction-ltr w-full placeholder-secondary-foreground h-8 border"
      />

      <FaMagnifyingGlass className="text-foreground/50 absolute left-1 top-1 mx-4 my-1 " />

      <div
        className={cn(
          "absolute left-0 right-0 card border rounded border-foreground/30 shadow-lg transition-all duration-100 z-50 -top-2.5 h-[min(70vh,400px)] bg-background ",
          { "hidden opacity-0": !isOpen }
        )}
      >
        <SearchModalBody onDismiss={() => setIsOpen(false)} />
      </div>
    </div>
  );
}
