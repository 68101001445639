
import Dict from 'models/Dict';
import { UserLogFilter } from 'models/UserLog';
import { dataMapper, dateAdd, getNotEmptyFields, getOnlyFields } from 'services/UtilServices';

import useApiServices from './UseApiServices';

export const BASE_URL = "userlogs/";

export default function useUserLogApi() {

  const apiServices = useApiServices();


  const get = async (filter: UserLogFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        dateAddTo: dateAdd({ date: filter.dateAddTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result ?? []
    };
  }

  const markAsRead = async (logIds: string[]) => {
    await apiServices.send({
      url: BASE_URL + "read",
      method: "post",
      body: { "ids": logIds }
    });
  }

  return {
    get,
    markAsRead
  };
}

