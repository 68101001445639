import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import State from "models/State";
import Effort, { EffortFilter, EffortSortTypes } from "models/Effort";

import useEffortApi from "hooks/api/UseEffortApi";
import LocalStorageServices from "services/localServices/LocalStorageServices";

import { MainContext } from "./MainProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { groupBy, listFlatten, sortedStringify } from "services/UtilServices";
import TicketCount from "models/TicketCount";
import useTicketCountUtils from "hooks/utils/UseTicketCountUtils";
import useDownloadServices from "hooks/UseDownloadServices";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import useApiServices from "hooks/api/UseApiServices";
import useExportApi from "hooks/api/UseExportApi";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

export interface UpdateStateInputType {
  effortIds: number[];
  stateId: number;
  state: State;
}

interface EffortsContextProps {
  currentEffort: Effort | undefined;
  efforts: Effort[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  updateState: (item: UpdateStateInputType) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;
  exportReport: (type: "xlsx" | "pdf", filter: EffortFilter) => Promise<Dict>;

  get: (filter: EffortFilter) => Promise<void>;
  filter: EffortFilter;
  hasNext: boolean;
}

const EffortsContext = React.createContext({} as EffortsContextProps);
EffortsContext.displayName = "EffortsContext";

// var items: Effort[] | undefined = undefined;
// var globalFilter: EffortFilter = {
//   pageNumber: -1,
//   ascOrder: false,
//   numberInPage: 999,
//   sortBy: EffortSortTypes.ADDWHEN,
//   parentId: "0"
// };

function EffortsProvider({
  children,
  parentId = null,
}: {
  children: React.ReactNode;
  parentId?: number | null;
}) {
  const _currentUserContext = React.useContext(CurrentUserContext);
  const _mainContext = React.useContext(MainContext);

  const effortApi = useEffortApi();
  const downloadServices = useDownloadServices();
  const textEditorUtils = useTextEditorUtils();
  const apiServices = useApiServices();
  const exportApi = useExportApi();
  const _effortUtils = useEffortUtils();
  const _ticketCountUtils = useTicketCountUtils();

  // const [_items, _setEfforts] = React.useState<Effort[]>();
  const [_filter, _setFilter] = React.useState<EffortFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: EffortSortTypes.ADDWHEN,
    numberInPage: 900,
    parentId: parentId === null ? 0 : parentId,
  });
  // const [hasNext, setHasNext] = React.useState<boolean>(true);

  React.useEffect(() => {
    LocalStorageServices.get("EFFORT_FILTER").then((r) => {
      _setFilter(
        r === null
          ? {
            pageNumber: -1,
            ascOrder: false,
            sortBy: EffortSortTypes.ADDWHEN,
            parentId: parentId === null ? "0" : parentId,
          }
          : r
      );
    });
  }, []);

  // React.useEffect(() => {
  //   setEfforts(undefined);
  //   setHasNext(true);
  //   globalFilter = {
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: EffortSortTypes.ADDWHEN,
  //       parentId: parentId === null ? "0" : parentId,
  //   };
  //   return () => {
  //     setEfforts(undefined);
  //     setHasNext(true);
  //     globalFilter = {
  //       pageNumber: -1,
  //       ascOrder: false,
  //       sortBy: EffortSortTypes.ADDWHEN,
  //       parentId: parentId === null ? "0" : parentId,
  //     };
  //   }
  // }, [parentId]);

  // const setEfforts = (o?: Effort[]) => {
  //   items = o;
  //   _setEfforts(o);
  // };

  const setFilter = (o: EffortFilter) => {
    // globalFilter = o;
    _setFilter(o);
    LocalStorageServices.set("EFFORT_FILTER", o);
  };

  const get = async (filter: EffortFilter) => {
    // if(JSON.stringify(filter) === JSON.stringify(globalFilter)) return;

    setFilter(filter);

    // if(filter.pageNumber <= 0) {
    //   setHasNext(true);
    //   setEfforts(undefined);
    // }

    // let response = await effortApi.get(filter);

    // setHasNext(response?.length >= 20);

    // response = response.filter((eachRes: Dict) =>
    //   !items?.find(e => e.id === eachRes.id)
    // );

    // setEfforts([
    //   ...items ?? [],
    //   ...response as Effort[]
    //   ]);
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      if (parentId !== null) {
        formData.parentId = parentId;
      }

      if (formData.description === undefined) {
        formData.description = "";
      }

      let { id, url, memberships, dateAdd } = await effortApi.create(formData);

      formData = {
        ...formData,

        dateAdd,
        id,
        url,
        parentId,

        members: [memberships],
        creator: _currentUserContext.user,
      };

      if (formData.avatarUrl || formData.description) {
        _errors = await update(formData);
      }

      const _project = _effortUtils.getParentProject(formData.parentId);
      _mainContext.setEfforts((prev) =>
        [formData as Effort, ...(prev ?? [])].map((eachPrev) =>
          _project?.id !== eachPrev.id
            ? eachPrev
            : {
              ...eachPrev,
              ticketsCount: _ticketCountUtils.add(
                ...(eachPrev.ticketsCount ?? []),
                ...(formData!.ticketsCount ?? ([] as TicketCount[]))
              ),
            }
        )
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.avatarUrl = await apiServices.sendFile({
        file: formData.avatarUrl,
        effortId: formData.id,
        fieldName: "avatarUrl",
      });

      formData.description = await textEditorUtils.uploadImages({
        value: formData.description,
        fieldName: "description",
        effortId: formData.id,
      });

      await effortApi.update(formData);

      const _oldEffort = _mainContext.efforts?.find(
        (e) => e.id === formData.id
      );
      const _project = _effortUtils.getParentProject(formData.id);

      // if (
      //   sortedStringify(formData.ticketsCount) !==
      //   sortedStringify(_oldEffort!.ticketsCount)
      // ) {
      _mainContext.setEfforts((prev) =>
        prev?.map((eachPrev) =>
          eachPrev.id === formData.id
            ? {
              ...(formData as Effort),
              url: (formData.index ?? formData.url) + "",
            }
            : _project?.id === eachPrev.id
              ? {
                ...eachPrev,
                ticketsCount: _ticketCountUtils.add(
                  ...(_ticketCountUtils.subtract(
                    eachPrev.ticketsCount,
                    ...(_oldEffort!.ticketsCount ?? [])
                  ) ?? []),
                  ...(formData!.ticketsCount ?? ([] as TicketCount[]))
                ),
              }
              : eachPrev
        )
      );
      // }
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const exportReport = async (type: "xlsx" | "pdf", filter: EffortFilter) => {
    let _errors = {};

    try {
      let response = await exportApi.getEffortReport(type, filter);

      downloadServices.downloadByBlob({
        blob: response.blob,
        filename: response.filename,
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const updateState = async (data: UpdateStateInputType) => {
    let _errors = {};
    let oldEfforts = _mainContext.efforts!.filter((e) =>
      data.effortIds.includes(e.id)
    );

    try {
      _mainContext.setEfforts((prev) =>
        prev?.map((e) =>
          !data.effortIds.includes(e.id)
            ? e
            : ({
              ...e,
              stateId: data.stateId,
              state: data.state,
            } as Effort)
        )
      );

      await effortApi.updateState(data);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) =>
          data.effortIds.includes(eachEffort.id)
            ? oldEfforts.find((e) => e.id === eachEffort.id)!
            : eachEffort
        )
      );
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Effort[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await effortApi.remove(ids);

      let _allIdsToDelete = itemsToDelete
        .map((e) =>
          _effortUtils.getSubEffortsDeep({
            id: e.id,
          })
        )
        .reduce((result, e) => [...result, ...e], [] as Effort[])
        .map((e) => e.id);

      const _groupedByProject = groupBy({
        list: itemsToDelete,
        callbackFunc: (e) => _effortUtils.getParentProject(e.id)!.id,
      })!;

      _mainContext.setEfforts((prev) =>
        prev
          ?.filter((e) => !_allIdsToDelete.includes(e.id))
          .map((eachPrev) =>
            !(eachPrev.id in _groupedByProject)
              ? eachPrev
              : {
                ...eachPrev,
                ticketsCount:
                  _ticketCountUtils.subtract(
                    eachPrev.ticketsCount,
                    ..._ticketCountUtils.add(
                      ...listFlatten(
                        _groupedByProject[eachPrev.id].map(
                          (e) => e.ticketsCount ?? []
                        )
                      )!
                    )
                  ) ?? [],
              }
          )
      );
    } catch (e) {
      _errors = e as AxiosError;
      // console.log(e);
    }

    return _errors;
  };

  return (
    <EffortsContext.Provider
      value={
        {
          currentEffort: _mainContext.efforts?.find((e) => e.id === parentId),
          efforts: _mainContext.efforts
            ?.filter((e) => e.parentId === parentId)
            .filter((e) => _effortUtils.isMatch(e, _filter))
            .map((e) => ({
              ...e,
              index: parseInt(e.url),
              url: _effortUtils.getUrl(e.id),
              subTasksCount:
                _effortUtils.getSubEffortsDeep({
                  id: e.id,
                }).length - 1,
            })),
          create,
          update,
          updateState,
          removeMany,
          exportReport,

          get,
          filter: _filter,
          // hasNext,
        } as EffortsContextProps
      }
    >
      {children}
    </EffortsContext.Provider>
  );
}

export function useEffortsContext() {
  const _context = React.useContext(EffortsContext);

  if (!_context) {
    throw new Error("cannot use EffortsContext outside of its provider.");
  }

  return _context;
}

export { EffortsContext, EffortsProvider };
export type { EffortsContextProps };
