import React from "react";

import Device from "models/Device";
import useDeviceUtils from "hooks/utils/UseDeviceUtils";

import { cn, dateGetTime } from "services/UtilServices";

import { ModalProvider } from "providers/ModalProvider";
import { UsersProvider } from "providers/UsersProvider";
import { useDevicesContext, DevicesProvider } from "providers/DevicesProvider";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";

import useMediaQuery from "components/common/useMediaQuery";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import ItemsListCardSection from "components/common/ItemsListSection/ItemsListCardSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import LocalDateTime from "components/common/LocalDateTime";
import { useThemeContext } from "providers/ThemeProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { LuLogOut } from "react-icons/lu";
import { ApiVariables } from "hooks/api/UseApiServices";
import { Button } from "components/ui/button";
import { useDeleteButton } from "components/common/DeleteButton";

function EachDeviceItem({
  device,
  index = -1,
}: {
  device: Device;
  index?: number;
}) {
  const deviceUtils = useDeviceUtils();

  return (
    <div className="flex-1 flex items-center gap-2">
      {index >= 0 && <h6 className="mb-0"> {index + 1} </h6>}

      <div>{deviceUtils.getClientInfo({ device })?.icon}</div>

      <div>{deviceUtils.getDeviceInfo({ device })?.icon}</div>

      <div>{device.ipv4Address}</div>

      <div className="ms-auto">
        <LocalDateTime date={device.loginDate} />
      </div>
    </div>
  );
}

function DeviceSectionBodyBody() {
  const _devicesContext = useDevicesContext();
  const _themeContext = useThemeContext();
  const _currentUserContext = useCurrentUserContext();
  const {
    actionsSection,
    itemClassName,
    bottomActionSection,
    buildCardBodyItem,
    buildCardTitleRow,
    itemStyle,
  } = useItemsListContext();

  let size = useMediaQuery();

  const currentDevice = _devicesContext.devices?.find(
    (e) => e.loginKey === ApiVariables.TOKEN?.loginKey
  );

  return (
    <div className="h-full mx-auto max-w-[400px]">
      <span className="block my-4 text-foreground">
        You can see your logged in devices in here and logout from them.
      </span>

      {currentDevice && (
        <>
          <div className="flex items-center justify-between">
            <h5 className="p-0 m-0 text-foreground">Current Device</h5>
            <Button
              variant={"light"}
              onClick={() => _currentUserContext.logout()} //isAdminPanel ? "/admin/login" : "/"
            >
              <LuLogOut />
              <span className="h6 mb-0">Logout</span>
            </Button>
          </div>

          <Button
            variant={"light"}
            className={cn(
              "w-full border gap-2 p-3 py-4 mb-7 pointer-events-none"
            )}
          >
            <EachDeviceItem device={currentDevice} />
          </Button>
        </>
      )}

      <ItemListSearchbarSection
        isSearchable={false}
        actionsSection={actionsSection}
        bottomActionSection={bottomActionSection}
      />

      <div>
        <ItemsListCardSection
          buildCardBodyItem={buildCardBodyItem}
          buildCardTitleRow={buildCardTitleRow}
          itemClassName={itemClassName}
        />
      </div>
    </div>
  );
}

function DeviceSectionBody() {
  const _devicesContext = useDevicesContext();
  const deleteButton = useDeleteButton();

  return (
    <main role="main" className="md:p-3 p-2 h-full">
      <ItemsListProvider<Device>
        title="Other Devices"
        data={_devicesContext.devices?.filter(
          (e) => e.loginKey !== ApiVariables.TOKEN?.loginKey
        )}
        ellipsisResponsiveBreakPoint="always"
        fields={[
          {
            value: "",
            label: "#",
            className: "text-start whitespace-nowrap ",
            width: "70px",
            isSortable: false,
            getUiContent: (e, i) => i + 1,
          },

          // {
          //   value: "loginKey",
          //   label: "loginKey",
          //   width: "auto",
          //   className: "whitespace-nowrap",
          //   // isSortable: false,
          // },

          // {
          //   value: "loginProvider",
          //   label: "login Provider",
          //   width: "auto",
          //   className: "whitespace-nowrap",
          //   // isSortable: false,
          // },

          {
            value: "ipv4Address",
            label: "IP Address",
            width: "auto",
            className: "whitespace-nowrap",
            // isSortable: false,
          },

          {
            value: "client",
            label: "Browser",
            width: "auto",
            // getUiContent: (e, i) => UserRoles.options[e.role],
            className: "whitespace-nowrap",
            // isSortable: false,
          },

          {
            value: "device",
            label: "device",
            width: "auto",
            // isSortable: false,
            // getUiContent: (e, i) => e.office?.name,
            className: "whitespace-nowrap",
          },

          {
            value: "loginDate",
            label: "Created On",
            width: "auto",
            // isSortable: false,
            getValue: (e) => dateGetTime(e.loginDate),
            getUiContent: (e, i) => <LocalDateTime date={e.loginDate} />,
            className: "whitespace-nowrap",
          },
        ]}
        onLoadMore={
          _devicesContext.devices !== undefined
            ? undefined
            : async () => _devicesContext.get()
        }
        // onSortField={(v) => {
        //   _devicesContext.get({
        //     ..._devicesContext.filter,
        //     sortBy: v as AuditLogSortType,
        //     pageNumber: -1,
        //   });
        // }}
        // onSortDir={(v) => {
        //   _devicesContext.get({
        //     ..._devicesContext.filter,
        //     ascOrder: v === 1,
        //     pageNumber: -1,
        //   });
        // }}

        initialSelectedSortFieldValue="loginDate"
        buildCardTitleRow={(v, i) => <EachDeviceItem device={v} index={i} />}
        // buildCardBodyItem={(v, i) => <EachUserItem user={v} index={i} />}

        // onItemEdit={(u) => openCreateUpdateModal}
        // onItemClick={(u) => openCreateUpdateModal}
        // onItemDelete={() => _devicesContext.logoutMany}
        actionsSection={
          <>
            {/* <FilterButton
            // isActive={AuditLogUtils.isFilterActive({ filter: _devicesContext.filter, pageType:"profile" })}
            onClick={openFilterModal}
          /> */}
          </>
        }
        getItemActions={(e) => [
          {
            icon: <LuLogOut />,
            text: "Logout",
            onClick: () =>
              deleteButton.tryRemove({
                title: "logout",
                deleteBtnText: "Logout",
                onConfirm: async () => await _devicesContext.logoutMany([e]),
                message: "You're logout from a device.'",
              }),
          },
        ]}
        selectedActionsSection={(e) => [
          {
            icon: <LuLogOut />,
            text: "Logout",
            onClick: () =>
              deleteButton.tryRemove({
                title: "logout",
                deleteBtnText: "Logout",
                onConfirm: async () => await _devicesContext.logoutMany(e),
                message: "You're logout from these devices.'",
              }),
          },
        ]}
      >
        <DeviceSectionBodyBody />
      </ItemsListProvider>
    </main>
  );
}

export default function DevicePage() {
  return (
    <DevicesProvider>
      <UsersProvider>
        <ModalProvider>
          <DeviceSectionBody />
          <MobileFooterPadding />
        </ModalProvider>
      </UsersProvider>
    </DevicesProvider>
  );
}
