import React from "react";

import MainLayout from "layouts/MainLayout";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import { FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";
import AppLogo from "components/pages/AppLogo";

function AboutUsPage() {
  return (
    <MainLayout title="About Us" back>
      <div className="p-4 h-full flex items-center justify-center">
        <div className="rounded border bg-card mx-auto mb-5 p-4 text-center max-w-[500px]">
          <div className="flex w-full justify-center mb-3 ">
            <AppLogo className="object-contain w-full" height={200} />
          </div>

          <span>
            We are a fantastic firm located in Deutschland!
            <a
              target="_blank"
              href="https://www.perxact.de"
              className="text-blue-500 underline"
            >
              {" "}
              Our website{" "}
            </a>
          </span>

          <div className="flex flex-wrap justify-center w-full gap-4 mt-2">
            <h6 className="mb-0">
              <FaPhone /> +49 89 1262 7676
            </h6>

            <h6 className="mb-0">
              <FaEnvelope /> info@perxact.de
            </h6>

            <h6 className="mb-0">
              <FaLocationDot /> München
            </h6>
          </div>
        </div>
      </div>

      <MobileFooterPadding />
    </MainLayout>
  );
}

export default AboutUsPage;
