
import * as shamsi from 'shamsi-date-converter'
import { TextEditorType } from './TextEditor';
import { isValid, parse } from 'date-fns';
import { dateGetOnlyDate, dateGetTime, dateGetToday, isEmpty } from 'services/UtilServices';
import useTextEditorUtils from 'hooks/UseTextEditorUtils';


export interface ValidationType {
    type: string;
    text: string;
    isValid: (value: any) => boolean;
}

export default function useValidation() {
    const textEditorUtils = useTextEditorUtils();
    // type: string;
    // text: string;
    // isValid: (value: any) => boolean;

    // constructor({
    //     type,
    //     text,
    //     isValid,
    // }: { type: string, text: string, isValid: (value: any) => boolean }) {
    //     this.type = type;
    //     this.text = text;
    //     this.isValid = isValid;
    // }

    const isRequired = (text = "This field is required.") => {
        return {
            type: "isRequired",
            text: text,
            isValid: (value: any) => value !== '' && value !== "" && value !== undefined && value !== null
        } as ValidationType;
    }

    const notEmptyList = (text = "This field is required.") => {
        return {
            type: "notEmptyList",
            text: text,
            isValid: (value: any) => Array.isArray(value) && value.length > 0
        } as ValidationType;
    }

    const notLargeFilesInEditor = (text = "File size can not be more than 5M!") => {
        return {
            type: "notLargeFilesInEditor",
            text: text,
            isValid: (value: TextEditorType) => !textEditorUtils.hasLargeFiles({ value })
        } as ValidationType;
    }


    const notFloatNumber = (text = "This field should be only numbers!") => {
        return {
            type: "notFloatNumber",
            text: text,
            isValid: (value: any) => /^[0-9\.]*$/.test(value),
        } as ValidationType;
    }


    const notEnglish = (text = "This field must contain English letters and numbers!") => {
        return {
            type: "notEnglish",
            text: text,
            isValid: (value: any) => /^[A-Za-z0-9]*$/.test(value),
        } as ValidationType;
    }


    const notSpecialChars = (text = "This field must not contain Special characters!") => {
        return {
            type: "notSpecialChars",
            text: text,
            isValid: (value: any) => /^[A-Za-z0-9]*$/.test(value),
        } as ValidationType;
    }

    const notSpecificCharsUsed = (chars = [""], text = "This field should not contain these characters: ") => {
        return {
            type: "notSpecificCharsUsed",
            text: text + chars.map(e => e === " " ? "space" : e).join(", "),
            isValid: (value: any) => !chars.some(e => value.includes(e)),
        } as ValidationType;
    }

    const tooSimplePassword = (text = "Your password needs to contain at least one lower case & one upper case & one special character & one number & has the minimum length of 8!") => {
        return {
            type: "tooSimplePassword",
            text: text,
            isValid: (value: any) => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,18}$/.test(value),
        } as ValidationType;
    }

    const isToDateValid = (toDate?: Date, text = "This field is exceeding the upper limit!") => {
        return {
            type: "isToDateValid",
            text: text,
            isValid: (value?: string | Date) => {
                let date = value;

                if (typeof value === "string" && !isEmpty(value)) {
                    date = parse(value, "y-MM-dd", dateGetToday());
                }

                return isValid(date) && toDate ?
                    dateGetOnlyDate(date)!.localeCompare(dateGetOnlyDate(toDate)!) <= 0 :
                    true;
            }
        } as ValidationType;
    }

    const isFromDateValid = (fromDate?: Date, text = "This field is exceeding the lower limit!") => {
        return {
            type: "isFromDateValid",
            text: text,
            isValid: (value?: string | Date) => {
                let date = value;

                if (typeof value === "string" && !isEmpty(value)) {
                    date = parse(value, "y-MM-dd", dateGetToday());
                }

                return isValid(date) && fromDate ?
                    dateGetOnlyDate(date)!.localeCompare(dateGetOnlyDate(fromDate)!) >= 0 :
                    true;
            }
        } as ValidationType;
    }

    const isDateValid = (text = "The date entered is not valid!") => {
        return {
            type: "isDateValid",
            text: text,
            isValid: (value?: string | Date) => {
                let date = value;

                if (typeof value === "string" && !isEmpty(value)) {
                    date = parse(value, "y-MM-dd", dateGetToday());
                }

                return date ? isValid(date) : true
            }
        } as ValidationType;
    }

    const isDateRangeValid = (text = "The range date entered is not valid!") => {
        return {
            type: "isDateRangeValid",
            text: text,
            isValid: (value?: { from?: Date, to?: Date }) => Boolean(value?.from) && isValid(value?.from) && (value?.to ? isValid(value.from) : true)
        } as ValidationType;
    }

    const isDateAfter = (date: Date, text = "You can't select this!") => {
        return {
            type: "isDateValid",
            text: text,
            isValid: (value: Date | string) => {
                if (value === "" || value === undefined) {
                    return true;
                }

                return value > date;
            }
        } as ValidationType;
    }

    return {
        isRequired,
        notEmptyList,
        notLargeFilesInEditor,
        notFloatNumber,
        notEnglish,
        notSpecialChars,
        notSpecificCharsUsed,
        tooSimplePassword,
        isToDateValid,
        isFromDateValid,
        isDateValid,
        isDateRangeValid,
        isDateAfter,
    }

}

