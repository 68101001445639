import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { UserRoles } from "models/User";

import { useMainContext } from "providers/MainProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";

import SideMenuCollapse from "./SideMenuCollapse";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import { useThemeContext } from "providers/ThemeProvider";

import { FaGears, FaRegBell } from "react-icons/fa6";
import { PiHeadset } from "react-icons/pi";
import { AiOutlineHome } from "react-icons/ai";
import { LuBell, LuListTree, LuMoon, LuSun, LuUserCog } from "react-icons/lu";
import { cn, listFirst } from "services/UtilServices";
import { Separator } from "components/ui/separator";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import useOutsideClick from "components/common/useOutsideClick";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import EffortBanner from "components/pages/EffortsSection/EffortBanner";
import { EachPanelItem } from "./LeftPannel";
import { SheetClose } from "components/ui/sheet";

function EffortItemSection({
  efforts,
  effortId,
}: {
  efforts: Effort[];
  effortId: number;
}) {
  const _currentUserContext = useCurrentUserContext();
  const textEditorUtils = useTextEditorUtils();
  const _effortUtils = useEffortUtils();
  const effort = efforts.find((e) => e.id === effortId)!;

  const subEffortsDeep = _effortUtils.getSubEffortsDeep({
    id: effort.id,
  });

  return (
    <SideMenuCollapse
      title={effort.title}
      icon={
        <EffortBanner
          effort={effort}
          className="object-cover rounded border w-7 h-7"
        />
      }
      url={_effortUtils.getUrl(effortId)}
      isProject={_effortUtils.isProject(effort)}
      matchUrls={
        subEffortsDeep
          .map((e) => _effortUtils.getUrl(e.id))
          .filter((e) => e) as string[]
      }
      notifCount={
        _currentUserContext.user!.logs?.filter((e) =>
          subEffortsDeep.some((a) => a.id === e.effortId)
        ).length
      }
    >
      {efforts
        .filter((e) => e.parentId === effort.id)
        .sort((a, b) => (a.dateAdd < b.dateAdd ? 1 : -1))
        .map((eachEffort) => (
          <EffortItemSection
            key={"eachEffort" + eachEffort.id}
            efforts={efforts}
            effortId={eachEffort.id}
          />
        ))}
    </SideMenuCollapse>
  );
}

function EffortsSection() {
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();

  return (
    <EmptyListIndicator wrapperClassName="h-full">
      {_mainContext.efforts
        ?.filter((e) => _effortUtils.isProject(e))
        .sort((a, b) => (a.dateAdd < b.dateAdd ? 1 : -1))
        .map((e) => (
          <EffortItemSection
            key={"eachEffort" + e.id}
            efforts={_mainContext.efforts!}
            effortId={e.id}
          />
        ))}
    </EmptyListIndicator>
  );
}

export function DesktopSideMenu({
  children,
  isMini = false,
}: {
  children?: React.ReactNode;
  isMini?: boolean;
}) {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();
  const mediaQuery = useMediaQuery();
  const _themeContext = useThemeContext();

  React.useEffect(() => {
    if (!_mainContext.sideMenuIsMini && mediaQuery <= MediaQuery2Num.xl) {
      _mainContext.setSideMenuIsMini(true);
    }
  }, [mediaQuery]);

  return (
    <>
      {!_mainContext.sideMenuIsMini && mediaQuery <= MediaQuery2Num.xl && (
        <div
          onClick={() => _mainContext.setSideMenuIsMini(true)}
          className="fixed inset-0 xl:hidden z-30 bg-background/50 backdrop-blur-sm"
        ></div>
      )}

      <div className={cn("flex transition-all duration-100 h-full ")}>
        <div className="flex flex-col h-full p-1 w-14 py-2">
          {_currentUserContext.isSudo() && (
            <EachPanelItem
              to="/admin"
              isMini={true}
              icon={<LuUserCog className="text-2xl" />}
              text="Admin"
            />
          )}

          <EachPanelItem
            to="/"
            exact
            isMini={true}
            icon={<AiOutlineHome className="text-2xl" />}
            text="Home"
          />
          {/* 
        <EachPanelItem
          to="/news"
          exact
          isMini={true}
          icon={<LuBell />}
          text="News"
          notifCount={
            _currentUserContext.user?.logs?.filter((e) =>
              _mainContext.efforts?.some((a) => a.id === e.effortId)
            ).length
          }
        /> */}

          <Separator className="my-2" />

          <EachPanelItem
            type="BUTTON"
            isMini={true}
            icon={<LuListTree className="text-2xl" />}
            text="Tree"
            isActive={!_mainContext.sideMenuIsMini}
            onClick={() =>
              _mainContext.setSideMenuIsMini(!_mainContext.sideMenuIsMini)
            }
          />

          <div className="flex-1" />

          <EachPanelItem
            type="BUTTON"
            isMini={true}
            icon={
              _themeContext.isDark ? <LuSun /> : <LuMoon className="text-2xl" />
            }
            text="Tree"
            onClick={() =>
              _themeContext.setDarkModeState(
                _themeContext.isDark ? "light" : "dark"
              )
            }
          />

          <EachPanelItem
            to="/about"
            icon={<PiHeadset className="text-2xl" />}
            text="Support"
            isMini={true}
          />
        </div>

        <div className="relative z-40 ">
          <div
            className={cn(
              "overflow-y-auto bg-background border-e scrollbar-hide h-full border-s transition-all",
              " duration-100  py-2 max-xl:shadow-[rgba(0,0,15,0.2)_2px_0px_4px_0px] max-xl:absolute ",
              _mainContext.sideMenuIsMini ? "opacity-0" : "px-2 w-80"
            )}
          >
            {!_mainContext.sideMenuIsMini && <EffortsSection />}

            <div className="py-5"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export function MobileSideMenu({
  children,
  isMini = false,
}: {
  children?: React.ReactNode;
  isMini?: boolean;
}) {
  const _currentUserContext = useCurrentUserContext();

  return (
    <div className="flex flex-col h-screen ">
      {_currentUserContext.isSudo() && (
        <EachPanelItem
          className="px-3"
          to="/admin"
          isMini={isMini}
          icon={<FaGears className="text-2xl" />}
          text="Admin"
        />
      )}

      <EachPanelItem
        className="px-3"
        to="/"
        exact
        isMini={isMini}
        icon={<AiOutlineHome className="text-2xl" />}
        text="Home"
      />
      {/* 
      <SheetClose>
        <EachPanelItem
          className="px-3"
          to="/news"
          exact
          isMini={isMini}
          icon={<LuBell />}
          text="News"
          notifCount={_currentUserContext.user?.logs?.length}
        />
      </SheetClose> */}

      <Separator className="mb-2 mt-0" />

      <EffortsSection />

      <div className="mt-auto py-4">
        <EachPanelItem
          className="px-3"
          to="/about"
          icon={<PiHeadset className="text-2xl" />}
          text="Support"
          isMini={isMini}
        />
      </div>

      <div className="py-3"></div>
    </div>
  );
}
