import React from "react";

import Office, {
  OfficeFilter,
  OfficeFormType,
  OfficeSortType,
} from "models/Office";
import Dict from "models/Dict";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";
import { useOfficesContext, OfficesProvider } from "providers/OfficesProvider";
import { useModalContext } from "providers/ModalProvider";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import OfficeCreateUpdateModal from "./OfficeCreateUpdateModal";
import OfficeFilterModal from "./OfficeFilterModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import useOfficeUtils from "hooks/utils/UseOfficeUtils";

function EachOfficeItem({ office, index }: { office: Office; index: number }) {
  return (
    <div className="flex items-center">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 w-5 h-5 "
        )}
        style={{
          background: office.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{office.name}</h6>
      </div>
    </div>
  );
}

function OfficesPageBody() {
  const _modalContext = useModalContext();
  const _officesContext = useOfficesContext();
  const officeUtils = useOfficeUtils();

  const onRemove = async (datas: Office[]) => {
    _officesContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _officesContext.update(data)
        : await _officesContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <OfficeCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _officesContext.get({
      ...formData,
      pageNumber: -1,
    } as OfficeFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <OfficeFilterModal
        filter={_officesContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <main role="main" className="md:p-4 p-3 h-full">
      <ItemsListSection<Office>
        title="Offices"
        data={_officesContext.offices}
        // ellipsisResponsiveBreakPoint=""

        fields={[
          // {
          //   value: "",
          //   label: "#",
          //   className: "text-start whitespace-nowrap",
          //   width: "70px",
          //   isSortable: false,
          //   getUiContent: (e, i) => i + 1,
          // },

          {
            value: "name",
            label: "name",
            isTitleField: true,
            width: "20%",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "color",
            label: "color",
            width: "10%",
            isSortable: false,
            isSearchable: false,
            getUiContent: (e, i) => (
              <div
                className={cn("rounded-full aspect-square w-7 h-7 ")}
                style={{
                  background: e.color,
                }}
              />
            ),
          },

          {
            value: "manager",
            label: "manager",
            width: "auto",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "location",
            label: "location",
            width: "auto",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "dateAdd",
            label: "Created on",
            width: "auto",
            // isSortable: false,
            getValue: (e) => dateGetTime(e.dateAdd),
            getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
            className: "whitespace-nowrap",
          },
        ]}
        onLoadMore={
          !_officesContext.hasNext
            ? undefined
            : async () => {
                await _officesContext.get({
                  ..._officesContext.filter,
                  pageNumber: _officesContext.filter.pageNumber + 1,
                });
              }
        }
        onSortField={(v) => {
          _officesContext.get({
            ..._officesContext.filter,
            sortBy: v as OfficeSortType,
            pageNumber: -1,
          });
        }}
        onSortDir={(v) => {
          _officesContext.get({
            ..._officesContext.filter,
            ascOrder: v === 1,
            pageNumber: -1,
          });
        }}
        initialSelectedSortFieldValue="dateAdd"
        buildCardTitleRow={(v, i) => <EachOfficeItem office={v} index={i} />}
        onItemEdit={(u) => openCreateUpdateModal}
        onItemClick={(u) => openCreateUpdateModal}
        onItemDelete={() => _officesContext.removeMany}
        actionsSection={
          <>
            <FilterButton
              count={_officesContext.totalNumber}
              isActive={officeUtils.isFilterActive({
                filter: _officesContext.filter,
              })}
              onClick={openFilterModal}
            />

            <NewButton onClick={() => openCreateUpdateModal()} />
          </>
        }
      />
    </main>
  );
}

export default function OfficesPage() {
  return (
    <OfficesProvider>
      <OfficesPageBody />

      <MobileFooterPadding />
    </OfficesProvider>
  );
}
