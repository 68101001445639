import { useEffect } from "react";

const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  callback: any
) => {
  const handleClick = (ev: MouseEvent) => {
    // console.log("ehsan", ref.current, ev.target, ev.currentTarget);
    // const rect = ref.current?.getBoundingClientRect();

    // console.log(
    //   [
    //     ref.current
    //     ref.current?.clientLeft,
    //     ref.current?.clientWidth,
    //     ref.current?.clientTop,
    //     ref.current?.clientHeight,
    //   ],
    //   [ev.clientX, ev.clientY],
    //   ref.current &&
    //     rect &&
    //     ev.clientX <= ref.current.clientLeft + ref.current.clientWidth &&
    //     ev.clientX >= ref.current.clientLeft &&
    //     ev.clientY >= ref.current.clientTop + ref.current.clientHeight &&
    //     ev.clientY <= ref.current.clientTop
    // );

    if (ref.current && !ref.current.contains(ev.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
