import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { cn, isFarsi } from "services/UtilServices";

import Skeleton from "components/common/Skeleton";
import Breadcrumb from "components/common/Breadcrumb";
import Cheeps from "components/common/Cheeps";
import { ViewTypeRow } from "../EffortsSection/ViewTypeSelectButton";
import EffortMenuButton from "./EffortMenuButton";
import { useSidePanelSections } from "./SidePanel";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import { TabItems } from "components/common/Tabs";
import EffortBanner from "../EffortsSection/EffortBanner";
import { FaEllipsisVertical } from "react-icons/fa6";

export default function InfoSection({ effort }: { effort: Effort }) {
  const mediaQuery = useMediaQuery();
  const _effortUtils = useEffortUtils();
  const SECTIONS = useSidePanelSections(effort, effort.level < 2);

  return (
    <div className="">
      <div className="m-3">
        <Breadcrumb effort={effort} />
      </div>
      <div className="md:mx-3 mx-2 mt-3 p-4 rounded border">
        <div className="items-start 2xl:items-center 2xl:flex  gap-2 min-w-0 min-h-0">
          <div className={cn("flex items-center gap-2 min-w-0 min-h-0  ")}>
            <EffortMenuButton
              effort={effort}
              ellipsisClassName="opacity-100 p-0 group whitespace-normal "
            >
              <FaEllipsisVertical className="group-hover:opacity-100 opacity-50" />

              <EffortBanner
                effort={effort}
                className="object-cover aspect-square border rounded h-10 w-10"
              />

              <div className=" flex-1 capitalize">
                <span>
                  {effort.prefix && (
                    <span className="mb-0 pe-2 inline-block ">
                      {effort.prefix?.name}
                    </span>
                  )}

                  <h2
                    className={cn("mb-0 inline-block text-start text-lg  ", {
                      "direction-rtl": isFarsi(effort.title),
                    })}
                  >
                    {effort.title}
                  </h2>
                </span>
              </div>
            </EffortMenuButton>

            {effort.isActive ? (
              effort.state === undefined ? (
                <Skeleton />
              ) : (
                <Cheeps color={effort.state?.color}>
                  {effort.state!.name}
                </Cheeps>
              )
            ) : (
              <Cheeps color="#00000055">Not Active</Cheeps>
            )}

            {/* <MiniMembersSection effort={effort} className="hidden md:flex" /> */}
          </div>

          <div className="flex-1"></div>

          {effort.level < 2 && (
            <>
              <div className="my-3"></div>
              {mediaQuery <= MediaQuery2Num.md ? (
                <TabItems
                  items={SECTIONS}
                  activeClassName="bg-muted"
                  className="bg-transparent p-0 border-0"
                  baseUrl={_effortUtils.getUrl(effort.id)}
                />
              ) : (
                <ViewTypeRow />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
