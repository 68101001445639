import React from "react";
import { convert2Date } from "services/UtilServices";

export default function LocalDateTime({
  date,
  needTime = false,
  needDate = true,
  needYear = false,
  ...props
}: {
  date?: string | number | Date | null;
  needTime?: boolean;
  needDate?: boolean;
  needYear?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>) {
  const _converteddate = convert2Date(date);
  const _isCurrentYear =
    _converteddate?.getFullYear() === new Date().getFullYear();

  return (
    <>
      {date && (
        <span title={_converteddate + ""} {...props}>
          {needDate &&
            _converteddate?.toLocaleDateString("en-GB", {
              month: "numeric",
              day: "numeric",
              year: needYear || !_isCurrentYear ? "2-digit" : undefined,
            })}{" "}
          {needTime && _converteddate?.toLocaleTimeString()}
        </span>
      )}
    </>
  );
}
