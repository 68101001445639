import Activity, { ActivityFilter } from "models/Activity";
import User, { CurrentUser, UserRoles } from "models/User";
import {
  isDateUntil,
  hasOtherKeysExcept,
  isEmpty,
} from "services/UtilServices";
import useAuthUtils from "hooks/UseAuthUtils";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { MemberAccessTypes } from "models/Member";
import { useToast } from "components/ui/use-toast";
import Dict from "models/Dict";
import { useActivitysContext } from "providers/ActivitysProvider";
import { useEachEffortContext } from "providers/EachEffortProvider";
import { useModalContext } from "providers/ModalProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";
import { useEffortUtils } from "./UseEffortUtils";
import ActivityCreateUpdateModal from "components/pages/EachEffort/ActivitysSection/ActivityCreateUpdateModal";
import ActivityFilterModal from "components/pages/EachEffort/ActivitysSection/ActivityFilterModal";
import Effort from "models/Effort";

export function useActivityViewUtils(effortId?: number | null) {
  const { toast } = useToast();
  const _effortUtils = useEffortUtils();
  const _modalContext = useModalContext();
  const _activitysContext = useActivitysContext();
  const _eachEffortContext = useEachEffortContext();
  const _typesContext = useTypesContext();
  const _usersContext = useUsersContext();

  const onRemove = async (datas: Activity[]) => {
    _activitysContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _activitysContext.update(data)
        : await _activitysContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData: { effortId: number } & Dict) => {
    let _project = _effortUtils.getParentProject(formData.effortId);

    // if (_project === undefined) {
    //   toast({
    //     variant: "destructive",
    //     description: "Project Not Found!",
    //   });
    // } else
    if (_project && _project.types?.length === 0) {
      toast({
        variant: "destructive",
        description:
          "You need to add a type to your project before you'd be able to create an activity!",
      });
    } else {
      _modalContext.open(
        <ActivityCreateUpdateModal formData={formData} onSubmit={onSubmit} />
      );
    }
  };

  const onFilterSubmit = (formData: Dict) => {
    _activitysContext.get({
      ...formData,
      pageNumber: -1,
    } as ActivityFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    if (!effortId) {
      throw new Error("Effort is required");
    }

    _typesContext.getAll(effortId);
    _usersContext.getAll(effortId);

    _modalContext.open(
      <ActivityFilterModal
        filter={_activitysContext.filter}
        onSubmit={onFilterSubmit}
        effortId={effortId}
      />
    );
  };

  return {
    onRemove,
    onSubmit,
    openCreateUpdateModal,
    onFilterSubmit,
    openFilterModal,
  };
}

export default function useActivityUtils(effortId?: number | null) {
  const _authUtils = useAuthUtils();
  const _currentUserContext = useCurrentUserContext();

  const canCreate = (effort?: Effort | null) => {
    let _accessType = _authUtils.authorize(effort?.id);
    return _accessType >= MemberAccessTypes.Create && effort?.parentId;
  };

  const canEdit = (item: Activity) => {
    let _accessType = _authUtils.authorize(effortId);
    return (
      _currentUserContext.user?.role !== UserRoles.USER ||
      (!item.isApproved && _accessType >= MemberAccessTypes.Edit) ||
      (!item.isApproved &&
        _accessType >= MemberAccessTypes.Create &&
        _currentUserContext.user?.id === item.userId &&
        isDateUntil({ date: item.dateAdd, days: 2 }))
    );
  };

  const canDelete = () => {
    return _currentUserContext.user?.role !== UserRoles.USER;
  };

  const canUpdateApproved = (item: Activity) => {
    let _accessType = _authUtils.authorize(effortId);
    return (
      _currentUserContext.user!.role !== UserRoles.USER ||
      (!item.isApproved && _accessType >= MemberAccessTypes.Delete)
    );
  };

  const isFilterActive = ({ filter }: { filter: ActivityFilter }) => {
    return hasOtherKeysExcept(filter, [
      "pageNumber",
      "ascOrder",
      "sortBy",
      "numberInPage",
      "effortIds",
    ]);
  };

  return {
    canCreate,
    canEdit,
    canDelete,
    isFilterActive,
    canUpdateApproved,
  };
}
