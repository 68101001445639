import React from "react";
import { FaPlus } from "react-icons/fa6";

import Link from "./Link";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { useMainContext } from "providers/MainProvider";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";

interface Props {
  src: string | File | FileList;
  [rest: string]: any;
}

export default function Breadcrumb({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();

  let _parents = _effortUtils.getParentEffortsDeep(effort.id);

  return (
    <div className="flex items-center scrollbar-hide overflow-x-auto capitalize text-foreground ">
      <Link href="/" className=" p-1 ">
        <AiOutlineHome />
      </Link>
      <span className="mx-1 "> /</span>

      {_parents
        .slice()
        .reverse()
        .map((e) => (
          <React.Fragment key={"eachBreadcrumbItem" + e.url}>
            <Link className=" p-1 " href={_effortUtils.getUrl(e.id)}>
              {e.title}
            </Link>

            <span className="mx-1">/</span>
          </React.Fragment>
        ))}

      <span className="whitespace-nowrap text-secondary-foreground">
        {effort.title}
      </span>
    </div>
  );
}
