import React from "react";

import Dict from "models/Dict";

import {
  getItem,
  objGetParameterCaseInsensitive,
  isEmpty,
  cn,
  strSplitCammelCase,
} from "services/UtilServices";

import { ValidationType } from "./UseValidation";
import { useFormContext, FormContextInterface } from "./Form";

import { LuX } from "react-icons/lu";
import MenuButton from "components/ui/menu-button";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import useValidation from "./UseValidation";

export interface ActionItemType {
  icon?: React.ReactNode;
  text: string;
  onClick?: () => void;
  multiple?: boolean;
}

export interface FormFieldBaseChildrenProps {
  error?: string;
  isRequired: boolean;
  isFocused: boolean;
  label: React.ReactNode;
  nullableBtn: React.ReactNode;
  onNullBtnClick?: (f: FormContextInterface) => void;
  isLoading: boolean;

  value: any;
  setData: (d: Dict) => void;
}

export interface FormFieldBaseInputProps {
  name: string;
  label?: string;
  description?: string | React.ReactNode;
  value?: any;
  validations?: ValidationType[];
  needLabel?: boolean;
  needMarginBottom?: boolean;
  needNullBtn?: boolean;
  needFocus?: boolean;
  icon?: React.ReactNode;

  onNullBtnClick?: (f: FormContextInterface) => void;

  actions?: (ActionItemType | React.ReactNode)[];

  [rest: string]: any;
}

interface Props extends FormFieldBaseInputProps {
  children: (d: FormFieldBaseChildrenProps) => React.ReactNode;
}

export function NullableButton({
  onClick: onNullBtnClick,
}: {
  onClick: (f: FormContextInterface) => void;
}) {
  const _formContext = useFormContext();

  return (
    <Button
      size={"sm"}
      variant={"light"}
      onClick={(e) => {
        e.stopPropagation();
        onNullBtnClick(_formContext);
      }}
      className=" rounded-full aspect-square flex items-center justify-center opacity-70 hover:opacity-50 bg-muted border p-0 w-5 h-5 "
    >
      <LuX className="!text-sm" />
    </Button>
  );
}

export default function FormFieldBase({
  name,
  label,
  description,
  value,
  validations = [],
  icon,
  needLabel = true,
  needMarginBottom = true,
  needNullBtn = true,
  onNullBtnClick,
  needFocus = true,
  children,

  actions,

  ...rest
}: Props) {
  const _formContext = useFormContext();
  const [_focused, _setFocused] = React.useState(!needFocus);
  const [_value, _setValue] = React.useState();
  const validation = useValidation();

  if (rest.required && !validations?.some((e) => e.type === "isRequired")) {
    validations = [validation.isRequired(), ...(validations ?? [])];
  }

  const isFieldRequired = validations?.some(
    (each) => each.type === "isRequired"
  );
  if (needNullBtn) {
    needNullBtn = !isFieldRequired;
  }

  React.useEffect(() => {
    if (value !== undefined && !(name in _formContext.data)) {
      _formContext.setData({ [name]: value });
    }
  }, [value]);

  React.useEffect(() => {
    // if(_formContext.data[name])
    // {
    //   checkError();
    // }
    checkError();
  }, [_formContext.data[name], validations]);

  const checkError = () => {
    let error = validations?.find(
      (e) => !e.isValid(_formContext.data[name])
    )?.text;

    if (error !== _formContext.localErrors[name]) {
      _formContext.setLocalErrors(name, error);
    }
  };

  let error = _formContext.localErrors[name];
  if (_formContext.submitErrors) {
    error = getItem(
      0,
      objGetParameterCaseInsensitive(_formContext.submitErrors, name)
    );
  }

  const _label = (
    <span>
      {icon} {label ?? name}
      {isFieldRequired && <span className="text-destructive">*</span>}
    </span>
  );

  const _nullableBtn = needNullBtn && !isEmpty(_formContext.data[name]) && (
    <NullableButton
      onClick={() => {
        if (onNullBtnClick) onNullBtnClick(_formContext);
        else _formContext.setData({ [name]: null });
      }}
    />
  );

  return (
    <div
      className={cn("is-invalid ", { "mb-4": needMarginBottom })}
      onBlur={() => {
        _setFocused(true);
        checkError();
      }}
      onKeyDown={() => {
        _setFocused(true);
        checkError();
      }}
      onClick={() => {
        _setFocused(true);
        checkError();
      }}
    >
      {needLabel && (
        <div className="flex items-center is-invalid gap-2 mb-1">
          <Label
            className="capitalize form-label is-invalid mb-1"
            htmlFor={name}
          >
            {icon} {label ?? strSplitCammelCase(name)}
            {isFieldRequired && (
              <span className="text-destructive dark:font-bold">*</span>
            )}
          </Label>

          <div className="-mt-1">
            {actions
              ?.filter((e) => !(e && typeof e === "object" && "text" in e))
              .map((e, i) => (
                <React.Fragment key={"eachAction" + i}>
                  {e as React.ReactNode}
                </React.Fragment>
              ))}

            <MenuButton
              btnsClassName="p-0 "
              items={
                actions?.filter(
                  (e) => e && typeof e === "object" && "text" in e
                ) as ActionItemType[]
              }
            />
          </div>
          <div className="flex-1"></div>

          {_nullableBtn}
        </div>
      )}

      {children({
        error,
        label: _label,
        isFocused: _focused,
        nullableBtn: _nullableBtn,
        isRequired: isFieldRequired,
        isLoading: _formContext.submitErrors === undefined,

        value: _formContext.data[name],
        setData: _formContext.setData,
      })}

      {error && (_focused || !isEmpty(_formContext.submitErrors)) && (
        <div className="text-destructive dark:font-bold text-sm mt-2 capitalize-first-letter">
          <span>{error}</span>
        </div>
      )}

      {description && (
        <div className="mt-1 text-sm text-justify capitalize-first-letter text-foreground ">
          {description}
        </div>
      )}
    </div>
  );
}
