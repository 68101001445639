import React from "react";

import User from "models/User";
import useUserUtils from "hooks/utils/UseUserUtils";
import Avatar, { AvatarProps } from "components/common/Avatar";
import { ApiVariables } from "hooks/api/UseApiServices";
import UserProfileHoverCard from "components/pages/admin/UsersSection/UserProfileHoverCard";

export type ProfileAvatarProps = {
  user?: User;
  needName?: boolean;
  needHover?: boolean;
  needAvatar?: boolean;
  align?: "center" | "start" | "end";
  side?: "top" | "right" | "bottom" | "left";
} & Omit<AvatarProps, "src">;

export default function ProfileAvatar({
  user,
  needHover = true,
  needName,
  needAvatar = true,
  align,
  side,
  ...props
}: ProfileAvatarProps) {
  const _userUtils = useUserUtils();

  const _avatar = (
    <div className="flex gap-1 items-center">
      {needAvatar && (
        <Avatar
          placeholder="blurred"
          title={_userUtils.getFullName(user)}
          {...props}
          secure={false}
          src={
            (user?.avatarUrl?.startsWith("http")
              ? ""
              : ApiVariables.PUBLIC_FILE_URL) +
            // + user?.avatarUrl
            user?.avatarUrl?.replace(".", "_256.")
          }
        />
      )}
      {needName && _userUtils.getFullName(user)}
    </div>
  );

  return needHover ? (
    <UserProfileHoverCard user={user} align={align} side={side}>
      {_avatar}
    </UserProfileHoverCard>
  ) : (
    _avatar
  );
}
