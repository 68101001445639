import React, { useContext } from "react";
import { ValidationType } from "./UseValidation";
import { cn, isFarsi } from "services/UtilServices";
import { Textarea } from "components/ui/textarea";

interface Props {
  value?: string | number;
  onChange?: (value: string | number) => void;
  validations?: ValidationType[];
  textarea?: boolean;
  className?: string;
  [rest: string]: any;
}

const InputStandalone = ({
  value,
  onChange = () => {},
  validations,
  textarea = false,
  className = "form-control ",
  ...rest
}: Props) => {
  const [_value, _setValue] = React.useState<string | number | undefined>(
    value
  );

  React.useEffect(() => {
    _setValue(value);
  }, [value]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    _setValue(event.target.value);
    onChange(event.target.value);
  };

  return textarea ? (
    <Textarea
      onChange={handleChange}
      value={_value === undefined ? "" : _value}
      className={cn(className, { "direction-rtl": isFarsi(_value as string) })}
      {...rest}
    />
  ) : (
    <input
      onChange={handleChange}
      value={_value === undefined ? "" : _value}
      className={cn(className, { "direction-rtl": isFarsi(_value as string) })}
      {...rest}
    />
  );
};

export default InputStandalone;
