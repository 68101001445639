import React from "react";

import Dict from "models/Dict";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import { MobileFooterPadding } from "components/layouts/MainFooter";
import { Switch, Form, SubmitButton } from "components/common/Forms/Form";
import { Button } from "components/ui/button";
import { cn } from "services/UtilServices";

function EachSettingItem({
  name = "",
  label = "",
  description = "",
  onClick = (n: string) => {},
}) {
  return (
    <Button
      variant={"light"}
      onClick={() => onClick(name)}
      className=" justify-start px-3 pt-3 border bg-card mt-2 w-full"
    >
      <Switch
        name={name}
        label={label}
        description={description}
        needNullBtn={false}
      />
    </Button>
  );
}

function SettingsSection() {
  const _currentUserContext = useCurrentUserContext();
  const [loading, setLoading] = React.useState(false);

  const onClick = async (name: string) => {
    setLoading(true);
    let _errors = await _currentUserContext.updateInfo({
      ..._currentUserContext.user,
      [name]: !((_currentUserContext.user as Dict)[name] as boolean),
    });
    setLoading(false);

    return _errors;
  };

  return (
    <>
      <section className="p-3 mx-auto max-w-[600px]">
        <div className="text-justify mb-3 text-foreground">
          In this section, you can change the notification settings and choose
          whether or not toreceive notifications for each of the following
          items.
        </div>

        <Form
          data={_currentUserContext.user}
          key={JSON.stringify(_currentUserContext.user)}
          className={cn({ "pointer-events-none animate-pulse": loading })}
        >
          <EachSettingItem
            onClick={onClick}
            name="newEffort"
            label="Project/Task Creation"
            description="Send Email When a project or task has been created"
          />
          <EachSettingItem
            onClick={onClick}
            name="effortUpdate"
            label="Project/Task Update"
            description="Send Email When a project or task has been created"
          />
          <EachSettingItem
            onClick={onClick}
            name="newComment"
            label="New Comment"
            description="Send Email When a project or task has been created"
          />
          <EachSettingItem
            onClick={onClick}
            name="commentUpdate"
            label="Comment Update"
            description="Send Email When a project or task has been created"
          />
        </Form>

        <MobileFooterPadding />
      </section>
    </>
  );
}

export default SettingsSection;
