import React from "react";

import Dict from "models/Dict";
import { EffortFilter } from "models/Effort";
import useUserUtils from "hooks/utils/UseUserUtils";

import { hasOtherKeysExcept, objList2Options } from "services/UtilServices";

import { useStatesContext } from "providers/StatesProvider";
import { useUsersContext } from "providers/UsersProvider";
import { useMainContext } from "providers/MainProvider";

import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import { useDepartmentsContext } from "providers/DepartmentsProvider";
import { usePrefixsContext } from "providers/PrefixsProvider";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { useTypesContext } from "providers/TypesProvider";
import { useTagsContext } from "providers/TagsProvider";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import { useTicketsContext } from "providers/TicketsProvider";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

export default function EffortFilterModal({
  title,
  filter,
  onSubmit,
  parentId = null,
}: {
  title?: string;
  filter: EffortFilter;
  onSubmit: (data: Dict) => void;
  parentId?: number | null;
}) {
  const _statesContext = useStatesContext();
  const _typesContext = useTypesContext();
  const _tagsContext = useTagsContext();
  const _usersContext = useUsersContext();
  const _departmentsContext = useDepartmentsContext();
  const _prefixsContext = usePrefixsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _ticketsContext = useTicketsContext();
  const _effortUtils = useEffortUtils();
  const _userUtils = useUserUtils();

  let _tags = _tagsContext.tags;
  let _users = _usersContext.users;
  let _types = _typesContext.types;
  let _states = _statesContext.states;
  let _prefixes = _prefixsContext.prefixs;
  let _departments = _departmentsContext.departments;
  let _priorities = _prioritiesContext.priorities;
  let _tickets = _ticketsContext.tickets;

  // console.log("Effort filter modal prefixes : ",)
  // if (parentId !== null) {
  //   let _project = _effortUtils.getParentProject(
  //     _mainContext.efforts!,
  //     parentId
  //   );

  //   _users = _project?.members?.map((e) => e.user);
  //   _states = _project?.states;
  //   _prefixes = _project?.prefixes;
  //   _types = _project?.types;
  //   _tags = _project?.tags;
  // }

  return (
    <DialogContent
      size="lg"
      title={title ? `Filter in "${title}"` : "Filter in Projects"}
    >
      <Form data={filter} onSubmit={onSubmit}>
        <div className="inline-flex">
          <Switch name="isActive" label="Active Projects" />
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Input name="title" label="Title" placeholder="Title..." />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="memberIds"
              label="Member"
              multiple
              options={_users?.map((e) => ({
                value: e.id,
                label: _userUtils.getFullName(e),
              }))}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <DatePickerInputRange name="dateAdd" label="Creation Date" />
          </div>
          <div className="col-12 lg:col-6">
            <DatePickerInputRange name="dateDue" label="Deadline" />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="creatorIds"
              label="Creator"
              multiple
              options={_users?.map((e) => ({
                value: e.id,
                label: _userUtils.getFullName(e),
              }))}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="stateIds"
              label="State"
              multiple
              options={objList2Options(_states)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="typeIds"
              label="type"
              multiple
              options={objList2Options(_types)}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="tagIds"
              label="tag"
              multiple
              options={objList2Options(_tags)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="priorityIds"
              label="priority"
              multiple
              options={objList2Options(_priorities)}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="ticketIds"
              label="ticket"
              multiple
              options={objList2Options(_tickets)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            {parentId == null && (
              <Select
                name="departmentIds"
                label="Department"
                multiple
                options={objList2Options(_departments)}
              />
            )}
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="prefixIds"
              label="Prefix"
              multiple
              options={objList2Options(_prefixes)}
            />
          </div>
        </div>

        <DialogFooter className="row items-center justify-center gap-2 w-full">
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={_effortUtils.isFilterActive({ filter, parentId })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
