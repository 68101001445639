import React from "react";
import Chart from "react-apexcharts";

import Dict from "models/Dict";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import useUserUtils from "hooks/utils/UseUserUtils";

import {
  getItem,
  randomHexColor,
  strCapitalizefirstLetter,
} from "services/UtilServices";

import { useUsersContext } from "providers/UsersProvider";
import { Form, Select } from "components/common/Forms/Form";
import { useTagsContext, TagsProvider } from "providers/TagsProvider";
import { useTypesContext, TypesProvider } from "providers/TypesProvider";
import { useStatesContext, StatesProvider } from "providers/StatesProvider";
import { useEffortsContext, EffortsProvider } from "providers/EffortsProvider";
import { useTicketsContext, TicketsProvider } from "providers/TicketsProvider";
import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";

import LoadingSpinner from "components/common/LoadingSpinner";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import Skeleton from "components/common/Skeleton";
import { useThemeContext } from "providers/ThemeProvider";

function DonutChart({ parentId }: { parentId?: number | null }) {
  const _itemsListContext = useItemsListContext();
  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _statesContext = useStatesContext();
  const _usersContext = useUsersContext();
  const _ticketsContext = useTicketsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _effortUtils = useEffortUtils();
  const _userUtils = useUserUtils();
  const _themeContext = useThemeContext();

  const _efforts = _itemsListContext.data as Effort[];

  const basedOnTypes = [
    { value: "state", label: "state" },
    { value: "priority", label: "priority" },
    { value: "type", label: "type" },
    { value: "tag", label: "tag" },
    { value: "ticket", label: "ticket" },
    { value: "user", label: "user" },
  ];
  const [_basedOnType, _setBasedOnType] = React.useState<
    "state" | "priority" | "type" | "tag" | "ticket" | "user"
  >("state");

  let _data: Dict[] | undefined;
  let _series: number[] | undefined;
  let _colors: string[] | undefined;
  if (_basedOnType === "state") {
    _data = _statesContext.states;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { stateIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "priority") {
    _data = _prioritiesContext.priorities;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { priorityIds: [eachItem.id] })?.length ??
        0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "type") {
    _data = _typesContext.types;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { typeIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "tag") {
    _data = _tagsContext.tags;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { tagIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "ticket") {
    _data = _ticketsContext.tickets;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { ticketIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "user") {
    _data = _usersContext.users?.map((e) => ({
      id: e.id,
      name: _userUtils.getFullName(e),
      color: randomHexColor(),
    }));
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { memberIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  }

  React.useEffect(() => {
    if (_basedOnType === "state") {
      _statesContext.getAll(parentId);
    } else if (_basedOnType === "type") {
      _typesContext.getAll(parentId);
    } else if (_basedOnType === "tag") {
      _tagsContext.getAll(parentId);
    } else if (_basedOnType === "user") {
      _usersContext.getAll(parentId);
    } else if (_basedOnType === "ticket") {
      _ticketsContext.getAll(parentId);
    } else if (_basedOnType === "priority") {
      _prioritiesContext.getAll(parentId);
    }
  }, [_basedOnType]);

  return (
    <div className="h-96">
      <div className="bg-card rounded border p-4 h-full">
        <div className="flex items-center">
          <h6 className="mb-0 me-2 text-foreground">Based On: </h6>

          <Form
            data={{ basedOnType: _basedOnType }}
            onChange={(d) => {
              _setBasedOnType(d.basedOnType);
            }}
          >
            <Select
              name="basedOnType"
              options={basedOnTypes}
              needLabel={false}
              needMarginBottom={false}
              required
            />
          </Form>
        </div>

        {_data === undefined ? (
          <div className="flex flex-col items-center p-3">
            <Skeleton className="rounded-full aspect-square mx-auto w-3/4 max-w-[200px]" />
            <div className="mt-3 flex flex-wrap gap-2 items-center justify-center">
              <Skeleton className=" w-24 h-5" count={5} />
            </div>
          </div>
        ) : _efforts.length === 0 || _data.length === 0 ? (
          <EmptyListIndicator text="No Data" />
        ) : (
          <Chart
            type="donut"
            height="90%"
            series={_series}
            options={{
              chart: {
                foreColor: _themeContext.isDark ? "#ffffff" : undefined,
              },
              legend: {
                position: "right",
                // offsetY: 40,
                formatter: function (val, opts) {
                  return strCapitalizefirstLetter(
                    _data![opts.seriesIndex].name
                  )!;
                },
              },
              labels: _data!.map((e) => strCapitalizefirstLetter(e.name)!),
              colors: _colors,
              responsive: [
                {
                  breakpoint: 1440,
                  options: {
                    legend: {
                      position: "bottom",
                      // offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
            }}
          />
        )}
      </div>
    </div>
  );
}

function TicketsBarChart({ parentId }: { parentId?: number | null }) {
  let _colors: string[] | undefined;

  const _themeContext = useThemeContext();
  const _itemsListContext = useItemsListContext();
  const _ticketsContext = useTicketsContext();

  const _efforts = (_itemsListContext.data as Effort[]) ?? [];

  React.useEffect(() => {
    _ticketsContext.getAll(parentId);
  }, []);

  _colors = _ticketsContext.tickets?.map((e) => e.color);

  return (
    <div className="h-96">
      <div className="bg-card rounded border p-4 h-full overflow-x-auto">
        {_ticketsContext.tickets === undefined ? (
          <LoadingSpinner />
        ) : _efforts.length === 0 || _ticketsContext.tickets.length === 0 ? (
          <EmptyListIndicator text="No Data" />
        ) : (
          <Chart
            type="bar"
            height="100%"
            options={{
              chart: {
                foreColor: _themeContext.isDark ? "#ffffff" : undefined,
                type: "bar",
                stacked: true,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
              },
              colors: _colors,
              responsive: [
                {
                  breakpoint: 1440,
                  options: {
                    legend: {
                      position: "bottom",
                      // offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                      },
                    },
                  },
                },
              },
              xaxis: {
                type: "category",
                categories: _efforts?.map(
                  (e: Effort) =>
                    e.title.substring(0, 15) +
                    (e.title.length > 15 ? "..." : "")
                ),
              },
              legend: {
                position: "right",
                offsetY: 40,
              },
              fill: {
                opacity: 1,
              },
            }}
            series={_ticketsContext.tickets?.map((eachTicket) => ({
              name: eachTicket.name,
              data: _efforts?.map(
                (e) =>
                  e.ticketsCount?.find((a) => a.ticket.id === eachTicket.id)
                    ?.count ?? 0
              ),
            }))}
          />
        )}
      </div>
    </div>
  );
}

export default function EffortsListChartSection({
  parentId = null,
}: {
  parentId?: number | null;
}) {
  return (
    // <EffortsProvider parentId={parentId}>
    <TicketsProvider>
      <StatesProvider>
        <TagsProvider>
          <TypesProvider>
            <div className="grid grid-rows-2 lg:grid-cols-2 gap-2">
              <DonutChart parentId={parentId} />

              <TicketsBarChart parentId={parentId} />
            </div>
          </TypesProvider>
        </TagsProvider>
      </StatesProvider>
    </TicketsProvider>
    // </EffortsProvider>
  );
}
