import React from "react";

import UserLog from "models/UserLog";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { isEmpty } from "services/UtilServices";

import { useMainContext } from "providers/MainProvider";
import { useModalContext } from "providers/ModalProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";

import Link from "components/common/Link";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import LocalDateTime from "components/common/LocalDateTime";
import { LuBell } from "react-icons/lu";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Badge } from "components/ui/badge";
import UserLogsSection from "components/pages/UserLogsSection/UserLogsSection";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";

function EachItem({
  log,
  href,
  index,
  onClick,
}: {
  log: UserLog;
  href?: string;
  index: number;
  onClick: () => void;
}) {
  return (
    <>
      {href && (
        <Link
          to={href + "/Logs"}
          onClick={onClick}
          variant={"light"}
          className=" border w-full mb-1 "
        >
          {/* <h6 className="mb-0"> {index + 1} </h6> */}

          <div className="flex-1 ps-3 min-w-0">
            <div className="flex p-0 mb-2 items-center">
              <ProfileAvatar user={log.user} />

              <span className="capitalize mb-0 text-justify me-auto ms-1 text-xs">
                {log.user.userName}
              </span>
              <span className="mb-0 text-justify text-xs">
                <LocalDateTime date={log.dateAdd} />
              </span>
            </div>

            <div className="text-start truncate w-full">{log.action}</div>
          </div>
        </Link>
      )}
    </>
  );
}

export function NotificationsModalBody({
  logs,
  onDismiss,
}: {
  logs?: UserLog[];
  onDismiss: () => void;
}) {
  const _effortUtils = useEffortUtils();

  return (
    <DialogContent title="Notifications">
      {/* <EmptyListIndicator>
        {logs?.map((e, i) => (
          <EachItem
            key={"searchResult" + i}
            index={i}
            log={e}
            onClick={onDismiss}
            href={_effortUtils.getUrl(e.effortId)}
          />
        ))}
      </EmptyListIndicator> */}

      <UserLogsSection type="Notifications" />
    </DialogContent>
  );
}

export default function NotificationButton() {
  const _currentUserContext = useCurrentUserContext();
  const _modalContext = useModalContext();
  const _mainContext = useMainContext();
  const mediaQuery = useMediaQuery();

  const _logs = _currentUserContext.user?.logs?.filter((e) =>
    _mainContext.efforts?.some((a) => a.id === e.effortId)
  );

  const onClick = () => {
    _modalContext.open(
      <DialogContent title={"   "}>
        <UserLogsSection type="Notifications" />
      </DialogContent>
    );

    // _currentUserContext.markLogsAsRead(
    //   _currentUserContext.user!.logs?.map(e => e.id)
    // );
  };

  return (
    <>
      {!isEmpty(_logs) &&
        (mediaQuery <= MediaQuery2Num.sm ? (
          <Button
            onClick={onClick}
            variant={"light"}
            className=" border-0 relative px-1"
          >
            <LuBell />
            <Badge className=" absolute right-5 top-0 ">{_logs!.length}</Badge>
          </Button>
        ) : (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                // onClick={onClick}
                variant={"light"}
                className=" border-0 relative px-1"
              >
                <LuBell />
                <Badge className=" absolute right-5 top-0 ">
                  {_logs!.length}
                </Badge>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className=" w-96 max-h-[400px] overflow-y-auto "
              align="end"
            >
              <UserLogsSection type="Notifications" />
            </PopoverContent>
          </Popover>
        ))}
    </>
  );
}
