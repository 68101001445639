import React from "react";
// import { Clipboard } from '@capacitor/clipboard';

import Comment from "models/Comment";
import useCommentUtils, {
  useCommentAuthUtils,
} from "hooks/utils/UseCommentUtils";
import Dict from "models/Dict";
import useUserUtils from "hooks/utils/UseUserUtils";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";
import useClipboard from "components/common/UseClipboard";

import { useModalContext } from "providers/ModalProvider";
import { useEachEffortContext } from "providers/EachEffortProvider";
import {
  useCommentsContext,
  CommentsProvider,
} from "providers/CommentsProvider";

import Skeleton from "components/common/Skeleton";
import NewButton from "components/common/AdminSection/NewButton";
import CommentCreateUpdateModal from "./CommentCreateUpdateModal";
import { TextEditorValue } from "components/common/Forms/TextEditor";
import LocalDateTime from "components/common/LocalDateTime";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import OnLoadMoreIndicator from "components/common/OnLoadMoreIndicator";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import ItemsListToolsSection from "components/common/ItemsListSection/ItemsListToolsSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";

import { FaClone, FaReply } from "react-icons/fa6";
import { Button } from "components/ui/button";
import { BsReply } from "react-icons/bs";
import { LuCopy } from "react-icons/lu";

function EachCommentItemBody({
  comment,
  index,
}: {
  comment: Comment;
  index: number;
}) {
  const {
    itemClassName,
    selecteds,
    onItemSelect,
    setSelecteds,
    onItemClick,
    onItemDelete,
    selectedActionsSection,
    ellipsisResponsiveBreakPoint,
    btnTextResponsiveBreakPoint,
  } = useItemsListContext();
  const userUtils = useUserUtils();

  return (
    <Button
      asChild
      variant={selecteds.includes(comment) ? "primary" : "light"}
      className={cn(
        "group relative mb-1 p-2 block break-words min-h-[50px]",
        itemClassName?.(comment, index),
        selecteds.includes(comment) ? " " : "border bg-card"
      )}
    >
      <div
        onClick={() => {
          if (selecteds.includes(comment)) {
            setSelecteds(selecteds.filter((e) => e !== comment));
            onItemSelect?.(comment)?.(comment);
          } else if (selecteds.length > 0) {
            setSelecteds([...selecteds, comment]);
            onItemSelect?.(comment)?.(comment);
          } else {
            onItemClick?.(comment)?.(comment);
          }
        }}
        onContextMenu={
          !(
            onItemSelect(comment) ||
            selectedActionsSection ||
            onItemDelete([comment, ...selecteds])
          )
            ? undefined
            : (e) => {
                e.preventDefault();

                if (selecteds.includes(comment)) {
                  setSelecteds(selecteds.filter((e) => e !== comment));
                  onItemSelect?.(comment)?.(comment);
                } else {
                  setSelecteds([...selecteds, comment]);
                  onItemSelect?.(comment)?.(comment);
                }
              }
        }
      >
        <div className="flex items-center gap-1 w-full">
          {comment.parentId && <BsReply className="!font-base" />}

          {comment.user && (
            <>
              <span className="text-xs ">
                <ProfileAvatar size={30} user={comment.user} needName />
              </span>
            </>
          )}

          <div className="flex-1"></div>
          <span className="mb-0 text-justify text-xs">
            <LocalDateTime date={comment.dateAdd} />
          </span>

          <ItemsListToolsSection
            eachItem={comment}
            ellipsisResponsiveBreakPoint={"always"}
            btnTextResponsiveBreakPoint={"never"}
          />
        </div>

        <div
          id={comment.id + ""}
          className="flex justify-start pt-3 whitespace-normal "
        >
          <TextEditorValue value={comment.content} />
        </div>
      </div>
    </Button>
  );
}

function EachCommentItem({
  comment,
  index,
}: {
  comment: Comment;
  index: number;
}) {
  const { data } = useItemsListContext();

  return (
    <div>
      <EachCommentItemBody index={index} comment={comment} />

      <div className="ms-2 md:ms-3">
        {data
          ?.filter((e) => e.parentId === comment.id)
          .map((e, i) => (
            <EachCommentItem key={"eachComment" + e.id} index={i} comment={e} />
          ))}
      </div>
    </div>
  );
}

function ItemsListSectionBody() {
  const { data, actionsSection, bottomActionSection, title, onLoadMore } =
    useItemsListContext();

  return (
    <div>
      <ItemListSearchbarSection
        tableModeBreakPoint={"never"}
        actionsSection={actionsSection}
        bottomActionSection={bottomActionSection}
      />

      <div className="pt-2">
        {data
          ?.filter((e) => !e.parentId)
          .map((e, i) => (
            <EachCommentItem key={"eachComment" + e.id} index={i} comment={e} />
          ))}

        {onLoadMore && (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-24" />
          </OnLoadMoreIndicator>
        )}

        {!onLoadMore && data?.length === 0 && (
          <EmptyListIndicator text={"No " + title + " Found!"} />
        )}
      </div>
    </div>
  );
}

function CommentsPageBody() {
  const _modalContext = useModalContext();
  const _eachEffortContext = useEachEffortContext();
  const _commentsContext = useCommentsContext();
  const commentAuthUtils = useCommentAuthUtils(_eachEffortContext.effort!.id);
  const clipboard = useClipboard();

  const onRemove = async (datas: Comment[]) => {
    _commentsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _commentsContext.update(data)
        : await _commentsContext.create(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <CommentCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    // _commentsContext.get({
    //   ...formData,
    //   pageNumber: -1,
    // } as CommentFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    // _modalContext.open(
    //      <CommentFilterModal
    //         filter={ _commentsContext.filter }
    //         onSubmit={ onFilterSubmit }
    //      />
    // );
  };

  return (
    <div className="">
      <ItemsListProvider<Comment>
        title="Comments"
        data={_commentsContext.comments ?? []}
        fields={[
          {
            value: "",
            label: "#",
            className: "text-start whitespace-nowrap",
            width: "70px",
            isSortable: false,
            getUiContent: (e, i) => i + 1,
          },

          {
            value: "content",
            label: "content",
            width: "auto",
            isSortable: false,
            getValue: (e) => {
              var parser = new DOMParser();
              const doc = parser.parseFromString(
                e.content.content ?? "",
                "text/html"
              );
              return doc.documentElement.innerText;
            },
          },

          {
            value: "dateAdd",
            label: "Created on",
            width: "auto",
            isSortable: false,
            getValue: (e) => dateGetTime(e.dateAdd),
            getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          },
        ]}
        onLoadMore={_eachEffortContext.isLoading ? async () => {} : undefined}
        // onSortField={(v) => {
        //   _commentsContext.get({
        //     ..._commentsContext.filter,
        //     sortBy: v as CommentSortType,
        //     pageNumber: -1,
        //   });
        // }}
        // onSortDir={(v) => {
        //   _commentsContext.get({
        //     ..._commentsContext.filter,
        //     ascOrder: v === 1,
        //     pageNumber: -1,
        //   });
        // }}

        initialSelectedSortFieldValue="dateAdd"
        onItemEdit={(item) =>
          commentAuthUtils.canEdit(item) ? openCreateUpdateModal : undefined
        }
        onItemDelete={(items) =>
          commentAuthUtils.canDelete(items)
            ? _commentsContext.removeMany
            : undefined
        }
        getItemActions={(targetItem) => [
          ...(commentAuthUtils.canCreate()
            ? [
                {
                  icon: <FaReply />,
                  text: "Reply",
                  onClick: () =>
                    openCreateUpdateModal({ parentId: targetItem.id }),
                },
              ]
            : []),
          {
            text: "Share",
            icon: <LuCopy />,
            onClick: () =>
              clipboard.write({
                content: window.location.href + "#" + targetItem.id,
              }),
          },
        ]}
        actionsSection={
          <>
            {commentAuthUtils.canCreate() && (
              <NewButton
                needText={false}
                onClick={() => openCreateUpdateModal()}
              />
            )}
          </>
        }
      >
        <ItemsListSectionBody />
      </ItemsListProvider>
    </div>
  );
}

export default function CommentsPage({ effortId }: { effortId: number }) {
  return (
    <CommentsProvider effortId={effortId}>
      <CommentsPageBody />
    </CommentsProvider>
  );
}
