import React from "react";

import { isEmpty } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { useActivitysContext } from "providers/ActivitysProvider";
import { Form, SubmitButton } from "components/common/Forms/Form";
import { DialogContent } from "components/ui/dialog";
import LoadingButton from "components/common/LoadingButton";
import { useToast } from "components/ui/use-toast";

export default function ExportModal() {
  const _activitysContext = useActivitysContext();
  const _modalContext = useModalContext();
  const { toast } = useToast();

  const onExport = async (type: "xlsx" | "pdf") => {
    let _errors = await _activitysContext.exportReport(type, {
      ..._activitysContext.filter,
      effortIds: [_activitysContext.effortId],
      typeIds: _activitysContext.filter.typeId
        ? [_activitysContext.filter.typeId]
        : undefined,
      userIds: _activitysContext.filter.userId
        ? [_activitysContext.filter.userId]
        : undefined,
      numberInPage: 999,
    });

    if (isEmpty(_errors)) {
      toast({ description: "Exported" });
      setTimeout(() => _modalContext.dismiss(), 2000);
    }

    return _errors;
  };

  return (
    <DialogContent
      title={"Export"}
      size={"sm"}
      fullScreen={false}
      onBgClickClosable
    >
      <span className="mb-3 block text-foreground">
        Now you can select in which format would you like to export.
      </span>

      <LoadingButton
        onClick={async () => await onExport("xlsx")}
        className=" w-full p-3 mb-2"
      >
        <img
          src={"/images/excel-logo.png"}
          width="30px"
          alt="excel-icon"
          className="inline"
        />
        <span>Excel</span>
      </LoadingButton>

      {/* <Form allowInitSubmit
        onSubmit={() => onExport("pdf")}
      >
        <SubmitButton 
            variant={"light"}
          className=" border w-full p-3 bg-transparent"
        >
            <img
                src={"/images/pdf-logo.png"}
                width="30px"
                alt="pdf-icon"
                className="me-2"
            />
            <span>PDF</span>
        </SubmitButton>
      </Form> */}
    </DialogContent>
  );
}
