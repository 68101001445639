import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import useOutsideClick from "components/common/useOutsideClick";
import { EachPanelItem } from "components/layouts/LeftPannel";
import { useEffortUtils, useEffortViewUtils } from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import { useMainContext } from "providers/MainProvider";
import React from "react";
import {
  LuClipboard,
  LuSlidersHorizontal,
  LuMessagesSquare,
  LuTimer,
  LuHistory,
  LuUserPlus,
} from "react-icons/lu";
import { cn } from "services/UtilServices";
import ActivitysSection from "../EachEffort/ActivitysSection/ActivitysSection";
import CommentsSection from "../EachEffort/CommentsSection/CommentsSection";
import MemberAvatar from "../EachEffort/MembersSection/MemberAvatar";
import SettingSection from "../EachEffort/SettingSection/SettingSection";
import EffortsSection from "../EffortsSection/EffortsSection";
import UserLogsSection from "../UserLogsSection/UserLogsSection";
import { Button } from "components/ui/button";
import { useUsersContext } from "providers/UsersProvider";
import ProfileAvatar from "../profile/account/ProfileAvatar";
import useMemberUtils from "hooks/utils/UseMemberUtils";
import { Separator } from "components/ui/separator";
import UserProfileHoverCard from "../admin/UsersSection/UserProfileHoverCard";

export function useSidePanelSections(
  effort?: Effort | null,
  includeSubTasks = false,
  includeSetting = true
) {
  const _currentUserContext = useCurrentUserContext();
  // effort!.level < 2

  return [
    ...(effort
      ? [
          ...(includeSubTasks
            ? [
                {
                  url: "",
                  exact: true,
                  icon: <LuClipboard />,
                  text: effort!.level === 0 ? "Tasks" : "Sub Tasks",
                  content: <EffortsSection parentId={effort.id} />,
                },
              ]
            : []),

          ...(includeSetting
            ? [
                {
                  url: "setting",
                  text: "Setting",
                  icon: <LuSlidersHorizontal />,
                  content: <SettingSection effort={effort} />,
                },
              ]
            : []),
          {
            url: "comments",
            text: "Comments",
            icon: <LuMessagesSquare />,
            content: <CommentsSection effortId={effort.id} />,
          },
        ]
      : []),

    {
      url: "activitys",
      text: "Activities",
      icon: <LuTimer />,
      content: <ActivitysSection />,
    },
    {
      url: "logs",
      text: "Logs",
      icon: <LuHistory />,
      count: effort
        ? _currentUserContext.user?.logs?.filter(
            (e) => e.effortId === effort.id
          ).length
        : undefined,
      content: <UserLogsSection onExitClear effortId={effort?.id} />,
    },
  ];
}

export default function SidePanel({ effort }: { effort?: Effort }) {
  const _mainContext = useMainContext();
  const mediaQuery = useMediaQuery();
  const effortViewUtils = useEffortViewUtils(effort?.parentId);
  const effortsContext = useEffortsContext();
  const _usersContext = useUsersContext();
  const _memberUtils = useMemberUtils(effort);
  const effortUtils = useEffortUtils();

  const _project = effortUtils.getParentProject(effort?.id);
  const _users = effort
    ? _project?.members?.map((e) => e.user)
    : _usersContext.users;

  React.useEffect(() => {
    console.log("SidePanel", effort);
    if (!effort) {
      _usersContext.getAll();
    }
  }, [effort?.id]);

  React.useEffect(() => {
    if (mediaQuery <= MediaQuery2Num.xl && _mainContext.effortSideMenu) {
      _mainContext.setEffortSideMenu(null);
    }
  }, [mediaQuery]);

  const SECTOINS = useSidePanelSections(
    effort,
    false,
    (effort?.level ?? 10) < 2
  );
  const content = SECTOINS.find(
    (e) => e.url === _mainContext.effortSideMenu
  )?.content;

  return (
    <>
      {mediaQuery <= MediaQuery2Num.xl && _mainContext.effortSideMenu && (
        <div
          onClick={() => _mainContext.setEffortSideMenu(null)}
          className="fixed inset-0 xl:hidden z-30 bg-background/50 backdrop-blur-sm"
        ></div>
      )}

      <div
        className={cn(
          "md:flex hidden transition-all duration-100 h-full z-40 relative bg-background "
        )}
      >
        <div className="relative">
          <div
            className={cn(
              "overflow-y-auto bg-background border-s scrollbar-hide h-full  border-e transition-all right-0",
              " duration-100  py-2 max-xl:shadow-[rgba(0,0,15,0.1)_-2px_0px_4px_0px] max-xl:absolute ",
              content ? "px-2 w-80" : "opacity-0"
            )}
          >
            {content}

            <div className="py-5"></div>
          </div>
        </div>

        <div className="flex flex-col h-full p-1 w-14 py-2 items-center">
          {SECTOINS.map((eachItem) => (
            <EachPanelItem
              key={"eachItem" + eachItem.url}
              type="BUTTON"
              isMini={true}
              icon={eachItem.icon}
              text={eachItem.text}
              notifCount={eachItem.count}
              isActive={eachItem.url === _mainContext.effortSideMenu}
              activeClassName="rounded-s rounded-e-none "
              className="border-e-4 border-s-0 p-1 pe-0"
              onClick={() =>
                _mainContext.setEffortSideMenu(
                  eachItem.url === _mainContext.effortSideMenu
                    ? null
                    : eachItem.url
                )
              }
            />
          ))}

          <Separator className="my-2" />

          <div className="flex-1 flex flex-col min-h-0 overflow-y-auto px-2 gap-1">
            {effort && _memberUtils.canCreate() && (
              <Button
                variant={"faded"}
                title="Add New Member"
                className="rounded-full aspect-square border ms-1"
                onClick={(ev) => {
                  _memberUtils.openCreateUpdateModal();
                }}
              >
                <LuUserPlus />
              </Button>
            )}

            {_users?.map((eachUser, i) => (
              <UserProfileHoverCard
                key={"eachMember" + i}
                side="left"
                user={eachUser}
                member={_project?.members?.find(
                  (e) => e.userId === eachUser.id
                )}
              >
                <Button
                  onClick={() =>
                    effortViewUtils.onFilterSubmit({
                      ...effortsContext.filter,
                      memberIds: effortsContext.filter.memberIds?.some(
                        (a) => a === eachUser.id
                      )
                        ? effortsContext.filter.memberIds.filter(
                            (a) => a !== eachUser.id
                          )
                        : [
                            ...(effortsContext.filter.memberIds ?? []),
                            eachUser.id,
                          ],
                    })
                  }
                  className={cn("rounded-full aspect-square p-1 ", {
                    "bg-primary": effortsContext.filter.memberIds?.some(
                      (a) => a === eachUser.id
                    ),
                  })}
                >
                  <ProfileAvatar needHover={false} user={eachUser} />
                </Button>
              </UserProfileHoverCard>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
