import React from "react";
import { format, isValid, parse } from "date-fns";

import { useFormContext } from "./Form";
import FormFieldBase, {
  FormFieldBaseChildrenProps,
  FormFieldBaseInputProps,
} from "./FormFieldBase";
import {
  cn,
  dateAdd,
  dateGetMonth,
  dateGetOnlyDate,
  dateGetToday,
  dateGetWeek,
  listFirst,
  listLast,
  objRemoveKeys,
} from "services/UtilServices";

import { LuCalendar, LuCheck } from "react-icons/lu";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { DateRange, SelectSingleEventHandler } from "react-day-picker";
import { Calendar } from "components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import useValidation from "./UseValidation";

type Props = {
  fromDate?: Date;
  toDate?: Date;
  needPopover?: boolean;
} & FormFieldBaseInputProps;

function DatePickerInputBody({
  props,
  baseProps,
}: {
  props: Props;
  baseProps: FormFieldBaseChildrenProps;
}) {
  const _formContext = useFormContext();
  const {
    value,
    name,
    label,
    validations,
    btnClassName = " ",
    needPopover,
    ...rest
  } = props;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    _formContext.setData({ [name]: value });
  };

  return (
    <>
      <Input
        name={name}
        id={name}
        // dir="rtl"
        className={cn("form-control flex-1  ", {
          "is-invalid": baseProps.isFocused && baseProps.error,
        })}
        placeholder="yyyy-mm-dd"
        onChange={onInputChange}
        value={_formContext.data[name] ?? ""}
        {...objRemoveKeys(rest, ["toDate", "fromDate"])}
      />

      <LuCalendar className="absolute right-3 opacity-30 hover:opacity-100 text-xl" />
    </>
  );
}

export default function DatePickerInput(props: Props) {
  const _formContext = useFormContext();
  const validation = useValidation();

  const { value, name, needPopover = true } = props;

  React.useEffect(() => {
    if (value !== undefined && !(name in _formContext.data)) {
      _formContext.setData({ [name]: value });
    }
  }, [value]);

  const onDateSelect: SelectSingleEventHandler = (date) => {
    if (date) {
      const _date = date ? format(date, "y-MM-dd") : "";
      _formContext.setData({ [name]: _date });
    }
  };
  const convert2Date = (date?: any) => {
    let _result =
      typeof date === "string" ? parse(date, "y-MM-dd", dateGetToday()) : date;
    return isValid(_result) ? _result : undefined;
  };

  const _value = _formContext.data[name];

  return (
    <FormFieldBase
      {...props}
      validations={[
        ...(props.validations ?? []),
        validation.isDateValid(),
        ...(props.toDate ? [validation.isToDateValid(props.toDate)] : []),
        ...(props.fromDate ? [validation.isFromDateValid(props.fromDate)] : []),
      ]}
    >
      {(baseProps) =>
        needPopover ? (
          <Popover>
            <PopoverTrigger className="relative flex items-center w-full">
              <DatePickerInputBody props={props} baseProps={baseProps} />
            </PopoverTrigger>
            <PopoverContent className="p-0 w-auto" align="start">
              <Calendar
                mode="single"
                initialFocus={true}
                defaultMonth={convert2Date(_value)}
                selected={convert2Date(_value)}
                onSelect={onDateSelect}
                fromDate={convert2Date(props.fromDate)}
                toDate={convert2Date(props.toDate)}
              />
            </PopoverContent>
          </Popover>
        ) : (
          <div className="relative flex items-center w-full">
            <DatePickerInputBody props={props} baseProps={baseProps} />
          </div>
        )
      }
    </FormFieldBase>
  );
}

function DateRangeModal({
  onSelect,
  from,
  to,
}: {
  onSelect: (d?: { from?: Date; to?: Date }) => void;
  value?: DateRange;
  from?: Date | string | number | null;
  to?: Date | string | number | null;
}) {
  const convert2Date = (date?: any) => {
    let _result = parse(date, "y-MM-dd", dateGetToday());
    return isValid(_result) ? _result : undefined;
  };

  const [range, _setRange] = React.useState<DateRange | undefined>({
    from: convert2Date(from),
    to: convert2Date(to),
  });

  const setRange = (r: DateRange | undefined) => {
    _setRange(r);
    onSelect(r);
  };

  const buttons = [
    // {
    //   text: "tomorrow",
    //   data: {
    //     from: dateAdd({
    //       date: new Date(dateGetOnlyDate(dateGetToday())!),
    //       days: 1,
    //     })!,
    //     to: dateAdd({ date: new Date(dateGetOnlyDate(dateGetToday())!), days: 1 })!,
    //   },
    // },
    {
      text: "today",
      data: {
        from: dateGetToday(),
        to: dateGetToday(),
      },
    },
    {
      text: "yesterday",
      data: {
        from: dateAdd({
          date: dateGetToday(),
          days: -1,
        })!,
        to: dateAdd({
          date: dateGetToday(),
          days: -1,
        })!,
      },
    },
    // {
    //   text: "next week",
    //   data: {
    //     from: listFirst(dateGetWeek(1)),
    //     to: listLast(dateGetWeek(1)),
    //   },
    // },
    {
      text: "this week",
      data: { from: listFirst(dateGetWeek()), to: listLast(dateGetWeek()) },
    },
    {
      text: "last week",
      data: {
        from: listFirst(dateGetWeek(-1)),
        to: listLast(dateGetWeek(-1)),
      },
    },
    // {
    //   text: "next month",
    //   data: { from: listFirst(dateGetMonth(1)), to: listLast(dateGetMonth(1)) },
    // },
    {
      text: "this month",
      data: { from: listFirst(dateGetMonth()), to: listLast(dateGetMonth()) },
    },
    {
      text: "last month",
      data: {
        from: listFirst(dateGetMonth(-1)),
        to: listLast(dateGetMonth(-1)),
      },
    },
    {
      text: "future",
      data: {
        from: dateAdd({
          date: dateGetToday(),
          days: 1,
        })!,
      },
    },
    {
      text: "before",
      data: {
        from: undefined,
        to: dateAdd({
          date: dateGetToday(),
          days: -1,
        })!,
      },
    },
  ];

  return (
    <div className="row justify-center">
      <div className="col-12 md:col-4">
        {buttons.map((eachBtn, i) => (
          <Button
            variant="light"
            key={"eachButton" + i}
            onClick={() => setRange(eachBtn.data)}
            className="flex items-center w-full text-start py-2 capitalize"
          >
            {eachBtn.text}
            <div className="flex-1"></div>
            {dateGetOnlyDate(eachBtn.data.from) ===
              dateGetOnlyDate(range?.from) &&
              dateGetOnlyDate(eachBtn.data.to) ===
                dateGetOnlyDate(range?.to) && <LuCheck />}
          </Button>
        ))}
      </div>

      <div className="col-12 md:col-8 flex flex-col items-center">
        <Calendar
          mode="range"
          selected={range}
          // defaultMonth={range?.from ?? dateGetToday()}
          onSelect={setRange}
          // modifiersStyles={{
          //   selected: {
          //     backgroundColor: _themeContext.themeColor,
          //   },
          //   range_middle: {
          //     backgroundColor: _themeContext.themeColor + "99",
          //   },
          // }}
          // modifiersClassNames={{
          //   selected: _themeContext.textColor,
          // }}
        />
      </div>
    </div>
  );
}

export function DatePickerInputRange(props: Props) {
  const _formContext = useFormContext();

  const {
    value,
    name,
    label,
    validations,
    btnClassName = " ",
    ...rest
  } = props;

  const fromName = name + "From";
  const toName = name + "To";

  const onRangeSelect = (date?: { from?: Date; to?: Date }) => {
    _formContext.setData({
      [fromName]: date?.from ? format(date?.from, "y-MM-dd") : date?.from,
      [toName]: date?.to ? format(date?.to, "y-MM-dd") : date?.to,
    });
  };

  return (
    <FormFieldBase
      {...props}
      // validations={[...(props.validations ?? []), validation.isDateRangeValid()]}
    >
      {(baseProps) => (
        <div className="flex">
          <Popover>
            <PopoverTrigger className="flex items-center w-full relative border rounded p-2">
              <div className="flex-1">
                <DatePickerInput
                  needMarginBottom={false}
                  needPopover={false}
                  placeholder="yyyy-mm-dd"
                  // toDate={_formContext.data[toName]}
                  {...rest}
                  value={_formContext.data[fromName]}
                  name={fromName}
                  id={fromName}
                  label="From"
                />
              </div>

              <div className="ps-2"></div>

              <div className="flex-1">
                <DatePickerInput
                  needMarginBottom={false}
                  needPopover={false}
                  placeholder="yyyy-mm-dd"
                  // fromDate={_formContext.data[fromName]}
                  {...rest}
                  value={_formContext.data[toName]}
                  name={toName}
                  id={toName}
                  label="To"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto" align="start">
              <DateRangeModal
                onSelect={onRangeSelect}
                from={_formContext.data[fromName]}
                to={_formContext.data[toName]}
              />
            </PopoverContent>
          </Popover>

          {/* <Button
            variant="faded"
            onClick={openDatePicker}
            className={cn(
              "absolute right-0 ",
              btnClassName
            )}
          >
            <LuCalendarRange />
          </Button> */}
        </div>
      )}
    </FormFieldBase>
  );
}
