import React from "react";

import Dict from "models/Dict";
import { ActivityFilter } from "models/Activity";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { UserRoles } from "models/User";

import { objList2Options } from "services/UtilServices";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import {
  Switch,
  Form,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserUtils from "hooks/utils/UseUserUtils";
import useActivityUtils from "hooks/utils/UseActivityUtils";
import { useMainContext } from "providers/MainProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";

export default function ActivityFilterModal({
  filter,
  onSubmit,
  effortId,
}: {
  filter: ActivityFilter;
  onSubmit: (data: Dict) => void;
  effortId?: number;
}) {
  const _mainContext = useMainContext();
  const _currentuserContext = useCurrentUserContext();
  const _userUtils = useUserUtils();
  const activityUtils = useActivityUtils();
  const _typesContext = useTypesContext();
  const _usersContext = useUsersContext();

  let _types = _typesContext.types;
  let _users = _usersContext.users;

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        {!effortId && (
          <Select
            name="effortIds"
            label="Task"
            multiple
            options={objList2Options(_mainContext.efforts, "title")}
          />
        )}

        <Select
          name="userId"
          label="User"
          options={_users?.map((e) => ({
            value: e.id,
            label: _userUtils.getFullName(e),
          }))}
        />

        <Select name="typeId" label="Type" options={objList2Options(_types)} />

        {_currentuserContext.user!.role !== UserRoles.USER && (
          <>
            <Switch name="isApproved" label="Approved Activities" />
          </>
        )}

        {/* <CheckBox name="isApproved" label="Approved Activities" /> */}

        <DatePickerInputRange name="dateAdd" label="Activity added" />

        <DatePickerInputRange name="dateDone" label="Activity Done" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={activityUtils.isFilterActive({ filter })}
            onClick={() =>
              onSubmit({
                pageNumber: -1,
                ascOrder: filter.ascOrder,
                sortBy: filter.sortBy,
                effortIds: filter.effortIds,
              })
            }
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
