import React from "react";

import { UsersProvider } from "providers/UsersProvider";
import { ModalProvider } from "providers/ModalProvider";

import MainLayout from "layouts/MainLayout";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import EffortsSection from "components/pages/EffortsSection/EffortsSection";
import { ViewTypeSelectProvider } from "components/pages/EffortsSection/ViewTypeSelectButton";
import { cn } from "services/UtilServices";
import { EffortsProvider } from "providers/EffortsProvider";
import SidePanel from "components/pages/EachEffort/SidePanel";
import { TypesProvider } from "providers/TypesProvider";
import AppLogo from "components/pages/AppLogo";

function HomePage() {
  return (
    <EffortsProvider>
      <UsersProvider>
        <TypesProvider>
          <MainLayout
            title={
              <AppLogo
                className={cn(
                  "object-contain h-7 hover:brightness-100 hover:invert-0 "
                )}
              />
            }
            sidePanel={<SidePanel />}
          >
            <ModalProvider>
              <ViewTypeSelectProvider>
                <main className="h-full p-4 md:p-6">
                  <EffortsSection />

                  <MobileFooterPadding />
                </main>
              </ViewTypeSelectProvider>
            </ModalProvider>
          </MainLayout>
        </TypesProvider>
      </UsersProvider>
    </EffortsProvider>
  );
}

export default HomePage;
