import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Type, { TypeFilter, TypeSortTypes } from "models/Type";

import { listUnique, listFlatten } from "services/UtilServices";

import { MainContext } from "./MainProvider";
import { EffortsContext } from "./EffortsProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";
import useTypeApi from "hooks/api/UseTypeApi";

interface TypesContextProps {
  types: Type[] | undefined;
  create: (type: Dict) => Promise<Dict>;
  update: (type: Dict) => Promise<Dict>;
  removeMany: (types: Dict[]) => Promise<boolean>;
  uploadImages: (typeImages: FileList) => Promise<boolean>;

  get: (filter: TypeFilter) => Promise<void>;
  getAll: (parentId?: number | null) => Promise<Dict | undefined>;
  filter: TypeFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const TypesContext = React.createContext({} as TypesContextProps);
TypesContext.displayName = "typesContext";

function TypesProvider({ children }: { children: React.ReactNode }) {
  const itemsRef = React.useRef<Type[] | undefined>(undefined);
  const globalFilterRef = React.useRef<TypeFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: TypeSortTypes.ADDWHEN,
  });

  const [_types, _setTypes] = React.useState<Type[]>();
  const [_filter, _setFilter] = React.useState<TypeFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const typeApi = useTypeApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = React.useContext(MainContext);
  const _currentUserContext = React.useContext(CurrentUserContext);
  const _effortsContext = React.useContext(EffortsContext);

  React.useEffect(() => {
    setTypes(undefined);
    setFilter({
      pageNumber: -1,
      ascOrder: false,
      sortBy: TypeSortTypes.ADDWHEN,
    });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setTypes(undefined);
      setFilter({
        pageNumber: -1,
        ascOrder: false,
        sortBy: TypeSortTypes.ADDWHEN,
      });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  const setTypes = (o?: Type[]) => {
    itemsRef.current = o;
    _setTypes(o);
  };

  const setFilter = (o: TypeFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async (parentId?: number | null) => {
    let _effort = _effortUtils.getEffortById(
      _effortsContext?.currentEffort?.id
    );

    if (!parentId && !_effort?.parentId && _currentUserContext.isSudo()) {
      return await get({
        ..._filter,
        pageNumber: 0,
        numberInPage: 999,
      });
    }

    let _result = _effortsContext.currentEffort
      ? _effortUtils.getParentProject(_effortsContext.currentEffort.id)?.types
      : listUnique(
          listFlatten(
            _mainContext
              .efforts!.filter((e) => _effortUtils.isProject(e))
              .map((e) => e.types ?? [])
          )
        );

    _result?.sort((a, b) => a.id - b.id);

    setTypes(_result);
    return _result;
  };

  const get = async (filter: TypeFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setTypes(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setTypes(undefined);
      setTotalNumber(undefined);

      return;
    }

    try {
      let { items, totalNumber } = await typeApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setTypes([...(itemsRef.current ?? []), ...(items as Type[])]);
    } catch (e) {
      toast({
        description: (e as AxiosError).message,
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setTypes([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await typeApi.create(formData);

      setTypes([formData as Type, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await typeApi.update(formData);

      setTypes(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Type)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          types: eachEffort.types?.map((e) =>
            e.id !== formData.id ? e : (formData as Type)
          ),
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (typesToDelete: Type[]) => {
    let _errors = {};

    try {
      await typeApi.remove(typesToDelete.map((e) => e.id));

      setTypes(itemsRef.current?.filter((e) => !typesToDelete.includes(e)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <TypesContext.Provider
      value={
        {
          types: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as TypesContextProps
      }
    >
      {children}
    </TypesContext.Provider>
  );
}

export function useTypesContext() {
  const _context = React.useContext(TypesContext);

  if (!_context) {
    throw new Error("cannot use TypesContext outside of its provider.");
  }

  return _context;
}

export { TypesContext, TypesProvider };
export type { TypesContextProps };
