import * as React from "react";

import { Route, Routes, useNavigate } from "react-router";

import { ApiVariables } from "hooks/api/UseApiServices";
import CurrentUserLocalServices from "services/localServices/CurrentUserLocalServices";

import { useMainContext } from "providers/MainProvider";
import { useThemeContext, ThemeUtils } from "providers/ThemeProvider";
import { ModalProvider } from "providers/ModalProvider";
import { SideMenuProvider } from "../providers/SideMenuProvider";

import MainFooter from "components/layouts/MainFooter";
import MainHeader from "../components/layouts/MainHeader/MainHeader";
import { BackButtonHandler } from "components/common/BackButtonHandler";
import {
  DesktopSideMenu,
  MobileSideMenu,
} from "../components/layouts/SideMenu";
import { cn } from "services/UtilServices";
import LocalStorageServices from "services/localServices/LocalStorageServices";

interface MainLayoutContextProps {
  leftSideMenu: string | null;
  setLeftSideMenu: (l: string | null) => void;

  rightSideMenu: string | null;
  setEffortSideMenu: (l: string | null) => void;

  title: React.ReactNode;
  setTitle: (d: React.ReactNode) => void;

  footer: React.ReactNode;
  setFooter: (d: React.ReactNode) => void;

  rightPannel: React.ReactNode;
  setRightPannel: (d: React.ReactNode) => void;
}

const MainLayoutContext = React.createContext({} as MainLayoutContextProps);
MainLayoutContext.displayName = "MainLayoutContext";

export function MainLayoutProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [leftSideMenu, _setLeftSideMenu] = React.useState<string | null>(null);
  const [rightSideMenu, _setRightSideMenu] = React.useState<string | null>(
    null
  );
  const [title, setTitle] = React.useState<React.ReactNode>();
  const [footer, setFooter] = React.useState<React.ReactNode>();
  const [rightPannel, setRightPannel] = React.useState<React.ReactNode>();

  React.useEffect(() => {
    LocalStorageServices.get("LEFT_SIDE_MENU").then((r) =>
      _setLeftSideMenu(r ?? false)
    );

    LocalStorageServices.get("RIGHT_SIDE_MENU").then((r) =>
      _setRightSideMenu(r)
    );
  }, []);

  const setLeftSideMenu = (value: string | null) => {
    _setLeftSideMenu(value);
    LocalStorageServices.set("LEFT_SIDE_MENU", value);
  };

  const setRightSideMenu = (value: string | null) => {
    _setRightSideMenu(value);
    LocalStorageServices.set("RIGHT_SIDE_MENU", value);
  };

  return (
    <MainLayoutContext.Provider
      value={
        {
          leftSideMenu,
          setLeftSideMenu,

          rightSideMenu,
          setEffortSideMenu: setRightSideMenu,

          title,
          setTitle,

          footer,
          setFooter,

          rightPannel,
          setRightPannel,
        } as MainLayoutContextProps
      }
    >
      {children}
    </MainLayoutContext.Provider>
  );
}

export function useMainLayoutContext() {
  const _context = React.useContext(MainLayoutContext);

  if (!_context) {
    throw new Error("cannot use MainLayoutContext outside of its provider.");
  }

  return _context;
}

interface Props {
  children: React.ReactNode;
  header?: React.ReactNode | null;
  title?: React.ReactNode;
  sideMenu?: React.ReactNode;
  back?: boolean;
  sidePanel?: React.ReactNode;
}

function SideMenuSection() {
  const _mainContext = useMainContext();
  const _themeContext = useThemeContext();

  const _isMini = _mainContext.sideMenuIsMini;

  return (
    <div className={cn("md:block hidden transition-all duration-100 h-full")}>
      <DesktopSideMenu isMini={_isMini} />
    </div>
  );
}

function MainLayout({
  children,
  header,
  title,
  back = false,
  sidePanel,
}: Props) {
  const _themeContext = useThemeContext();
  const _mainContext = useMainContext();
  const mainLayoutContext = useMainLayoutContext();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!CurrentUserLocalServices.isLoggedIn()) {
      navigate("/login/");
    }
  }, []);

  return (
    <BackButtonHandler>
      <ModalProvider>
        <div className="h-full w-full overflow-hidden bg-background">
          <SideMenuProvider>
            {header !== null && (
              <MainHeader title={title} back={back}>
                {header}
              </MainHeader>
            )}
          </SideMenuProvider>

          <div className="flex items-stretch h-[calc(100vh-50px)]">
            {/* <LeftPannel /> */}
            <SideMenuSection />

            <div
              style={{
                backgroundImage: _themeContext.bgImage
                  ? `linear-gradient(to top, ${
                      _themeContext.isDark ? "#000000aa" : "#ffffffaa"
                    }, ${_themeContext.isDark ? "#000000aa" : "#ffffffaa"}), 
                                    url('${ThemeUtils.addBaseUrl(
                                      _themeContext.bgImage
                                    )}')`
                  : undefined,
              }}
              className=" bg-cover bg-center bg-no-repeat bg-muted transition-all duration-100 overflow-y-auto flex-1 "
            >
              {children}
            </div>

            {/* <SideMenuSection /> */}
            {/* {mainLayoutContext.rightPannel} */}
            {sidePanel}
          </div>

          <MainFooter />
        </div>
      </ModalProvider>
    </BackButtonHandler>
  );
}

export default MainLayout;
