import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import State, { StateFilter, StateSortTypes } from "models/State";

import { listFlatten, listUnique } from "services/UtilServices";
import useStateApi from "hooks/api/UseStateApi";

import { MainContext } from "./MainProvider";
import { EffortsContext } from "./EffortsProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface StatesContextProps {
  states: State[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: StateFilter) => Promise<void>;
  getAll: (effortId?: number | null) => Promise<Dict | undefined>;
  filter: StateFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const StatesContext = React.createContext({} as StatesContextProps);
StatesContext.displayName = "StatesContext";

function StatesProvider({ children }: { children: React.ReactNode }) {
  const itemsRef = React.useRef<State[] | undefined>(undefined);
  const globalFilterRef = React.useRef<StateFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: StateSortTypes.ADDWHEN,
  });

  const [_items, _setStates] = React.useState<State[]>();
  const [_filter, _setFilter] = React.useState<StateFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const stateApi = useStateApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = React.useContext(MainContext);
  const _currentUserContext = React.useContext(CurrentUserContext);
  const _effortsContext = React.useContext(EffortsContext);

  // console.log(items, globalFilter,_items, _filter, hasNext)

  // React.useEffect(() => {
  //   setStates(undefined);
  //   setFilter({
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: StateSortTypes.ADDWHEN,
  //   });
  //   _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   return () => {
  //     setStates(undefined);
  //     setFilter({
  //       pageNumber: -1,
  //       ascOrder: false,
  //       sortBy: StateSortTypes.ADDWHEN,
  //     });
  //     _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   }
  // }, []);

  const setStates = (o?: State[]) => {
    itemsRef.current = o;
    _setStates(o);
  };

  const setFilter = (o: StateFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async (parentId?: number | null) => {
    let _effort = _effortUtils.getEffortById(
      _effortsContext?.currentEffort?.id
    );

    if (!parentId && !_effort?.parentId && _currentUserContext.isSudo()) {
      return await get({
        ..._filter,
        pageNumber: 0,
        numberInPage: 999,
      });
    }

    let _result = _effortsContext.currentEffort
      ? _effortUtils.getParentProject(_effortsContext.currentEffort.id)?.states
      : listUnique(
          listFlatten(
            _mainContext
              .efforts!.filter((e) => _effortUtils.isProject(e))
              .map((e) => e.states ?? [])
          )
        );

    _result?.sort((a, b) => a.id - b.id);

    setStates(_result);
    return _result;
  };

  const get = async (filter: StateFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setStates(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setStates(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await stateApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setStates([...(itemsRef.current ?? []), ...(items as State[])]);
    } catch (e) {
      toast({
        description: (e as AxiosError).message,
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setStates([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await stateApi.create(formData);

      setStates([formData as State, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await stateApi.update(formData);

      setStates(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as State)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          state:
            eachEffort.state?.id === formData.id
              ? (formData as State)
              : eachEffort.state,
          states: eachEffort.states?.map((e) =>
            e.id !== formData.id ? e : (formData as State)
          ),
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: State[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await stateApi.remove(ids);

      setStates(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <StatesContext.Provider
      value={
        {
          states: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as StatesContextProps
      }
    >
      {children}
    </StatesContext.Provider>
  );
}

export function useStatesContext() {
  const _context = React.useContext(StatesContext);

  if (!_context) {
    throw new Error("cannot use StatesContext outside of its provider.");
  }

  return _context;
}

export { StatesContext, StatesProvider };
export type { StatesContextProps };
