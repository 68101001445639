import React from "react";

import LocalStorageServices from "services/localServices/LocalStorageServices";

import {
  FieldType,
  useItemsListContext,
  SortDirType,
} from "./ItemsListProvider";
import { useModalContext } from "providers/ModalProvider";

import ItemsListToolsSection from "./ItemsListToolsSection";
import OnLoadMoreIndicator from "../OnLoadMoreIndicator";
import EmptyListIndicator from "../EmptyListIndicator";
import Skeleton from "../Skeleton";
import { ReactSortable, Sortable } from "react-sortablejs";

import { FaCaretDown, FaCaretUp, FaGear } from "react-icons/fa6";
import { cn, strSplitCammelCase } from "services/UtilServices";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { LuCheck } from "react-icons/lu";
import Sticky from "../Sticky";
import useMediaQuery, { MediaQuery2Num } from "../useMediaQuery";

function EachFieldSelectionModalItem({
  isSelected = false,
  label = "",
  onClick = () => {},
}) {
  return (
    <div>
      <Button
        asChild
        variant={"light"}
        className="w-full py-2 flex items-center"
      >
        <div onClick={onClick}>
          {label}
          <div className="flex-1"></div>
          {isSelected && <LuCheck className="ms-auto" />}
          {/* {isSelected ? (
            <FaCircleCheck className="ms-auto text-primary" />
          ) : (
            <FaCircle className="ms-auto text-secondary-foreground" />
          )} */}
        </div>
      </Button>
    </div>
  );
}

function FieldSelectionModal({
  sortableFields,
  setSortableFields,
}: {
  sortableFields: FieldType<any>[];
  setSortableFields: (d: FieldType<any>[]) => void;
}) {
  const [_sortableFields, _setSortableFields] = React.useState(sortableFields);

  const set = (d: FieldType<any>[]) => {
    _setSortableFields(d);
    setSortableFields(d);
  };

  const onDragEnd = (result: Sortable.SortableEvent) => {
    const dest = { id: result.to.id, index: result.newIndex! };
    const src = { id: result.from.id, index: result.oldIndex! };
    const draggableId = result.item.id;

    const copiedItems = [..._sortableFields];
    const [removed] = copiedItems.splice(src.index, 1);
    copiedItems.splice(dest.index, 0, removed);
    set(copiedItems);
  };

  return (
    <DialogContent fullScreen={false} size={"sm"} onBgClickClosable={true}>
      <ReactSortable
        id="board"
        group="board"
        ghostClass="invisible"
        list={_sortableFields.map((e) => ({ ...e, id: e.label })) ?? []}
        setList={() => {}}
        onEnd={onDragEnd}
      >
        {_sortableFields.map((eachField, i) => (
          <EachFieldSelectionModalItem
            key={"colItem" + eachField.label}
            label={eachField.label}
            isSelected={
              eachField.isActive === true || eachField.isActive === undefined
            }
            onClick={() =>
              set(
                _sortableFields.map((e) =>
                  e.label !== eachField.label
                    ? e
                    : {
                        ...eachField,
                        isActive: !(
                          eachField.isActive === true ||
                          eachField.isActive === undefined
                        ),
                      }
                )
              )
            }
          />
        ))}
      </ReactSortable>
    </DialogContent>
  );
}

export default function ItemsListTableSection({
  onLoadMoreIndicator,
}: {
  onLoadMoreIndicator?: React.ReactNode;
}) {
  const _modalContext = useModalContext();
  const mediaQuery = useMediaQuery();

  const {
    data,
    title,
    selecteds,
    onItemSelect,
    setSelecteds,
    onItemClick,
    selectedActionsSection,
    onItemDelete,
    onSortDir,
    setSelectedSortDir,
    selectedSortDir,
    sortableFields,
    onSortField,
    setSelectedSortFieldValue,
    selectedSortFieldValue,
    ellipsisResponsiveBreakPoint,
    btnTextResponsiveBreakPoint,
    onLoadMore,
  } = useItemsListContext();

  const [_sortableFields, _setSortableFields] =
    React.useState<FieldType<any>[]>();

  const _titleField = sortableFields.find((e) => e.isTitleField);

  React.useEffect(() => {
    LocalStorageServices.get("TABLE_FIELDS_" + title).then((r) => {
      if (r === null) {
        _setSortableFields(sortableFields);
      } else {
        let prevOrdered = (r as FieldType<any>[])
          .map((e) => {
            let eachField = sortableFields.find((a) => a.label === e.label);

            if (eachField !== undefined) eachField.isActive = e.isActive;

            return eachField;
          })
          .filter((e) => e !== undefined) as FieldType<any>[];

        let notOrderedYet = sortableFields.filter(
          (a) => !(r as FieldType<any>[]).some((e) => a.label === e.label)
        );

        _setSortableFields([...prevOrdered, ...notOrderedYet]);
      }
    });
  }, []);

  const setSortableFields = (d: FieldType<any>[]) => {
    _setSortableFields(d);
    LocalStorageServices.set(
      "TABLE_FIELDS_" + title,
      d.map((e) => ({ label: e.label, isActive: e.isActive }))
    );
  };

  return (
    <div className="overflow-x-auto pt-2 relative ">
      {data !== undefined && _sortableFields !== undefined && (
        <Table /* className="table mb-1 w-full table-striped hover:table border-separate border-spacing-y-1 " */
        >
          {
            // sortableFields.length > 1 &&
            <TableHeader /* className="bg-dark " */>
              <TableRow>
                {_sortableFields!
                  .filter(
                    (e) => e.isActive === true || e.isActive === undefined
                  )
                  .map((eachField, eachFieldIndex) => (
                    <Sticky
                      side="left"
                      disabled={
                        !eachField.isTitleField ||
                        mediaQuery <= MediaQuery2Num.md
                      }
                      key={"sortCol" + eachFieldIndex}
                      className={cn(
                        "bg-muted p-1 data-[stick=true]:shadow-md",
                        {
                          "md:sticky -left-1 z-[1]":
                            eachField.isTitleField &&
                            mediaQuery > MediaQuery2Num.md,
                        }
                      )}
                    >
                      <TableHead
                        // className={cn(
                        //   "bg-muted p-1 data-[stick=true]:shadow-md",
                        //   {
                        //     "md:sticky left-0 z-[1]":
                        //       eachField.isTitleField &&
                        //       mediaQuery > MediaQuery2Num.md,
                        //   }
                        // )}
                        style={{
                          width: eachField.width,
                        }}
                      >
                        <Button
                          variant={"light"}
                          disabled={eachField.isSortable === false}
                          className={cn(
                            " w-full justify-start " //hover:bg-transparent hover:text-secondary py-2 px-0
                            // { "": eachField.width === "auto" }
                          )}
                          onClick={
                            eachField.isSortable === false
                              ? undefined
                              : () => {
                                  if (
                                    selectedSortFieldValue === eachField.value
                                  ) {
                                    onSortDir?.(
                                      -selectedSortDir as SortDirType
                                    );
                                    setSelectedSortDir(
                                      -selectedSortDir as SortDirType
                                    );
                                  } else {
                                    onSortField?.(eachField.value);
                                    setSelectedSortFieldValue(eachField.value);
                                  }
                                }
                          }
                        >
                          {selectedSortFieldValue === eachField.value &&
                            (selectedSortDir === 1 ? (
                              <FaCaretUp className="!text-base" />
                            ) : (
                              <FaCaretDown className="!text-base" />
                            ))}

                          <span>{strSplitCammelCase(eachField.label)}</span>
                        </Button>
                      </TableHead>
                    </Sticky>
                  ))}

                {/* <TableHead
                  className="text-end bg-muted" 
                >
                  <Button
                    variant="faded"
                    size={"sm"}
                    className=" p-1 "
                    onClick={() =>
                      _modalContext.open(
                        <FieldSelectionModal
                          sortableFields={_sortableFields}
                          setSortableFields={setSortableFields}
                        />
                      )
                    }
                  >
                    <FaGear className="!text-sm" />
                  </Button>
                </TableHead> */}
              </TableRow>
            </TableHeader>
          }

          <TableBody>
            {data?.map((eachItem, eachItemIndex) => (
              <TableRow
                key={"itemsListSectionDesktop" + eachItemIndex}
                className={cn(
                  " group/tools ",
                  selecteds.includes(eachItem) ? " bg-primary " : " bg-card ",
                  { "cursor-pointer": onItemClick || selecteds.length > 0 }
                )}
                onClick={() => {
                  if (selecteds.includes(eachItem)) {
                    setSelecteds(selecteds.filter((e) => e !== eachItem));
                    onItemSelect?.(eachItem)?.(eachItem);
                  } else if (selecteds.length > 0) {
                    setSelecteds([...selecteds, eachItem]);
                    onItemSelect?.(eachItem)?.(eachItem);
                  } else {
                    onItemClick?.(eachItem)?.(eachItem);
                  }
                }}
                onContextMenu={
                  !(
                    onItemSelect(eachItem) ||
                    selectedActionsSection ||
                    onItemDelete([eachItem, ...selecteds])
                  )
                    ? undefined
                    : (e) => {
                        e.preventDefault();

                        if (selecteds.includes(eachItem)) {
                          setSelecteds(selecteds.filter((e) => e !== eachItem));
                          onItemSelect?.(eachItem)?.(eachItem);
                        } else {
                          setSelecteds([...selecteds, eachItem]);
                          onItemSelect?.(eachItem)?.(eachItem);
                        }
                      }
                }
              >
                {_sortableFields!
                  .filter(
                    (e) => e.isActive === true || e.isActive === undefined
                  )
                  .map((eachField, eachFieldIndex) => (
                    <Sticky
                      side="left"
                      key={"col" + eachField.label}
                      disabled={
                        !eachField.isTitleField ||
                        mediaQuery <= MediaQuery2Num.md
                      }
                      className={cn(
                        " group data-[stick=true]:shadow-md ", //break-all p-2 py-1 border
                        eachField.className,
                        {
                          // "rounded-s border-e-0": eachFieldIndex === 0,
                          // "border-s-0 border-e-0": eachFieldIndex > 0,
                          // "flex-1": eachField.width === "auto",
                          "md:sticky -left-1 z-[1]":
                            eachField.isTitleField &&
                            mediaQuery > MediaQuery2Num.md,
                        },
                        selecteds.includes(eachItem)
                          ? " bg-primary text-primary-foreground "
                          : " bg-card group-hover/row:bg-slate-100 group-hover/row:dark:bg-slate-800 group-hover/row:hover:bg-slate-200 group-hover/row:hover:dark:bg-slate-700"
                      )}
                    >
                      <TableCell
                        // className={cn(
                        //   " group data-[stick=true]:shadow-md ", //break-all p-2 py-1 border
                        //   eachField.className,
                        //   {
                        //     // "rounded-s border-e-0": eachFieldIndex === 0,
                        //     // "border-s-0 border-e-0": eachFieldIndex > 0,
                        //     // "flex-1": eachField.width === "auto",
                        //     "md:sticky -left-1 z-[1]":
                        //       eachField.isTitleField &&
                        //       mediaQuery > MediaQuery2Num.md,
                        //   },
                        //   selecteds.includes(eachItem)
                        //     ? " bg-primary text-primary-foreground "
                        //     : " bg-card group-hover/row:bg-slate-100 group-hover/row:dark:bg-slate-800 group-hover/row:hover:bg-slate-200 group-hover/row:hover:dark:bg-slate-700"
                        // )}
                        style={{
                          width: eachField.width,
                        }}
                        onClick={
                          eachField.onClick
                            ? () => {
                                if (selecteds.includes(eachItem)) {
                                  // setSelecteds(selecteds.filter((e) => e !== eachItem));
                                  // onItemSelect?.(eachItem)?.(eachItem);
                                } else if (selecteds.length > 0) {
                                  // setSelecteds([...selecteds, eachItem]);
                                  // onItemSelect?.(eachItem)?.(eachItem);
                                } else {
                                  eachField.onClick!(eachItem, eachItemIndex);
                                }
                              }
                            : undefined
                        }
                      >
                        <div className="inline-flex items-center">
                          {eachField.isTitleField && (
                            <ItemsListToolsSection
                              eachItem={eachItem}
                              ellipsisResponsiveBreakPoint={"always"}
                              ellipsisClassName="visible opacity-20 group-hover/row:opacity-100 -ml-3"
                              deleteMessage={
                                _titleField
                                  ? `You're about to delete "${
                                      eachItem[_titleField.value]
                                    }" in ${title}!`
                                  : undefined
                              }
                            />
                          )}
                          {eachField.getUiContent
                            ? eachField.getUiContent(eachItem, eachItemIndex)
                            : eachItem[eachField.value]}
                        </div>
                      </TableCell>
                    </Sticky>
                  ))}

                {/* <TableCell
                  className={cn(
                    selecteds.includes(eachItem)
                      ? " bg-primary text-primary-foreground "
                      : " bg-card group-hover/row:bg-muted group-hover/row:hover:bg-slate-200"
                  )}
                >
                  <ItemsListToolsSection
                    eachItem={eachItem}
                    ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
                    btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
                    deleteMessage={
                      _titleField
                        ? `You're about to delete "${
                            eachItem[_titleField.value]
                          }" in ${title}!`
                        : undefined
                    }
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {onLoadMore &&
        (onLoadMoreIndicator ?? (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-10" />
          </OnLoadMoreIndicator>
        ))}

      {!onLoadMore && data?.length === 0 && (
        <EmptyListIndicator text={"No " + title + " Found!"} />
      )}
    </div>
  );
}
