import React from "react";
import { ReactSortable, Sortable } from "react-sortablejs";

import Effort, { TrelloSectionTypeItemType } from "models/Effort";

import { ColType } from "./BoardSection";
import EachCardItem from "./EachCardItem";
import { cn, isEmpty, isFarsi } from "services/UtilServices";
import {
  ItemType,
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import Dict from "models/Dict";
import { useDepartmentsContext } from "providers/DepartmentsProvider";
import { usePrefixsContext } from "providers/PrefixsProvider";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import { useStatesContext } from "providers/StatesProvider";
import { useTagsContext } from "providers/TagsProvider";
import { useTicketsContext } from "providers/TicketsProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";
import EffortCreateUpdateModal from "../EffortCreateUpdateModal";
import { useModalContext } from "providers/ModalProvider";
import { FaPlus } from "react-icons/fa6";
import MenuButton from "components/ui/menu-button";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { LuPlus } from "react-icons/lu";

export default function EachColumn({
  col,
  basedOnType,
  onDragEnd,
  onDragStart,
  onCardRemove,
  disabled,
  colActions,
  colClick,
}: {
  col: ColType;
  disabled?: boolean;
  basedOnType: TrelloSectionTypeItemType;
  onDragEnd: (ev: Sortable.SortableEvent) => void;
  onDragStart: (ev: Sortable.SortableEvent) => void;
  onCardRemove: (e: Effort) => void;
  colActions?: (col: ColType) => ItemType[];
  colClick?: (col: ColType) => void;
}) {
  const { onItemDelete, onItemEdit } = useItemsListContext();
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _usersContext = useUsersContext();
  const _statesContext = useStatesContext();
  const _prefixsContext = usePrefixsContext();
  const _ticketsContext = useTicketsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _departmentsContext = useDepartmentsContext();
  const _effortUtils = useEffortUtils();

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  const ref = React.useRef<HTMLDivElement>(null);

  const _effort =
    basedOnType.label === "effort"
      ? _mainContext.efforts?.find((e) => e.id === parseInt(col.id))
      : undefined;

  const onSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.create(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _tagsContext.getAll(_effort!.id);
    _typesContext.getAll(_effort!.id);
    _statesContext.getAll(_effort!.id);
    _prefixsContext.getAll(_effort!.id);
    _ticketsContext.getAll(_effort!.id);
    _prioritiesContext.getAll(_effort!.id);
    _departmentsContext.getAll(_effort!.id);

    // title: _isHome
    //   ? "Project Information"
    //   : _isProject
    //   ? "Task Information"
    //   : "SubTask Information",
    _modalContext.open(
      <EffortCreateUpdateModal onSubmit={onSubmit} parentId={_effort!.id} />
    );
  };

  return (
    <div className="px-1">
      <div
        className={cn(
          "rounded transition-all duration-100 pb-2 overflow-hidden border w-80 ",
          { "opacity-25": disabled }
        )}
        style={{
          background: col.color + "33",
        }}
      >
        <div
          onClick={colClick ? () => colClick(col) : undefined}
          className={cn(
            "flex items-center rounded-t py-3 shadow relative w-full group ",
            { "cursor-grab": basedOnType.colsSortable }
          )}
        >
          <span
            className={cn(
              "block capitalize text-center flex-1 mx-3 truncate text-foreground ",
              {
                "direction-rtl":
                  typeof col.title === "string" && isFarsi(col.title),
              }
            )}
          >
            {col.title}
          </span>

          <div className="absolute right-0 md:invisible group-hover:visible">
            <MenuButton
              ellipsisResponsiveBreakPoint="always"
              onEdit={
                !_effort || onItemEdit(_effort) === undefined
                  ? undefined
                  : () => onItemEdit(_effort)?.(_effort)
              }
              onDelete={
                !_effort || onItemDelete([_effort]) === undefined
                  ? undefined
                  : () => onItemDelete([_effort])?.([_effort])
              }
              items={
                !_effort
                  ? undefined
                  : [
                      {
                        text: "new",
                        icon: <LuPlus />,
                        onClick: () => openCreateUpdateModal(),
                      },
                    ]
              }
            />
          </div>
        </div>

        <div ref={ref}></div>

        <ReactSortable
          id={col.id + ""}
          group={"Col"}
          ghostClass="opacity-25" //invisible
          setList={() => {}}
          onEnd={onDragEnd}
          onStart={onDragStart}
          list={col.effortIds?.map((e) => ({ id: e })) ?? []}
          disabled={disabled || basedOnType.draggable === false}
          style={{
            maxHeight: `calc(100vh - ${ref.current?.offsetTop}px - 30px)`,
          }}
          className={cn("p-2 overflow-y-auto min-h-[50px]")}
        >
          {col.effortIds?.map((e) => (
            <EachCardItem
              key={"eachItem" + e}
              effortId={e}
              onRemove={basedOnType.required ? undefined : onCardRemove}
            />
          ))}
        </ReactSortable>
      </div>
    </div>
  );
}
