import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import useUserUtils from "hooks/utils/UseUserUtils";
import Member, { MemberAccessTypes } from "models/Member";

import DeleteButton from "components/common/DeleteButton";
import { NewButtonMini } from "components/common/AdminSection/NewButton";
import MemberAvatar from "./MemberAvatar";
import { LuX } from "react-icons/lu";
import { Button } from "components/ui/button";
import useMemberUtils from "hooks/utils/UseMemberUtils";

function EachItem({
  member,
  onClick,
  onRemove,
}: {
  member: Member;
  onClick?: () => void;
  onRemove?: () => void;
}) {
  const _userUtils = useUserUtils();
  const Icon = MemberAccessTypes.icons[member.accessType];

  return (
    <Button
      variant="light"
      onClick={onClick}
      className="rounded-full border p-0 bg-muted "
    >
      <MemberAvatar member={member} size={40} needHover={false} />

      <div className="py-1 flex-1 text-start">
        {_userUtils.getFullName(member.user)}
        <div className="text-xs text-muted-foreground ">
          <Icon className="!text-sm" />{" "}
          {MemberAccessTypes.options[member.accessType]} Access
        </div>
      </div>

      {onRemove ? (
        <DeleteButton
          size="sm"
          onConfirm={onRemove}
          className="rounded-full aspect-square "
        >
          <LuX />
        </DeleteButton>
      ) : (
        <div className="ps-4"></div>
      )}
    </Button>
  );
}

export default function MembersSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();
  const _memberUtils = useMemberUtils(effort);

  return (
    <div className="col-12 p-0 mb-1">
      <div className="bg-card border rounded p-3 min-h-[100px]">
        <div className="flex items-center">
          <h6 className="mb-0 text-foreground">
            {_effortUtils.isProject(effort) ? "Members" : "Assignees"}
          </h6>
          {_memberUtils.canCreate() && (
            <NewButtonMini
              onClick={() => _memberUtils.openCreateUpdateModal()}
            />
          )}
        </div>

        <div className="flex flex-wrap mt-2 gap-1">
          {effort.members
            ?.filter((e) => e.isDeleted === false)
            .map((eachMember, i) => (
              <EachItem
                key={"eachAttachment" + i}
                member={eachMember}
                onClick={
                  _memberUtils.canEdit(eachMember)
                    ? () => _memberUtils.openCreateUpdateModal(eachMember)
                    : undefined
                }
                onRemove={
                  _memberUtils.canDelete([eachMember])
                    ? () => _memberUtils.removeMany([eachMember])
                    : undefined
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
}
