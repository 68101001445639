import React from "react";

import Dict from "models/Dict";
import Effort from "models/Effort";
import Attachment from "models/Attachment";
import useAttachmentUtils from "hooks/utils/UseAttachmentUtils";

import { getItem, isEmpty, listFirst } from "services/UtilServices";

import { useAttachmentsContext } from "providers/AttachmentsProvider";

import LoadingSpinner from "components/common/LoadingSpinner";
import SelectFileButtonStandalone from "components/common/Forms/SelectFileButtonStandalone";
import AttachmentItem from "./AttachmentItem";
import Skeleton from "components/common/Skeleton";

import { FaPlus } from "react-icons/fa6";
import { useToast } from "components/ui/use-toast";
import { useEachEffortContext } from "providers/EachEffortProvider";
import { LuPlus } from "react-icons/lu";

function CreateAttachmentButton() {
  const _attachmentsContext = useAttachmentsContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const { toast } = useToast();

  return (
    <SelectFileButtonStandalone
      multiple
      wrapperClassName="inline-block "
      size="sm"
      className="ms-2 border relative p-0.5"
      disabled={isLoading}
      onChange={async (data) => {
        setIsLoading(true);
        let _error = await _attachmentsContext.createMany({ files: data });
        setIsLoading(false);

        if (!isEmpty(_error)) {
          let _message = listFirst(listFirst(Object.values(_error) as Dict[]));
          if (_message) {
            toast({
              variant: "destructive",
              description: _message,
            });
          }
        }
      }}
    >
      <LuPlus className="!text-base" />
      {isLoading && <Skeleton className="absolute inset-0" />}
    </SelectFileButtonStandalone>
  );
}

export default function AttachmentsSection({ effort }: { effort: Effort }) {
  const _attachmentsContext = useAttachmentsContext();
  const _eachEffortContext = useEachEffortContext();
  const attachmentUtils = useAttachmentUtils(_eachEffortContext.effort!.id);

  return (
    <div className="col-12 p-0 mb-1">
      <div className="bg-card border rounded p-3 min-h-[100px]">
        <div className="flex items-center">
          <h6 className="mb-0 text-foreground">Attachments</h6>

          {attachmentUtils.canCreate() && <CreateAttachmentButton />}
        </div>

        <div className="flex flex-wrap mt-2 gap-1">
          {effort.attachments?.map((e, i) => (
            <AttachmentItem
              key={"eachAttachment" + i}
              attachment={e}
              onDelete={
                attachmentUtils.canDelete(e)
                  ? () => _attachmentsContext.removeMany([e])
                  : undefined
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}
