import MemberCreateUpdateModal from "components/pages/EachEffort/MembersSection/MemberCreateUpdateModal";
import MemberNotificationModal from "components/pages/EachEffort/MembersSection/MemberNotificationModal";
import useAuthUtils from "hooks/UseAuthUtils";
import Dict from "models/Dict";
import Effort from "models/Effort";
import Member, { MemberAccessTypes } from "models/Member";
import { UserRoles } from "models/User";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useMembersContext } from "providers/MembersProvider";
import { useModalContext } from "providers/ModalProvider";
import { useUsersContext } from "providers/UsersProvider";
import { isEmpty } from "services/UtilServices";
import { useEffortUtils } from "./UseEffortUtils";

export default function useMemberUtils(effort?: Effort) {
  const _currentUserContext = useCurrentUserContext();
  const _modalContext = useModalContext();
  const _usersContext = useUsersContext();
  const _membersContext = useMembersContext();
  const _authUtils = useAuthUtils();
  const _effortUtils = useEffortUtils();

  let _accessType = _authUtils.authorize(effort?.id);

  const canCreate = () => {
    return _accessType >= MemberAccessTypes.Create;
  };

  const canEdit = (item: Member) => {
    return (
      _accessType >= MemberAccessTypes.Edit ||
      _currentUserContext.user?.id === item.userId
    );
  };

  const canDelete = (items: Member[]) => {
    const _userIds = items.map((a) => a.userId);
    const _subEfffortsDeep = _effortUtils.getSubEffortsDeep({
      id: effort?.id,
      includeSelf: false,
    });

    return (
      _accessType >= MemberAccessTypes.Delete &&
      !_subEfffortsDeep.some((e) =>
        e.members?.some((a) => _userIds.includes(a.userId))
      )
    );
  };

  const canUpdateNotif = (item?: Member) => {
    return item?.userId === _currentUserContext.user!.id; //_currentUserContext.user!.role === UserRoles.ADMIN ||
  };

  const getCurrent = (effort?: Effort) => {
    return effort?.members?.find(
      (e) => e.userId === _currentUserContext.user?.id
    ); //_currentUserContext.user!.role === UserRoles.ADMIN ||
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _membersContext.update(data)
        : await _membersContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openNotificationModal = (formData?: Dict) => {
    _modalContext.open(
      <MemberNotificationModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    if (!effort) {
      throw new Error("effort is required");
    }

    if (!formData) {
      _usersContext.getAll(effort.parentId);
    }

    _modalContext.open(
      <MemberCreateUpdateModal
        formData={formData}
        onSubmit={onSubmit}
        effortId={effort.id}
        parentId={effort.parentId}
        accessType={_accessType}
        usersAlreadyMember={effort.members?.map((e) => e.userId)}
      />
    );
  };

  return {
    canCreate,
    canEdit,
    canDelete,
    openCreateUpdateModal,
    canUpdateNotif,
    removeMany: _membersContext.removeMany,
    openNotificationModal,
    getCurrent,
  };
}
