import React from "react";

import {
  NetworkImage,
  ImageSizes,
  ImageDirs,
  NetworkImageProps,
} from "./NetworkImage";
import { cn } from "services/UtilServices";

export type AvatarProps = {
  src: string | Blob | undefined | null;
  size?: number;
} & Omit<NetworkImageProps, "size">;

export default function Avatar({ src, size = 35, ...rest }: AvatarProps) {
  return (
    <NetworkImage
      src={src}
      size={ImageSizes.SMALL}
      alt="First slide"
      secure={false}
      {...rest}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
      }}
      className={cn(
        "rounded-full aspect-square border object-cover inline-block",
        rest.className
      )}
    />
  );
}
