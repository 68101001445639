import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import Attachment from "models/Attachment";

import MenuButton from "components/ui/menu-button";
import { NetworkImage } from "components/common/NetworkImage";
import useAttachmentApi from "hooks/api/UseAttachmentApi";
import { cn, getFileType } from "services/UtilServices";
import { LuDownload } from "react-icons/lu";

export default function AttachmentItem({
  attachment,
  onDelete,
  onClick,
  disabled,
}: {
  attachment: Attachment;
  disabled?: boolean;
  onDelete?: () => Promise<Dict>;
  onClick?: () => void;
}) {
  const _isLocal = attachment.url.startsWith("data:");
  const name = attachment.name;
  const extension = attachment.name.substring(
    attachment.name.lastIndexOf(".") + 1
  );

  const attachmentApi = useAttachmentApi();
  // _isLocal ?
  //     attachment.name.substring(attachment.name.lastIndexOf(".")+1) :
  //     attachment.url.substring(attachment.url.lastIndexOf(".")+1);

  const download = async () => {
    let _errors = {};

    try {
      await attachmentApi.download(attachment);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <div
      onClick={onClick}
      className={cn(
        "flex items-center rounded border p-0 bg-muted text-foreground ",
        { "cursor-pointer": onClick }
      )}
    >
      {getFileType(attachment.name) === "image" ? (
        <NetworkImage
          src={attachment.url}
          secure
          className="rounded-s object-cover w-10 h-10 border-e bg-background"
        />
      ) : (
        <div className="rounded-s flex items-center justify-center bg-background px-1 min-w-[40px] h-10 border-e ">
          <span className="mb-0 uppercase text-xs">
            {extension.substring(0, 5)}
          </span>
        </div>
      )}

      <span className="whitespace-nowrap ms-2 text-sm">
        {name.length <= 20
          ? name
          : name.substring(0, 10) + "..." + name.substring(name.length - 10)}
      </span>

      <MenuButton
        ellipsisResponsiveBreakPoint="always"
        disabled={disabled}
        onDelete={onDelete}
        items={[
          ...(_isLocal
            ? []
            : [
                {
                  icon: <LuDownload />,
                  text: "download",
                  onClick: download,
                },
              ]),
        ]}
      />
    </div>
  );
}
