import React from "react";
import Lottie from "react-lottie-player";

import LoadingSpinner from "components/common/LoadingSpinner";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { LuLogOut } from "react-icons/lu";
import { cn } from "services/UtilServices";
import { Button } from "components/ui/button";
import AppLogo from "components/pages/AppLogo";

export default function SplashPage() {
  const _currentUserContext = useCurrentUserContext();
  const [showLogoutButton, setShowLogoutButton] = React.useState(false);
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    setTimeout(() => {
      if (mounted.current) {
        setShowLogoutButton(true);
      }
    }, 5000);

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-background ">
      <div className="text-center">
        <AppLogo width={300} />

        <h5 className="mt-4 text-foreground">Task Management System</h5>
      </div>

      <Lottie play loop path="/lottie/splash_loading.json" className=" w-72" />

      <span className="text-foreground">Loading...</span>

      {/* <div className="flex items-center mb-4">
                <span>Loading...</span>
                <div className="me-2">
                    <LoadingSpinner />
                </div>
            </div>
             */}

      <Button
        variant={"light"}
        className={cn(
          "border flex items-center p-3 my-3 transition-all duration-100 ",
          { "hidden opacity-0": !showLogoutButton }
        )}
        onClick={_currentUserContext.logout}
      >
        <span className="h6 mb-0">Logout</span>
        <LuLogOut />
      </Button>
    </div>
  );
}
