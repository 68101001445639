import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import React from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { LuX } from "react-icons/lu";
import { cn } from "services/UtilServices";

function SearchComponent({
  onSearch = () => {},
  color = "secondary",
  placeholder = "Search",
}: {
  onSearch?: (s: string) => void;
  color?: string;
  placeholder?: string;
}) {
  const [value, setValue] = React.useState("");

  return (
    <div className="relative  flex items-center ">
      {value === "" ? null : (
        <Button
          variant={"light"}
          onClick={() => {
            setValue("");
            onSearch("");
          }}
          className="rounded-full aspect-square absolute p-1 px-2 flex items-center justify-center right-1 w-7 h-7"
        >
          <LuX
            className={cn(" ", {
              // "text-secondary-foreground": color === "secondary",
              "text-white": color === "white",
            })}
          />
        </Button>
      )}

      <FaMagnifyingGlass //fal
        className={cn("absolute left-4 ", {
          "text-slate-400": color === "secondary",
          "text-white": color === "white",
        })}
      />

      <Input
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          onSearch(e.target.value);
        }}
        className={cn(
          "rounded-full form-control border-b bg-transparent border px-11 h-7 flex-1 ",
          {
            // " border-secondary text-secondary-foreground placeholder-secondary": color === "secondary",
            " border-white text-white placeholder-white": color === "white",
          }
        )}
      />
    </div>
  );
}

export default SearchComponent;
