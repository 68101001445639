import React from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

import { useModalContext } from "providers/ModalProvider";

import { base64toFile, makeid } from "./UtilServices";
import { FaCamera, FaImages } from "react-icons/fa6";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";

export function SelectManyImageModal({ onChange = (files: File[]) => {} }) {
  const _modalContext = useModalContext();

  return (
    <DialogContent size={"sm"} fullScreen={false} onBgClickClosable={true}>
      <div className="flex flex-col">
        <Button
          className=" py-3"
          variant={"light"}
          onClick={() => {
            SelectImageServices.takeFromCamera().then((image) => {
              if (image?.dataUrl !== undefined) {
                onChange([base64toFile(image.dataUrl, ".jpg")]);
              }
            });
            _modalContext.dismiss();
          }}
        >
          <FaCamera className="me-2" />
          Camera
        </Button>

        <Separator className="m-0" />

        <Button
          className=" py-3"
          variant={"light"}
          onClick={() => {
            SelectImageServices.selectManyFromGallery().then(async (images) => {
              let _result: File[] = [];

              for (const e of images.photos) {
                const response = await fetch(e.webPath);
                const data = await response.blob();

                _result.push(
                  new File([data], makeid() + "." + e.format, {
                    type: data.type,
                  })
                );
              }

              onChange(_result);
            });
            _modalContext.dismiss();
          }}
        >
          <FaImages className="me-2" />
          Gallery
        </Button>
      </div>
    </DialogContent>
  );
}

export function SelectSingleImageModal({ onChange = (file: File) => {} }) {
  const _modalContext = useModalContext();

  return (
    <DialogContent size={"sm"} fullScreen={false}>
      <div className="flex flex-col">
        <Button
          className=" py-3"
          variant={"light"}
          onClick={() => {
            SelectImageServices.takeFromCamera().then((image) => {
              if (image?.dataUrl !== undefined) {
                let ex = image.dataUrl
                  .match(/(png|jpg|jpeg);base64,/)![0]
                  .split(";")[0];
                onChange(base64toFile(image.dataUrl, ex));
              }
            });
            _modalContext.dismiss();
          }}
        >
          <FaCamera className="ms-2" />
          Camera
        </Button>

        <Separator className="m-0" />

        <Button
          className=" py-3"
          variant={"light"}
          onClick={() => {
            SelectImageServices.selectSingleFromGallery().then(
              async (image) => {
                if (image?.dataUrl !== undefined) {
                  onChange(base64toFile(image.dataUrl, ".jpg"));
                }
              }
            );
            _modalContext.dismiss();
          }}
        >
          <FaImages className="ms-2" />
          Gallery
        </Button>
      </div>
    </DialogContent>
  );
}

export default class SelectImageServices {
  static takeFromCamera() {
    return Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      presentationStyle: "fullscreen",
      source: CameraSource.Camera,
    });
  }

  static selectSingleFromGallery() {
    return Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      presentationStyle: "fullscreen",
      source: CameraSource.Photos,
    });
  }

  static selectManyFromGallery() {
    return Camera.pickImages({
      quality: 90,
      presentationStyle: "fullscreen",
      limit: 10,
    });
  }
}
