import React from "react";

import Dict from "models/Dict";
import Priority, { PriorityFilter, PrioritySortType } from "models/Priority";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import {
  usePrioritiesContext,
  PrioritiesProvider,
} from "providers/PrioritiesProvider";

import PriorityFilterModal from "./PriorityFilterModal";
import NewButton from "components/common/AdminSection/NewButton";
import PriorityCreateUpdateModal from "./PriorityCreateUpdateModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import FilterButton from "components/common/AdminSection/FilterButton";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import LocalDateTime from "components/common/LocalDateTime";
import usePriorityUtils from "hooks/utils/UsePriorityUtils";

function EachPriorityItem({
  priority,
  index,
}: {
  priority: Priority;
  index: number;
}) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 w-5 h-5 "
        )}
        style={{
          background: priority.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{priority.name}</h6>
      </div>
    </div>
  );
}

function PrioritiesPageBody() {
  const _modalContext = useModalContext();
  const _prioritiesContext = usePrioritiesContext();
  const priorityUtils = usePriorityUtils();

  const onRemove = async (datas: Priority[]) => {
    _prioritiesContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _prioritiesContext.update(data)
        : await _prioritiesContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <PriorityCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _prioritiesContext.get({
      ...formData,
      pageNumber: -1,
    } as PriorityFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <PriorityFilterModal
        filter={_prioritiesContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <main role="main" className="md:p-4 p-3 h-full">
      <ItemsListSection<Priority>
        title="Priorities"
        data={_prioritiesContext.priorities}
        // ellipsisResponsiveBreakPoint=""

        fields={[
          // {
          //   value: "",
          //   label: "#",
          //   className: "text-start whitespace-nowrap",
          //   width: "70px",
          //   isSortable: false,
          //   getUiContent: (e, i) => i + 1,
          // },

          {
            value: "name",
            label: "name",
            isTitleField: true,
            width: "auto",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "color",
            label: "color",
            width: "10%",
            isSortable: false,
            isSearchable: false,
            getUiContent: (e, i) => (
              <div
                className={cn("rounded-full aspect-square w-7 h-7 ")}
                style={{
                  background: e.color,
                }}
              />
            ),
          },

          {
            value: "weight",
            label: "weight",
            width: "auto",
            // isSortable: false,
            className: "whitespace-nowrap",
          },

          {
            value: "dateAdd",
            label: "Created on",
            width: "auto",
            // isSortable: false,
            getValue: (e) => dateGetTime(e.dateAdd),
            getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
            className: "whitespace-nowrap",
          },
        ]}
        onLoadMore={
          !_prioritiesContext.hasNext
            ? undefined
            : async () => {
                await _prioritiesContext.get({
                  ..._prioritiesContext.filter,
                  pageNumber: _prioritiesContext.filter.pageNumber + 1,
                });
              }
        }
        onSortField={(v) => {
          _prioritiesContext.get({
            ..._prioritiesContext.filter,
            sortBy: v as PrioritySortType,
            pageNumber: -1,
          });
        }}
        onSortDir={(v) => {
          _prioritiesContext.get({
            ..._prioritiesContext.filter,
            ascOrder: v === 1,
            pageNumber: -1,
          });
        }}
        initialSelectedSortFieldValue="dateAdd"
        buildCardTitleRow={(v, i) => (
          <EachPriorityItem priority={v} index={i} />
        )}
        onItemEdit={(u) => openCreateUpdateModal}
        onItemClick={(u) => openCreateUpdateModal}
        onItemDelete={() => _prioritiesContext.removeMany}
        actionsSection={
          <>
            <FilterButton
              count={_prioritiesContext.totalNumber}
              isActive={priorityUtils.isFilterActive({
                filter: _prioritiesContext.filter,
              })}
              onClick={openFilterModal}
            />

            <NewButton onClick={() => openCreateUpdateModal()} />
          </>
        }
      />
    </main>
  );
}

export default function PrioritiesPage() {
  return (
    <PrioritiesProvider>
      <PrioritiesPageBody />

      <MobileFooterPadding />
    </PrioritiesProvider>
  );
}
