import React from "react";
import { cn, makeid } from "services/UtilServices";

export default function Truncate({
  maxCount = 3,
  children,
  className,
}: {
  maxCount?: number;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("inline-flex gap-1 items-center ", className)}>
      {React.Children.map(children, (e, i) => e)?.slice(0, maxCount)}
      <span className="whitespace-nowrap">
        {React.Children.count(children) > maxCount
          ? "+" + (React.Children.count(children) - maxCount)
          : ""}
      </span>
    </div>
  );
}
