import React from "react";

import Dict from "../../../models/Dict";

import ItemsListProvider, {
  useItemsListContext,
  ItemsListProviderInputProps,
} from "./ItemsListProvider";

import ItemListSearchbarSection from "./ItemsListSearchbarSection";
import ItemsListTableSection from "./ItemsListTableSection";
import ItemsListCardSection from "./ItemsListCardSection";
import useMediaQuery, { MediaQuery2Num } from "../useMediaQuery";
import EmptyListIndicator from "../EmptyListIndicator";
import { RESPONSIVE_KEYS } from "models/views/responsive";

function ItemsListSectionBody({
  tableBreakPointSize = "md",
}: {
  tableBreakPointSize?: RESPONSIVE_KEYS;
}) {
  const {
    data,
    actionsSection,
    itemClassName,
    bottomActionSection,
    buildCardBodyItem,
    buildCardTitleRow,
    itemStyle,
    onSearch,
    onLoadMore,
    title,
  } = useItemsListContext();

  let size = useMediaQuery();

  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <div className="h-full">
      <ItemListSearchbarSection
        isSearchable={onSearch !== null}
        actionsSection={actionsSection}
        bottomActionSection={bottomActionSection}
        tableModeBreakPoint={tableBreakPointSize}
      />

      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : size > 5 - MediaQuery2Num[tableBreakPointSize] ? (
        <ItemsListTableSection />
      ) : (
        <ItemsListCardSection
          buildCardBodyItem={buildCardBodyItem}
          buildCardTitleRow={buildCardTitleRow}
          itemClassName={itemClassName}
        />
      )}
    </div>
  );
}

interface PropTypes<T extends Dict>
  extends React.PropsWithChildren<ItemsListProviderInputProps<T>> {
  tableBreakPointSize?: RESPONSIVE_KEYS;
}

function ItemsListSection<T extends Dict>(props: PropTypes<T>) {
  return (
    <ItemsListProvider<T> {...props}>
      <ItemsListSectionBody tableBreakPointSize={props.tableBreakPointSize} />
    </ItemsListProvider>
  );
}

export default ItemsListSection;
