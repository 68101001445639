import React from "react";

import useUserUtils from "hooks/utils/UseUserUtils";
import Effort, { TRELLO_SECTION_TYPES } from "models/Effort";

import { useStatesContext } from "providers/StatesProvider";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import {
  cn,
  dateGetMonth,
  dateGetWeek,
  dateGetWeekDayName,
  dateIsToday,
  isEmpty,
  listFirst,
  listLast,
  listPartition,
  str2Color,
} from "services/UtilServices";
import { Form, Select } from "components/common/Forms/Form";
import { useTagsContext } from "providers/TagsProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";
import { useTicketsContext } from "providers/TicketsProvider";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import SelectButtonGroup from "components/common/Forms/SelectButtonGroup";
import BoardSection, { ColType } from "./BoardSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import { useEffortsContext } from "providers/EffortsProvider";
import { useMembersContext } from "providers/MembersProvider";
import LocalStorageServices from "services/localServices/LocalStorageServices";
import { useMainContext } from "providers/MainProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import LocalDateTime from "components/common/LocalDateTime";
import { useNavigate } from "react-router";
import { useToast } from "components/ui/use-toast";
import { useEffortUtils, useEffortAuthUtils } from "hooks/utils/UseEffortUtils";
import Dict from "models/Dict";

export default function EffortsListTrelloSection({
  parentId = null,
}: {
  parentId?: number | null;
}) {
  const _effortsContext = useEffortsContext();
  const _mainContext = useMainContext();
  const _itemsListContext = useItemsListContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortUtils = useEffortUtils();
  const _effortAuthUtils = useEffortAuthUtils();
  const _userUtils = useUserUtils();

  const _statesContext = useStatesContext();
  // const _tagsContext = useTagsContext();
  // const _typesContext = useTypesContext();
  const _usersContext = useUsersContext();
  // const _ticketsContext = useTicketsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _membersContext = useMembersContext();

  const { toast } = useToast();

  const _efforts = _itemsListContext.data as Effort[];
  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  const navigate = useNavigate();

  const basedOnTypesOptions = Object.entries(TRELLO_SECTION_TYPES)
    .filter(([k, v]) => (_isTask ? k !== "effort" : true))
    .map(([k, v]) => ({
      value: k,
      label: k === "effort" ? (_isHome ? "Project" : "Task") : v.label,
    }));
  const [_basedOnType, _setBasedOnType] =
    React.useState<keyof typeof TRELLO_SECTION_TYPES>();

  var cols: ColType[] | undefined = undefined;
  if (_basedOnType === "state") {
    cols = _statesContext.states?.map((eachItem) => ({
      id: eachItem.id + "",
      title: eachItem.name,
      color: eachItem.color,
      effortIds: _effortUtils
        .filter(_efforts, { stateIds: [eachItem.id] })
        ?.map((e) => e.id),
    }));
  } else if (_basedOnType === "effort") {
    cols = _efforts.map((eachItem) => ({
      id: eachItem.id + "",
      title: eachItem.title,
      color: str2Color(eachItem.title),
      effortIds: _mainContext.efforts
        ?.filter((a) => a.parentId === eachItem.id)
        ?.map((e) => e.id),
    }));
    // } else if (_basedOnType === "type") {
    //   cols = _typesContext.types?.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.name,
    //     color: eachItem.color,
    //     effortIds: _effortUtils
    //       .filter(_efforts, { typeIds: [eachItem.id] })
    //       ?.map((e) => e.id),
    //   }));
    // } else if (_basedOnType === "tag") {
    //   cols = _tagsContext.tags?.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.name,
    //     color: eachItem.color,
    //     effortIds: _effortUtils
    //       .filter(_efforts, { tagIds: [eachItem.id] })
    //       ?.map((e) => e.id),
    //   }));
  } else if (_basedOnType === "user") {
    cols = _usersContext.users?.map((eachItem) => ({
      id: eachItem.id,
      title: (
        <span
          className={cn("", {
            "font-bold": _currentUserContext.user?.id === eachItem.id,
          })}
        >
          <ProfileAvatar user={eachItem} needName />
        </span>
      ),
      color: str2Color(eachItem.id),
      effortIds: _effortUtils
        .filter(_efforts, { memberIds: [eachItem.id] })
        ?.map((e) => e.id),
    }));
    // } else if (_basedOnType === "ticket") {
    //   cols = _ticketsContext.tickets?.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.name,
    //     color: eachItem.color,
    //     effortIds: _effortUtils
    //       .filter(_efforts, {
    //         ticketIds: [eachItem.id],
    //       })
    //       ?.map((e) => e.id),
    //   }));
  } else if (_basedOnType === "priority") {
    cols = _prioritiesContext.priorities?.map((eachItem) => ({
      id: eachItem.id + "",
      title: eachItem.name,
      color: eachItem.color,
      effortIds: _effortUtils
        .filter(_efforts, {
          priorityIds: [eachItem.id],
        })
        ?.map((e) => e.id),
    }));
  } /* else if (_basedOnType === "today") {
    cols = [
      {
        id: "today",
        title: "today",
        effortIds: _effortUtils.filter(_efforts, {
          dateDueFrom: dateGetToday(),
          dateDueTo: dateGetToday(),
        }),
      },
    ];
  }  */ else if (_basedOnType === "thisWeek") {
    cols = dateGetWeek().map((eachItem) => ({
      id: eachItem.toISOString(),
      color: str2Color(dateGetWeekDayName(eachItem)),
      effortIds: _effortUtils
        .filter(_efforts, {
          dateDueFrom: eachItem,
          dateDueTo: eachItem,
        })
        ?.map((e) => e.id),
      title: (
        <div className="text-center">
          <div className={cn("", { "font-bold": dateIsToday(eachItem) })}>
            {dateGetWeekDayName(eachItem)}
          </div>
          <LocalDateTime date={eachItem} className="text-xs" />
        </div>
      ),
    }));
  } else if (_basedOnType === "thisMonth") {
    cols = listPartition(
      dateGetMonth(),
      (e) => dateGetWeekDayName(e) === "sunday"
    ).map((eachWeek, i) => ({
      id: listFirst(eachWeek)!.toISOString(),
      color: str2Color(i + 1 + "Week"),
      effortIds: _effortUtils
        .filter(_efforts, {
          dateDueFrom: listFirst(eachWeek),
          dateDueTo: listLast(eachWeek),
        })
        ?.map((e) => e.id),
      title: (
        <div className="text-center">
          <div
            className={cn("", {
              "font-bold": eachWeek.some((e) => dateIsToday(e)),
            })}
          >
            {"Week " + (i + 1)}
          </div>
          <span className="text-xs">
            <LocalDateTime date={listFirst(eachWeek)} /> -{" "}
            <LocalDateTime date={listLast(eachWeek)} />
          </span>
        </div>
      ),
    }));
  }

  React.useEffect(() => {
    LocalStorageServices.get("TRELLO_SECTION_TYPE").then((r) =>
      _setBasedOnType(r === null ? "state" : r)
    );
  }, []);

  React.useEffect(() => {
    if (_basedOnType === "state") {
      _statesContext.getAll(parentId);
    }
    // else if (_basedOnType === "type") {
    //   _typesContext.getAll(parentId);
    // } else if (_basedOnType === "tag") {
    //   _tagsContext.getAll(parentId);
    // } else if (_basedOnType === "ticket") {
    //   _ticketsContext.getAll(parentId);
    // }
    else if (_basedOnType === "user") {
      _usersContext.getAll(parentId);
    } else if (_basedOnType === "priority") {
      _prioritiesContext.getAll(parentId);
    }
  }, [_basedOnType, parentId]);

  const setBasedOnType = (value: keyof typeof TRELLO_SECTION_TYPES) => {
    _setBasedOnType(value);
    LocalStorageServices.set("TRELLO_SECTION_TYPE", value);
  };

  const onUpdate = async ({
    effortId,
    desColId,
    srcColId,
    isCloning,
  }: {
    effortId: number;
    desColId: string;
    srcColId: string;
    isCloning?: boolean;
  }) => {
    let _errors = {};
    const _effort = _effortsContext.efforts?.find((e) => e.id === effortId);

    let _newEffort = {};
    if (_basedOnType === "state") {
      const stateId = parseInt(desColId);
      _newEffort = {
        ..._effort,
        stateId,
        state: _statesContext.states?.find((e) => e.id === stateId),
      };
    } else if (_basedOnType === "effort") {
      const srcTypeId = parseInt(srcColId);
      const _effortToDelete = _mainContext.efforts?.find(
        (a) => a.id === srcTypeId
      );

      if (_effortToDelete) {
        _errors = await _effortsContext.removeMany([_effortToDelete]);
      }
    } else if (_basedOnType === "user") {
      /* else if (_basedOnType === "type") {
      const desTypeId = parseInt(desColId);
      const srcTypeId = parseInt(srcColId);
      let _new = _typesContext.types?.find((e) => e.id === desTypeId);
      const types = [
        ...(isCloning
          ? _effort!.types!
          : _effort!.types!.filter((e) => e.id !== srcTypeId)),
        ...(_new ? [_new] : []),
      ];
      _newEffort = {
        ..._effort,
        types,
        typeList: types.map((e) => e.id),
      };
    } else if (_basedOnType === "tag") {
      const desTagId = parseInt(desColId);
      const srcTagId = parseInt(srcColId);
      let _new = _tagsContext.tags?.find((e) => e.id === desTagId);
      const tags = [
        ...(isCloning
          ? _effort!.tags!
          : _effort!.tags!.filter((e) => e.id !== srcTagId)),
        ...(_new ? [_new] : []),
      ];
      _newEffort = {
        ..._effort,
        tags,
        tagList: tags.map((e) => e.id),
      };
    } */
      let _user = _usersContext.users?.find((e) => e.id === desColId);
      let _srcMember = _effort!.members!.find((e) => e.userId === srcColId)!;

      let createFormData = undefined;
      if (_user) {
        createFormData = {
          ..._srcMember,
          effortId,
          userId: _user.id,
          user: _user,
          isDeleted: false,
        };
      }

      let itemsToDelete = undefined;
      if (!isCloning) {
        itemsToDelete = [_srcMember];
      }

      _membersContext.createAndDelete({
        createFormData,
        itemsToDelete,
      });
    } /* else if (_basedOnType === "ticket") {
      const desTagId = parseInt(desColId);
      const srcTagId = parseInt(srcColId);
      let _new = _ticketsContext.tickets?.find((e) => e.id === desTagId);
      const ticketsAvailable = [
        ...(isCloning
          ? _effort!.ticketsAvailable!
          : _effort!.ticketsAvailable!.filter((e) => e.id !== srcTagId)),
        ...(_new ? [_new] : []),
      ];
      _newEffort = {
        ..._effort,
        ticketsAvailable,
        // ticketsAvailable: ticketsAvailable.map((e) => e.id),
      };
    } */ else if (_basedOnType === "priority") {
      const priorityId = parseInt(desColId);
      _newEffort = {
        ..._effort,
        priorityId,
        priority: _prioritiesContext.priorities?.find(
          (e) => e.id === priorityId
        ),
      };
    } else if (_basedOnType === "thisWeek") {
      _newEffort = {
        ..._effort,
        dateDue: desColId,
      };
    } else if (_basedOnType === "thisMonth") {
      _newEffort = {
        ..._effort,
        dateDue: desColId,
      };
    }

    if (!isEmpty(_newEffort)) {
      _errors = await _effortsContext.update(_newEffort);
    }

    if (!isEmpty(_errors)) {
      let _message = listFirst(listFirst(Object.values(_errors) as Dict[]));
      if (_message) {
        toast({
          variant: "destructive",
          description: _message,
        });
      }
    }

    return _errors;
  };

  const isColDisabled = (eachCol: ColType, draggingEffortId?: number) => {
    let _isDisabled: boolean | undefined = false;

    if (draggingEffortId) {
      const _effort = _mainContext.efforts?.find(
        (a) => a.id === draggingEffortId
      )!;

      if (!_effortAuthUtils.canEdit([_effort])) {
        _isDisabled = true;
      } else if (eachCol.effortIds?.some((a) => a === draggingEffortId)) {
        _isDisabled = true;
      } else {
        let _project = _effortUtils.getParentProject(draggingEffortId);
        const _isDraggingAProject = _project?.id === draggingEffortId;
        const _getAll = _isDraggingAProject && _currentUserContext.isSudo();

        if (_basedOnType === "state") {
          const _states = _getAll ? _statesContext.states : _project?.states;
          _isDisabled = !_states?.some((e) => e.id + "" === eachCol.id);
        } /* else if (_basedOnType === "type") {
          const _types = _getAll ? _typesContext.types : _project?.types;
          _isDisabled = !_types?.some((e) => e.id + "" === eachCol.id);
        } else if (_basedOnType === "tag") {
          const _tags = _getAll ? _tagsContext.tags : _project?.tags;
          _isDisabled = !_tags?.some((e) => e.id + "" === eachCol.id);
        } */ else if (_basedOnType === "user") {
          if (_getAll) {
            _isDisabled = !_usersContext.users?.some(
              (e) => e.id === eachCol.id
            );
          } else {
            _isDisabled = !_project?.members?.some(
              (e) => e.userId === eachCol.id
            );
          }
        } /* else if (_basedOnType === "ticket") {
          const _tickets = _getAll
            ? _ticketsContext.tickets
            : _project?.ticketsAvailable;
          _isDisabled = !_tickets?.some((e) => e.id + "" === eachCol.id);
        } */ else if (_basedOnType === "priority") {
          const _priorities = _getAll
            ? _prioritiesContext.priorities
            : _project?.priorities;
          _isDisabled = !_priorities?.some((e) => e.id + "" === eachCol.id);
        }
      }
    }

    return _isDisabled;
  };

  return (
    <div>
      {_basedOnType && (
        <>
          <div className="py-3 px-2">
            <Form
              data={{ basedOnType: _basedOnType }}
              onChange={(d) => {
                setBasedOnType(d.basedOnType);
              }}
            >
              <div className="flex items-center gap-2">
                <h6 className="mb-0 whitespace-nowrap text-foreground">
                  Based On:{" "}
                </h6>
                <div className="md:hidden">
                  <Select
                    name="basedOnType"
                    options={basedOnTypesOptions}
                    needLabel={false}
                    needMarginBottom={false}
                    required
                  />
                </div>
                <div className="hidden md:block">
                  <SelectButtonGroup
                    name="basedOnType"
                    options={basedOnTypesOptions}
                    needLabel={false}
                    needMarginBottom={false}
                    itemClassName="border-0"
                    required
                  />
                </div>
              </div>
            </Form>
          </div>

          <BoardSection
            cols={cols}
            onUpdate={onUpdate}
            isColDisabled={isColDisabled}
            basedOnType={TRELLO_SECTION_TYPES[_basedOnType]}
            colClick={(col) => navigate(_effortUtils.getUrl(parseInt(col.id))!)}
          />
        </>
      )}
    </div>
  );
}
