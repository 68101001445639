import CollapseText from "components/common/CollapseText";
import { useSideMenuContext } from "providers/SideMenuProvider";
import React, { useState } from "react";

import Link, { useLinkUtils } from "../common/Link";
import { useLocation } from "react-router";
import { cn, isFarsi } from "services/UtilServices";
import { LuMinus, LuMinusSquare, LuPlus, LuPlusSquare } from "react-icons/lu";
import { Button, buttonVariants } from "components/ui/button";
import { NetworkImage } from "components/common/NetworkImage";
import {
  FaCaretRight,
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa6";
import { Badge } from "components/ui/badge";

// export function CollapseItemButton({ onClick=()=>{}, children=<></> }) {
//   return (
//     <Button
//        variant="outline-light"
//         className=" border-0 w-full mb-1"
//         onClick={onClick}
//     >

//       { children }

//     </Button>
//   );
// }

export default function SideMenuCollapse({
  title,
  icon,
  isOpen = false,
  children,
  url,
  matchUrls,
  notifCount = 0,
  isProject,
  imgUrl,
}: {
  title?: string;
  icon?: React.ReactNode;
  isOpen?: boolean;
  children: React.ReactNode;
  url?: string;
  imgUrl?: string;
  matchUrls: string[];
  notifCount?: number;
  isProject?: boolean;
}) {
  const [_isOpen, _setIsOpen] = useState(isOpen);
  const [_isActive, _setIsActive] = useState(isOpen);
  const [_isMouseEntered, _setIsMouseEntered] = useState(false);

  const linkUtils = useLinkUtils();

  React.useEffect(() => {
    const isActive = matchUrls.some((e) => linkUtils.isMatch(e));
    _setIsActive(isActive);
    _setIsOpen(isActive);
  }, [location.pathname]);

  return (
    <div className="">
      <Link
        href={url}
        onMouseEnter={() => _setIsMouseEntered(true)}
        onMouseLeave={() => _setIsMouseEntered(false)}
        // activeClassName={
        //   "bg-primary text-primary-foreground hover:text-foreground"
        // }
        // deactiveClassName="text-primary-foreground hover:text-foreground"
        variant={"light"}
        activeVariant="primary"
        className={cn(" px-2 border-0 w-full mb-1 min-h-[50px]")}
      >
        {matchUrls.length > 1 && (
          <FaCaretRight
            className={cn("!text-base opacity-50 hover:opacity-100 ", {
              "rotate-90": _isOpen,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              _setIsOpen(!_isOpen);
            }}
          />
        )}

        {icon}

        {title && (
          <span
            title={title}
            className={cn("me-auto truncate inline-block ", {
              "direction-rtl": isFarsi(title),
            })}
          >
            {title}
          </span>
        )}

        {notifCount > 0 && <Badge>{notifCount}</Badge>}
      </Link>

      {_isOpen && React.Children.count(children) > 0 && (
        <div className="ms-4 border-s-2">{children}</div>
      )}
    </div>
  );
}
