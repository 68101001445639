import React from "react";
import { useLocation, useNavigate } from "react-router";

import Effort from "models/Effort";

import { UsersProvider } from "providers/UsersProvider";
import { TagsProvider } from "providers/TagsProvider";
import { TypesProvider } from "providers/TypesProvider";
import { StatesProvider } from "providers/StatesProvider";
import { TicketsProvider } from "providers/TicketsProvider";
import { PrefixsProvider } from "providers/PrefixsProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import { PrioritiesProvider } from "providers/PrioritiesProvider";
import { DepartmentsProvider } from "providers/DepartmentsProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";

import ExportModal from "./ExportModal";
import ViewTypeSelectButton, {
  useViewTypeSelectContext,
} from "./ViewTypeSelectButton";
import ItemsListCardSection from "./EffortsListGridSection";
import NewButton from "components/common/AdminSection/NewButton";
import EffortsListTrelloSection from "./EffortsListTrelloSection/EffortsListTrelloSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import FilterButton from "components/common/AdminSection/FilterButton";
import ItemsListTableSection from "components/common/ItemsListSection/ItemsListTableSection";
import EffortsListChartSection from "components/pages/EffortsSection/EffortsListChartSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import { MembersProvider } from "providers/MembersProvider";

import { LuCopy, LuDownload, LuPencil, LuTrash2 } from "react-icons/lu";
import { Button } from "components/ui/button";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import {
  useEffortViewUtils,
  useEffortAuthUtils,
  useEffortUtils,
} from "hooks/utils/UseEffortUtils";
import { useEffortMenuButton } from "../EachEffort/EffortMenuButton";
import { useDeleteButton } from "components/common/DeleteButton";
import { ActivitysProvider } from "providers/ActivitysProvider";

function EffortsListSectionBody({
  parentId = null,
}: {
  parentId?: number | null;
}) {
  const { data, onLoadMore, title, actionsSection, bottomActionSection } =
    useItemsListContext();

  const _viewTypeContext = useViewTypeSelectContext();

  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <div className="h-full">
      <ItemListSearchbarSection
        actionsSection={actionsSection}
        bottomActionSection={bottomActionSection}
        hideEllipseBreakPoint={
          _viewTypeContext.index === "board" ? "never" : "md"
        }
      />

      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : (
        <>
          {_viewTypeContext.index === "table" ? (
            <ItemsListTableSection />
          ) : _viewTypeContext.index === "grid" ? (
            <ItemsListCardSection />
          ) : _viewTypeContext.index === "board" ? (
            <EffortsListTrelloSection parentId={parentId} />
          ) : _viewTypeContext.index === "chart" ? (
            <EffortsListChartSection parentId={parentId} />
          ) : null}
        </>
      )}
    </div>
  );
}

function EffortsPageBody({ parentId = null }: { parentId?: number | null }) {
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const deleteButton = useDeleteButton();

  const _effortViewUtils = useEffortViewUtils(parentId);
  const _effortAuthUtils = useEffortAuthUtils();
  const _effortUtils = useEffortUtils();
  const effortMenuButton = useEffortMenuButton(parentId);

  const navigate = useNavigate();
  const mediaQuery = useMediaQuery();

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  return (
    <ItemsListProvider<Effort>
      title={_isHome ? "Projects" : _isProject ? "Tasks" : "Sub Tasks"}
      data={_effortsContext.efforts}
      btnTextResponsiveBreakPoint="never"
      initialSelectedSortFieldValue="dateAdd"
      fields={_effortViewUtils.getItemsListFields()}
      // onItemClick={(item) => () => navigate(item.url!)}
      selectedActionsSection={(items) => [
        {
          icon: <LuDownload />,
          text: "Export",
          onClick: () => {
            _modalContext.open(<ExportModal selectedItems={items} />);
          },
        },

        ...(_effortAuthUtils.canEdit(items)
          ? [
              {
                icon: <LuPencil />,
                text: "Change State",
                onClick: () => _effortViewUtils.openUpdateStateModal(items),
              },
            ]
          : []),

        // ...(_effortAuthUtils.canDelete(items)
        //   ? [
        //     {
        //       icon: <LuPencil />,
        //       text: "Change State",
        //       onClick: () => _effortViewUtils.openUpdateStateModal(items),
        //     },
        //   ]
        //   : []),
        ...(_effortAuthUtils.canDelete(items)
          ? [
              {
                icon: <LuTrash2 />,
                text: "Delete",
                onClick: () =>
                  deleteButton.tryRemove({
                    message: (
                      <>
                        You're about to delete these tasks{" "}
                        {items.map((e, i) => (
                          <span>
                            {i === 0 ? "" : ", "}"
                            <b className="capitalize">{e.title}</b>"
                          </span>
                        ))}
                        .
                      </>
                    ),
                    onConfirm: async () =>
                      await _effortViewUtils.onRemove(items),
                  }),
              },
            ]
          : []),
      ]}
      getItemActions={(item) => effortMenuButton.getItems(item)}
      actionsSection={
        <>
          {(_isHome || mediaQuery <= MediaQuery2Num.md) && (
            <ViewTypeSelectButton />
          )}

          {_effortsContext.efforts !== undefined &&
            _effortsContext.efforts?.length > 0 && (
              <Button
                variant={"light"}
                onClick={() => _modalContext.open(<ExportModal />)}
              >
                <LuDownload />
                <span className="hidden lg:inline ">Export</span>
              </Button>
            )}

          <FilterButton
            count={_effortsContext.efforts?.length}
            isActive={_effortUtils.isFilterActive({
              parentId,
              filter: _effortsContext.filter,
            })}
            onClick={_effortViewUtils.openFilterModal}
          />

          {_effortAuthUtils.canCreate(parentId) && (
            <NewButton
              onClick={() => _effortViewUtils.openCreateUpdateModal()}
            />
          )}
        </>
      }
    >
      <EffortsListSectionBody parentId={parentId} />
    </ItemsListProvider>
  );
}

export default function EffortsSection({
  parentId = null,
}: {
  parentId?: number | null;
}) {
  return (
    // <EffortsProvider parentId={parentId}>
    <ActivitysProvider>
      <TicketsProvider>
        <PrioritiesProvider>
          <PrefixsProvider>
            <DepartmentsProvider>
              <StatesProvider>
                <TagsProvider>
                  <TypesProvider>
                    <UsersProvider>
                      <MembersProvider>
                        <ModalProvider>
                          <EffortsPageBody parentId={parentId} />

                          <MobileFooterPadding />
                        </ModalProvider>
                      </MembersProvider>
                    </UsersProvider>
                  </TypesProvider>
                </TagsProvider>
              </StatesProvider>
            </DepartmentsProvider>
          </PrefixsProvider>
        </PrioritiesProvider>
      </TicketsProvider>
    </ActivitysProvider>
    // </EffortsProvider>
  );
}
