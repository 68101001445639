import React from "react";
import { useModalContext } from "../../providers/ModalProvider";
import { getItem, isEmpty, listFirst } from "services/UtilServices";
import Dict from "models/Dict";
import LoadingButton from "./LoadingButton";

import { useToast } from "components/ui/use-toast";
import { DialogContent } from "components/ui/dialog";
import { Button, ButtonProps } from "components/ui/button";
import { LuTrash2 } from "react-icons/lu";

export function useDeleteButton() {
  const _modalContext = useModalContext();

  const tryRemove = (props: DeleteWarningProps) => {
    _modalContext.open(<DeleteWarning {...props} />);
  };

  return {
    tryRemove,
  };
}

export default function DeleteButton({
  onConfirm,
  onDismiss = () => {},
  title = "Delete",
  message,
  deleteBtnText,
  variant = "light",
  children = <LuTrash2 />,
  ...rest
}: DeleteWarningProps & ButtonProps) {
  const deleteButton = useDeleteButton();

  const _onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    deleteButton.tryRemove({
      onConfirm,
      onDismiss,
      title,
      message,
      deleteBtnText,
    });
  };

  return (
    <Button variant={variant} {...rest} onClick={_onDelete}>
      {children}
    </Button>
  );
}

type DeleteWarningProps = {
  title?: string;
  message?: React.ReactNode;
  onConfirm: () => Promise<Dict | void> | void;
  onDismiss?: () => void;
  deleteBtnText?: React.ReactNode;
};

export function DeleteWarning({
  onConfirm,
  onDismiss,
  message = "You're about to delete an item.",
  title = "Delete",
  deleteBtnText = "Delete",
}: DeleteWarningProps) {
  const modalContext = useModalContext();

  const { toast } = useToast();

  const _onDismiss = () => {
    modalContext.dismiss();
    onDismiss?.();
  };

  const _onConfirm = async () => {
    let _error = await onConfirm?.();

    // if(isEmpty(_error))
    // {
    //   onConfirm?.();
    // }
    // else

    if (!isEmpty(_error)) {
      let _message = listFirst(listFirst(Object.values(_error!) as Dict[]));
      if (_message) {
        toast({
          variant: "destructive",
          description: _message,
        });
      }
    }

    modalContext.dismiss();
  };

  return (
    <DialogContent title={title} size="sm" fullScreen={false} onBgClickClosable>
      <span className="text-foreground">{message}</span>

      <span className="text-foreground"> Are you sure?!</span>

      <div className="flex mt-3">
        <LoadingButton
          onClick={_onConfirm}
          variant={"destructive"}
          className=" flex-1"
        >
          {deleteBtnText}
        </LoadingButton>

        <Button variant={"light"} onClick={_onDismiss} className="ms-2 flex-1">
          Cancel
        </Button>
      </div>
    </DialogContent>
  );
}
