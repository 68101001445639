import React from "react";

import MainLayout from "layouts/MainLayout";
import { MobileFooterPadding } from "components/layouts/MainFooter";

function StatsPageBody() {
  return (
    <div className="px-2 lg:px-3 ">
      <MobileFooterPadding />
    </div>
  );
}

function StatsPage() {
  return (
    <MainLayout title="آمار">
      <StatsPageBody />
    </MainLayout>
  );
}

export default StatsPage;
