import React from "react";

import Dict from "models/Dict";

import { isEmpty } from "services/UtilServices";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import AvatarSection from "./AvatarSection";
import SubmitButton from "components/common/Forms/SubmitButton";
import { Form, FormContext, Input } from "components/common/Forms/Form";
import { useMainContext } from "providers/MainProvider";
import User from "models/User";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function AccountEditModal({
  onSubmit,
}: {
  onSubmit: () => void;
}) {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();

  const _onSubmit = async (d: Dict) => {
    let _errors = await _currentUserContext.updateInfo(d);

    if (isEmpty(_errors)) {
      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          members: eachEffort.members?.map((e) =>
            e.userId !== d.id
              ? e
              : {
                ...e,
                user: d as User,
              }
          ),
        }))
      );

      onSubmit();
    }

    return _errors;
  };

  return (
    <DialogContent title={"Personal Info"} size={"sm"}>
      <Form data={_currentUserContext.user} onSubmit={_onSubmit}>
        <AvatarSection />

        <Input name="userName" required maxLength={256} />

        <Input name="firstName" maxLength={256} />
        <Input name="lastName" maxLength={256} />
        <DialogFooter>
          <SubmitButton>Update</SubmitButton>
        </DialogFooter>
      </Form>
    </DialogContent >
  );
}
