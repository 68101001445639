import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import User, { UserFilter, UserRoles, UserSortTypes } from "models/User";

import useUserApi from "hooks/api/UseUserApi";
import { listUnique, listFlatten } from "services/UtilServices";

import { MainContext } from "./MainProvider";
import { EffortsContext } from "./EffortsProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";
import useApiServices from "hooks/api/UseApiServices";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

interface UsersContextProps {
  users: User[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: UserFilter) => Promise<void>;
  getAll: (parentId?: number | null) => Promise<Dict | undefined>;
  filter: UserFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const UsersContext = React.createContext({} as UsersContextProps);
UsersContext.displayName = "UsersContext";

function UsersProvider({ children }: { children: React.ReactNode }) {
  const itemsRef = React.useRef<User[] | undefined>(undefined);
  const globalFilterRef = React.useRef<UserFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: UserSortTypes.ADDWHEN,
  });

  const userApi = useUserApi();
  const apiServices = useApiServices();
  const _effortUtils = useEffortUtils();

  const [_items, _setUsers] = React.useState<User[]>();
  const [_filter, _setFilter] = React.useState<UserFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();
  const { toast } = useToast();

  const _mainContext = React.useContext(MainContext);
  const _currentUserContext = React.useContext(CurrentUserContext);
  const _effortsContext = React.useContext(EffortsContext);

  React.useEffect(() => {
    setUsers(undefined);
    setFilter({
      pageNumber: -1,
      ascOrder: false,
      sortBy: UserSortTypes.dateadd,
    });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setUsers(undefined);
      setFilter({
        pageNumber: -1,
        ascOrder: false,
        sortBy: UserSortTypes.dateadd,
      });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  // React.useEffect(() => {
  //   setUsers(undefined);
  //   globalFilter = {
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: UserSortTypes.ADDWHEN,
  //   };
  //   _setFilter(globalFilter);
  //   return () => {
  //     setUsers(undefined);
  //     globalFilter = {
  //       pageNumber: -1,
  //       ascOrder: false,
  //       sortBy: UserSortTypes.ADDWHEN,
  //     };
  //     _setFilter(globalFilter);
  //   }
  // }, []);

  const setUsers = (o?: User[]) => {
    itemsRef.current = o;
    _setUsers(o);
  };

  const setFilter = (o: UserFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async (parentId?: number | null) => {
    let _effort = _effortUtils.getEffortById(
      _effortsContext?.currentEffort?.id
    );

    if (!parentId && !_effort?.parentId && _currentUserContext.isSudo()) {
      return await get({
        ..._filter,
        pageNumber: 0,
        numberInPage: 999,
      });
    }

    let _result = _effortsContext.currentEffort
      ? _effortUtils
          .getParentProject(_effortsContext.currentEffort.id)
          ?.members?.filter((e) => !e.isDeleted)
          .map((e) => e.user)
      : listUnique(
          listFlatten(
            _mainContext
              .efforts!.filter((e) => _effortUtils.isProject(e))
              .map(
                (e) =>
                  e.members?.filter((e) => !e.isDeleted).map((e) => e.user) ??
                  []
              )
          )
        );

    _result?.sort((a, b) => a.id.localeCompare(b.id));

    console.log("userrrrrrrrrrrrrrrrrrr", parentId, _result);

    setUsers(_result);
    return _result;
  };

  const get = async (filter: UserFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setUsers(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setUsers(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await userApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setUsers([...(itemsRef.current ?? []), ...(items as User[])]);
    } catch (e) {
      toast({
        description: (e as AxiosError).message,
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setUsers([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.avatarUrl = await apiServices.sendFile({
        file: formData.avatarUrl,
        // size: 256,
        fieldName: "avatarUrl",
      });

      if (formData.email === "") {
        formData.email = null;
      }
      if (formData.officeId === "") {
        formData.officeId = null;
      }
      if (formData.phone === "") {
        formData.phone = null;
      }

      let { id, dateAdd } = await userApi.create(formData);
      formData.id = id;
      formData.createdOn = new Date();
      formData.dateAdd = dateAdd;

      setUsers([formData as User, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      // if(formData.avatarUrl !== null && typeof formData.avatarUrl !== "string") {
      formData.avatarUrl = await apiServices.sendFile({
        file: formData.avatarUrl,
        // size: 256,
        fieldName: "avatarUrl",
      });
      // }

      if (formData.email === "") {
        formData.email = null;
      }

      if (formData.phone === "") {
        formData.phone = null;
      }

      await userApi.update(formData);

      setUsers(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as User)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          members: eachEffort.members?.map((e) =>
            e.userId !== formData.id
              ? e
              : {
                  ...e,
                  user: formData as User,
                }
          ),
        }))
      );

      if (formData.id === _currentUserContext.user!.id) {
        _currentUserContext.set({
          ..._currentUserContext.user,
          ...formData,
        });

        if (formData.role === UserRoles.USER) {
          // navigate("/");
          _currentUserContext.logout();
        }
      }
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: User[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await userApi.remove(ids);

      setUsers(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <UsersContext.Provider
      value={
        {
          users: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as UsersContextProps
      }
    >
      {children}
    </UsersContext.Provider>
  );
}

export function useUsersContext() {
  const _context = React.useContext(UsersContext);

  if (!_context) {
    throw new Error("cannot use UsersContext outside of its provider.");
  }

  return _context;
}

export { UsersContext, UsersProvider };
export type { UsersContextProps };
