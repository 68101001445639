import Truncate from "components/common/Truncate";
import Member, { MemberAccessTypes } from "models/Member";
import MemberAvatar from "./MemberAvatar";
import { cn } from "services/UtilServices";
import { Button } from "components/ui/button";
import { LuBell, LuUserPlus } from "react-icons/lu";
import Effort from "models/Effort";
import useMemberUtils from "hooks/utils/UseMemberUtils";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import MenuButton from "components/ui/menu-button";
import useUserUtils from "hooks/utils/UseUserUtils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";

function PopoverEachItem({
  effort,
  member,
  onEdit,
  onDelete,
}: {
  effort: Effort;
  member: Member;
  onDelete?: () => void;
  onEdit?: () => void;
}) {
  const userUtils = useUserUtils();
  const Icon = MemberAccessTypes.icons[member.accessType];

  return (
    <CommandItem className="flex items-center py-2 mb-1 border-b last:border-b-0">
      <MemberAvatar member={member} size={35} needHover={false} />

      <span className="ms-2 capitalize whitespace-nowrap text-sm">
        {userUtils.getFullName(member.user)}
        <div className="text-xs text-muted-foreground font-normal ">
          <Icon className="!text-sm" />{" "}
          {MemberAccessTypes.options[member.accessType]} Access
        </div>
      </span>

      <div className="flex-1"></div>
      <PopoverClose>
        <MenuButton
          stopPropagation={false}
          onEdit={onEdit}
          onDelete={onDelete}
          deleteMessage={
            <>
              You're about to remove "
              <b className="capitalize">{userUtils.getFullName(member.user)}</b>
              " from "<b className="capitalize">{effort.title}</b>".
            </>
          }
          btnTextResponsiveBreakPoint={"never"}
          ellipsisResponsiveBreakPoint={"never"}
          ellipsisClassName="p-0 rounded-full"
        />
      </PopoverClose>
    </CommandItem>
  );
}

export default function MiniMembersSection({
  effort,
  className = "",
}: {
  effort?: Effort;
  className?: string;
}) {
  const _memberUtils = useMemberUtils(effort);

  return (
    <div className="inline-flex" onClick={(e) => e.stopPropagation()}>
      <Popover>
        <PopoverTrigger
          className="cursor-pointer"
          onClick={(ev) => ev.stopPropagation()}
        >
          <Truncate maxCount={3} className={cn("justify-center ", className)}>
            {effort?.members
              ?.filter((e) => !e.isDeleted)
              .map((eachMember, i) => (
                <MemberAvatar key={"eachMember" + i} member={eachMember} />
              ))}
          </Truncate>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          // className="overflow-y-auto !w-auto"
          // style={{ maxHeight: 300 }}
          title={
            <>
              <b>Members</b>{" "}
              {_memberUtils.canCreate() && (
                <PopoverClose asChild>
                  <Button
                    variant={"faded"}
                    title="Add New Member"
                    className=""
                    onClick={(ev) => {
                      _memberUtils.openCreateUpdateModal();
                    }}
                  >
                    <LuUserPlus />
                  </Button>
                </PopoverClose>
              )}
            </>
          }
        >
          <Command>
            <CommandInput />
            <CommandEmpty>Nothing found.</CommandEmpty>
            <CommandGroup className="max-h-[300px] overflow-y-auto">
              {effort?.members?.map((eachMember) => (
                <PopoverEachItem
                  key={"eachmemb" + eachMember.id}
                  effort={effort}
                  member={eachMember}
                  onEdit={
                    _memberUtils.canEdit(eachMember)
                      ? () => _memberUtils.openCreateUpdateModal(eachMember)
                      : undefined
                  }
                  onDelete={
                    _memberUtils.canDelete([eachMember])
                      ? () => _memberUtils.removeMany([eachMember])
                      : undefined
                  }
                />
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      {/* {_memberUtils.canCreate() && (
        <Button
          variant={"faded"}
          title="Add New Member"
          className="rounded-full aspect-square border ms-1"
          onClick={(ev) => {
            ev.stopPropagation();
            _memberUtils.openCreateUpdateModal();
          }}
        >
          <LuUserPlus />
        </Button>
      )} */}
    </div>
  );
}
