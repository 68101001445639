import React from "react";

import Dict from "models/Dict";
import Ticket from "models/Ticket";

import { useThemeContext } from "providers/ThemeProvider";

import { useFormContext, Validation } from "components/common/Forms/Form";
import InputStandalone from "components/common/Forms/InputStandalone";
import FormFieldBase from "components/common/Forms/FormFieldBase";
import TicketCount from "models/TicketCount";
import { cn, isBright } from "services/UtilServices";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { Button } from "components/ui/button";
import { ValidationType } from "components/common/Forms/UseValidation";
import { LuMinus, LuPlus } from "react-icons/lu";

function EachTicketItem({
  name,
  ticket,
  disabled,
  ticketCountFieldName,
}: {
  name: string;
  ticket: Ticket;
  disabled?: boolean;
  ticketCountFieldName: "count" | "countApproved";
}) {
  const _formContext = useFormContext();

  const _oldTicketCount = _formContext.data[name]?.find(
    (e: TicketCount) => e.ticketId === ticket.id
  );
  const value = _oldTicketCount ? _oldTicketCount[ticketCountFieldName] : 0;

  const _ticketColor = ticket.color + "aa";
  const _textColor = isBright(_ticketColor) ? "" : " text-light";

  const onSet = (v: number) => {
    if (_oldTicketCount) {
      _formContext.setData({
        [name]: _formContext.data[name].map((e: TicketCount) =>
          e.ticketId !== ticket.id
            ? e
            : {
                ...e,
                [ticketCountFieldName]: v,
              }
        ),
      });
    } else {
      _formContext.setData({
        [name]: [
          ...(_formContext.data[name] ?? []),
          {
            ticketId: ticket.id,
            [ticketCountFieldName]: v,
          },
        ],
      });
    }
  };

  return (
    <div className="flex items-center">
      <div
        className={cn("flex items-center rounded border ")}
        style={{
          background: _ticketColor,
        }}
      >
        <Button
          className={cn("rounded-s rounded-none ", _textColor)}
          onClick={() => onSet(value - 1)}
          disabled={value === 0 || disabled}
        >
          <LuMinus />
        </Button>

        <div className="px-2 d-fle">
          <InputStandalone
            value={value}
            type="number"
            onChange={(v) => onSet(parseInt(v as string))}
            className={cn(
              "border-0 inline text-center bg-transparent w-7 ",
              _textColor
            )}
          />
        </div>

        <Button
          className={cn("rounded-e rounded-none ", _textColor)}
          onClick={() => onSet(value + 1)}
          disabled={disabled}
        >
          <LuPlus />
        </Button>
      </div>

      <div>
        <b className="ms-3 uppercase text-foreground">{ticket.name}</b>
      </div>
    </div>
  );
}

export const ticketIsRequired = {
  type: "ticketIsRequired",
  text: "This field is required.",
  isValid: (value?: Dict) =>
    value !== undefined &&
    Object.values(value).some((e) => e !== 0) &&
    Object.values(value).every((e) => !Number.isNaN(e)),
} as ValidationType;

export default function TicketsCountInputField(props: {
  name: string;
  tickets: Ticket[] | undefined;
  ticketCountFieldName?: "count" | "countApproved";
  label: string;
  validations?: ValidationType[];
  required?: boolean;
  disabled?: boolean;
}) {
  return (
    <FormFieldBase {...props}>
      {(baseProps) => (
        <div className="flex flex-col items-start gap-2">
          {props.tickets?.map((e) => (
            <EachTicketItem
              key={"eachTicketItem" + e.id}
              ticket={e}
              name={props.name}
              disabled={props.disabled || baseProps.isLoading}
              ticketCountFieldName={props.ticketCountFieldName ?? "count"}
            />
          ))}
        </div>
      )}
    </FormFieldBase>
  );
}
