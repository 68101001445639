import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils"
import { MemberAccessTypes } from "models/Member";
import User, { UserRoles } from "models/User";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useMainContext } from "providers/MainProvider";



export default function useAuthUtils() {
    const _effortUtils = useEffortUtils();
    const _currentUseContext = useCurrentUserContext();

    const authorize = (parentId?: number | null) => {
        let _accessType = -1;

        if (_currentUseContext.user?.role !== UserRoles.USER) {
            _accessType = MemberAccessTypes.Delete;
        }
        else if (parentId !== null) {
            let _parentEffort = _effortUtils.getEffortById(parentId);
            if (_parentEffort !== undefined) {
                let _parents = _effortUtils.getParentEffortsDeep(parentId!);
                _parents = [_parentEffort, ..._parents];

                if (_parents.every(e => e.isActive)) {
                    let _member = _parents.find(e =>
                        e.members?.some(a => a.userId === _currentUseContext.user?.id)
                    )!.members?.find(a => a.userId === _currentUseContext.user?.id);
                    // console.log("_parentssssssssssssss", _parents, _member)
                    if (_member) {
                        _accessType = _member.accessType;
                    }
                }

                else {
                    _accessType = MemberAccessTypes.Read;
                }
            }
        }

        return _accessType;
    }

    return {
        authorize
    }
}