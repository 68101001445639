import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router";

import { TabContents, TabItems } from "components/common/Tabs";
import { useCurrentUserContext } from "providers/CurrentUserProvider";

import MainLayout from "layouts/MainLayout";
import UsersSection from "components/pages/admin/UsersSection/UsersSection";
import DepartmentsSection from "components/pages/admin/DepartmentsSection/DepartmentsSection";
import OfficesSection from "components/pages/admin/OfficesSection/OfficesSection";
import StatesSection from "components/pages/admin/StatesSection/StatesSection";
import TagsSection from "components/pages/admin/TagsSection/TagsSection";
import TypesSection from "components/pages/admin/TypesSection/TypesSection";
import PrefixsSection from "components/pages/admin/PrefixsSection/PrefixsSection";
import PrioritiesSection from "components/pages/admin/PrioritiesSection/PrioritiesSection";
import TicketsSection from "components/pages/admin/TicketsSection/TicketsSection";
import AuditLogSection from "components/pages/AuditLogSection/AuditLogSection";
import { UserRoles } from "models/User";

const AdminPage = () => {
  const _currentUserContext = useCurrentUserContext();
  const params = useParams();
  const navigate = useNavigate();

  const _isAdmin = _currentUserContext.user?.role === UserRoles.ADMIN;

  const SECTIONS = [
    ...(_isAdmin
      ? [
          {
            url: "",
            exact: true,
            text: "Users",
            content: <UsersSection />,
          },
        ]
      : []),

    {
      url: _isAdmin ? "offices" : "",
      exact: !_isAdmin,
      text: "Offices",
      content: <OfficesSection />,
    },
    {
      url: "departments",
      text: "Departments",
      content: <DepartmentsSection />,
    },
    {
      url: "tags",
      text: "Tags",
      content: <TagsSection />,
    },
    {
      url: "types",
      text: "Types",
      content: <TypesSection />,
    },
    {
      url: "states",
      text: "States",
      content: <StatesSection />,
    },
    {
      url: "prefixes",
      text: "Prefixes",
      content: <PrefixsSection />,
    },
    {
      url: "priorities",
      text: "Priorities",
      content: <PrioritiesSection />,
    },
    {
      url: "tickets",
      text: "Tickets",
      content: <TicketsSection />,
    },
    {
      url: "auditLogs",
      text: "Audit Logs",
      content: <AuditLogSection pageType="admin" />,
    },
  ];

  if (!_currentUserContext.isSudo()) {
    navigate("/404");
    return null;
  }

  return (
    <MainLayout title="AdminPage">
      <div className="h-full ">
        <TabItems baseUrl="/admin/" items={SECTIONS} />

        <div className="h-full">
          <TabContents activeUrl={params.index} items={SECTIONS} />
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminPage;
