
import Dict from 'models/Dict';
import Effort, { EffortFilter, Priorities } from 'models/Effort';
import Priority from 'models/Priority';
import Ticket from 'models/Ticket';

import { getNotEmptyFields, getOnlyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import TicketCount from 'models/TicketCount';
import { useEffortUtils } from 'hooks/utils/UseEffortUtils';



export const BASE_URL = "efforts/";

export default function useEffortApi() {

  const apiServices = useApiServices();
  const _effortUtils = useEffortUtils();

  const get = async (filter: EffortFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      body: {
        ...getNotEmptyFields(filter),
        // includes: false,
      },
      expirationDurationMinutes: 2,
    });

    return response.result?.map((e: Dict) => _effortUtils.serverResponse2Effort(e)) ?? [];
  }

  const getById = async (id: number) => {
    let response = await apiServices.send({
      url: BASE_URL + "" + id,
      method: "get",
      expirationDurationMinutes: 2,
    });

    return _effortUtils.serverResponse2Effort(response);
  }



  const create = async (effort: Dict) => {
    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      body: getNotEmptyFields({
        ...getOnlyFields(effort, [
          "id",
          "parentId",
          "stateId",
          "priorityId",
          "departmentId",
          "prefixId",
          "title",
          // "description", 
          "level",
          "timeEstimate",
          "timeMax",
          "timeApproved",
          "timeActual",
          "dateStart",
          "dateDue",
          "dateEnd",
          "weight",
          "qualityFactor",
          // "avatarUrl", 
          "isActive",
          "prefixList",
          "tagList",
          "typeList",
          "stateList",
          // "priorities", 
        ]),

        priorities: effort.priorities.map((e: Priority) => e.id),
        ticketsAvailable: _effortUtils.isProject(effort) ? effort.ticketsAvailable.map((e: Ticket) => e.id) : null,
        ticketsCount: _effortUtils.isProject(effort) ? null :
          effort.ticketsCount?.map((e: TicketCount) => ({
            ticketId: e.ticketId,
            count: e.count,
          })),

      })
    });

    return response;
  }


  const update = async (effort: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      body: getNotEmptyFields({
        ...getOnlyFields(effort, [
          "id",
          "parentId",
          "stateId",
          "priorityId",
          "departmentId",
          "prefixId",
          "title",
          "description",
          "level",
          "timeEstimate",
          "timeMax",
          "timeApproved",
          "timeActual",
          "dateStart",
          "dateDue",
          "dateEnd",
          "weight",
          "qualityFactor",
          "avatarUrl",
          "isActive",
          "prefixList",
          "tagList",
          "typeList",
          "stateList",
          // "priorities", 

        ]),

        priorities: effort.priorities.map((e: Priority) => e.id),
        prefixId: effort.prefixId === "" ? null : effort.prefixId,
        ticketsAvailable: _effortUtils.isProject(effort) ? effort.ticketsAvailable.map((e: Ticket) => e.id) : null,
        ticketsCount: _effortUtils.isProject(effort) ? null :
          effort.ticketsCount?.map((e: TicketCount) => ({
            ticketId: e.ticketId,
            count: e.count,
          })),
        description: JSON.stringify(effort.description),
      })
    });

    return response;
  }

  const updateState = async (data: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/state",
      method: "post",
      body: getOnlyFields(data, ["effortIds", "stateId"])
    });

    return response;
  }


  const remove = async (ids: number[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      body: { ids }
    });
  }

  return {
    get,
    getById,
    create,
    update,
    updateState,
    remove,
  };
}

