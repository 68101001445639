import { Clipboard } from "@capacitor/clipboard";
import { useToast } from "components/ui/use-toast";

export default function useClipboard() {
  const { toast } = useToast();

  const write = ({
    content,
    messageSuccess = "Link Copied",
    messageError = "Not Copied!",
  }: {
    content: string;
    messageSuccess?: string;
    messageError?: string;
  }) => {
    try {
      Clipboard.write({ string: content })
        .then(() => {
          toast({
            description: messageSuccess,
          });
        })
        .catch(() => {
          toast({
            description: messageError,
            variant: "destructive",
          });
        });
    } catch (e) {
      toast({
        description: messageError,
        variant: "destructive",
      });
    }
  };

  return {
    write,
  };
}
