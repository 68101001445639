import React, { useState } from "react";
import { Navigate, createSearchParams, useNavigate } from "react-router-dom";

import Dict from "models/Dict";

import CurrentUserServices from "services/localServices/CurrentUserLocalServices";

import {
  Form,
  Input,
  SubmitButton,
  useFormContext,
} from "components/common/Forms/Form";
import { AxiosError } from "axios";
import useCurrentUserApi from "hooks/api/UseCurrentUserApi";
import LoginLayout from "layouts/LoginLayout";
import LoginSection from "components/pages/login/LoginSection";
import { Button } from "components/ui/button";
import { LuArrowLeft, LuPencil } from "react-icons/lu";
import Link from "components/common/Link";
import { useToast } from "components/ui/use-toast";

function ChangeEmailButtn({ onClick }: { onClick: () => void }) {
  const _formContext = useFormContext();

  return (
    <Button
      variant={"faded"}
      onClick={() => {
        onClick();
        _formContext.removeData(["otp", "password", "confirmPassword"]);
      }}
    >
      <LuPencil className="!text-base" /> Change Email
    </Button>
  );
}

function ForgotPasswordPage() {
  const currentUserApi = useCurrentUserApi();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [state, setState] = React.useState<"EMAIL" | "OTP" | "PASSWORD">(
    "EMAIL"
  );

  React.useEffect(() => {
    CurrentUserServices.isLoggedIn().then((result) => {
      if (result) {
        navigate("/", { replace: true });
      }
    });
  }, []);

  const onEmailSubmit = async (d: Dict) => {
    let _errors = {};

    try {
      await currentUserApi.sendForgotPasswordOTP(d.email);

      setState("OTP");
    } catch (e) {
      console.log(e);
      _errors = e as AxiosError;
    }

    return _errors;
  };

  const onOtpSubmit = async (d: Dict) => {
    let _errors = {};

    try {
      await currentUserApi.validatePasswordOTP(d);

      setState("PASSWORD");
    } catch (e) {
      console.log(e);
      _errors = e as AxiosError;
    }

    return _errors;
  };

  const onPasswordSubmit = async (d: Dict) => {
    let _errors = {};
    console.log(d);

    try {
      if (d.password === d.confirmPassword) {
        await currentUserApi.resetPassword(d);

        toast({
          description: "Your password successfully changed!",
        });
        navigate("/", { replace: true });
      } else {
        _errors = {
          confirmPassword: "must be equal to password",
        };
      }
    } catch (e) {
      console.log(e);
      _errors = e as AxiosError;
    }

    return _errors;
  };

  return (
    <LoginLayout>
      <LoginSection className="border border-foreground/40" back>
        <div className="card-body p-4">
          <h4 className="card-title text-center md:mb-4 mt-3">
            Forgot Password
          </h4>

          <div
            style={{ maxWidth: 350 }}
            className="row m-0 transition-all duration-100 "
          >
            <div
              className={
                "col-12 pt-3 px-0 md:px-3 transition-all duration-100 "
              }
            >
              <div className="mb-4">
                <p className="text-sm ">
                  {state === "EMAIL"
                    ? "Please enter your email address to recieve a code to reset password!."
                    : state === "OTP"
                    ? "Please enter the verification code that was sent to your email."
                    : "Please enter your new password and confirm it."}
                </p>
              </div>

              <Form
                onSubmit={
                  state === "EMAIL"
                    ? onEmailSubmit
                    : state === "OTP"
                    ? onOtpSubmit
                    : onPasswordSubmit
                }
              >
                <Input
                  name="email"
                  required
                  readOnly={state !== "EMAIL"}
                  actions={
                    state === "EMAIL"
                      ? undefined
                      : [
                          <ChangeEmailButtn
                            onClick={() => {
                              setState("EMAIL");
                            }}
                          />,
                        ]
                  }
                />

                {state === "OTP" && (
                  <Input
                    name="otp"
                    label="Verification Code"
                    required
                    maxLength="5"
                  />
                )}

                {state === "PASSWORD" && (
                  <>
                    <Input
                      name="password"
                      label="password"
                      type={"password"}
                      required
                      showPasswordBtn
                    />
                    <Input
                      name="confirmPassword"
                      label="Confirm password"
                      type={"password"}
                      required
                      showPasswordBtn
                    />
                  </>
                )}

                <SubmitButton className="w-1/2">Send</SubmitButton>
              </Form>
              <div className="flex justify-center mt-2">
                <Link variant="faded" to="/login">
                  <LuArrowLeft /> back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LoginSection>
    </LoginLayout>
  );
}

export default ForgotPasswordPage;
