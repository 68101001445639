import Dict from "models/Dict";
import Effort from "models/Effort";
import { useDepartmentsContext } from "providers/DepartmentsProvider";
import { usePrefixsContext } from "providers/PrefixsProvider";
import { useStatesContext } from "providers/StatesProvider";
import { useTagsContext } from "providers/TagsProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";
import React from "react";
import { cn, isEmpty, strCapitalizefirstLetter } from "services/UtilServices";
import EffortSingleFieldUpdateModal, {
  EffortSingleFieldUpdateModalFieldNameType,
} from "./EffortSingleFieldUpdateModal";
import { useModalContext } from "providers/ModalProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import { useTicketsContext } from "providers/TicketsProvider";
import { LuPencil } from "react-icons/lu";
import { Button, buttonVariants } from "components/ui/button";
import { VariantProps } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";
import TableFillCell from "./TableFillCell";

export function SingleFieldUpdatePen({
  effort,
  fieldName,
  className = "",
  variant = "faded",
  size,
}: {
  effort: Effort;
  fieldName: EffortSingleFieldUpdateModalFieldNameType;
  className?: string;
} & VariantProps<typeof buttonVariants>) {
  const singleFieldEdit = useSingleFieldUpdate();

  return (
    <Button
      asChild
      variant={variant}
      size={size}
      title={"Edit " + fieldName}
      onClick={(ev) =>
        singleFieldEdit.onClick({
          effort,
          fieldName,
          ev,
        })
      }
      className={cn(
        "aspect-square p-0 md:invisible group-hover:visible opacity-50 h-7 absolute left-0 top-0 ",
        className
      )}
    >
      <div>
        <LuPencil className="!text-base" />
      </div>
    </Button>
  );
}

export function SingleFieldUpdateFillCell({
  effort,
  fieldName,
  children,
  color,
  className,
  disabled,
}: {
  effort: Effort;
  fieldName: EffortSingleFieldUpdateModalFieldNameType;
  children: React.ReactNode;
  color?: string;
  className?: string;
  disabled?: boolean;
}) {
  const singleFieldEdit = useSingleFieldUpdate();

  return (
    <div
      onClick={
        disabled
          ? undefined
          : (ev) =>
              singleFieldEdit.onClick({
                effort,
                fieldName,
                ev,
              })
      }
    >
      <TableFillCell color={color} className={className}>
        {children}
      </TableFillCell>
      {/* <LuPencil className="!text-base md:invisible group-hover:visible absolute opacity-50 hover:opacity-100 left-1 top-1 " /> */}
    </div>
  );
}

export default function useSingleFieldUpdate() {
  const _effortsContext = useEffortsContext();
  const _modalContext = useModalContext();
  const _prefixsContext = usePrefixsContext();
  const _departmentsContext = useDepartmentsContext();
  const _statesContext = useStatesContext();
  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _prioritiesContext = usePrioritiesContext();
  const _ticketsContext = useTicketsContext();

  const onSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.update(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openSingleFieldUpdateModal = (
    fieldName: EffortSingleFieldUpdateModalFieldNameType,
    effort: Effort
  ) => {
    if (fieldName === "prefix" || fieldName === "prefixes") {
      _prefixsContext.getAll(effort.parentId);
    } else if (fieldName === "department") {
      _departmentsContext.getAll(effort.parentId);
    } else if (fieldName === "state" || fieldName === "states") {
      _statesContext.getAll(effort.parentId);
    } else if (fieldName === "tags") {
      _tagsContext.getAll(effort.parentId);
    } else if (fieldName === "types") {
      _typesContext.getAll(effort.parentId);
    } else if (fieldName === "priority" || fieldName === "priorities") {
      _prioritiesContext.getAll(effort.parentId);
    } else if (
      fieldName === "tickets Count" ||
      fieldName === "Available tickets"
    ) {
      _ticketsContext.getAll(effort.parentId);
    }

    _modalContext.open(
      <EffortSingleFieldUpdateModal
        effort={effort}
        onSubmit={onSubmit}
        fieldName={fieldName}
      />
    );
  };

  const onClick = ({
    fieldName,
    effort,
    ev,
  }: {
    fieldName: EffortSingleFieldUpdateModalFieldNameType;
    effort: Effort;
    ev?: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    ev?.stopPropagation();
    console.log("ehsan");
    openSingleFieldUpdateModal(fieldName, effort);
  };

  return {
    onClick,
  };
}
