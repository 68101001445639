import React from "react";

import LoadingSpinner from "./LoadingSpinner";
import useOnScreen from "./UseOnScreen";

var timerId: undefined | NodeJS.Timeout = undefined;
var isLoadingSpninnerVisible = true;
var _onLoadMore = () => {};
var hasResponded = true;

export default function OnLoadMoreIndicator({
  children,
  onLoadMore,
  rest,
}: {
  children?: React.ReactNode;
  onLoadMore: () => Promise<void> | void;
  [key: string]: any;
}) {
  _onLoadMore = onLoadMore;
  const loadingElement = React.useRef<HTMLDivElement>(null);
  isLoadingSpninnerVisible = useOnScreen(loadingElement);

  var myFnc = async function () {
    if (hasResponded && timerId !== undefined) {
      if (isLoadingSpninnerVisible) {
        hasResponded = false;
        await _onLoadMore();
        hasResponded = true;
      } else {
        clearInterval(timerId);
        timerId = undefined;
      }
    }
  };

  if (isLoadingSpninnerVisible && timerId === undefined) {
    timerId = setInterval(myFnc, 2000);
    myFnc();
  }

  React.useEffect(() => {
    // timerId = undefined;

    return () => {
      clearInterval(timerId);
      timerId = undefined;
    };
  }, []);

  return (
    <div ref={loadingElement} {...rest} className="min-h-12 min-w-12 ">
      {children ?? <LoadingSpinner />}
    </div>
  );
}
