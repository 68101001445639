import React from "react";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { useMainContext } from "providers/MainProvider";
import { useModalContext } from "providers/ModalProvider";
import { useStatesContext } from "providers/StatesProvider";

import { Form, Select, SubmitButton } from "components/common/Forms/Form";
import { objList2Options } from "services/UtilServices";
import { useToast } from "components/ui/use-toast";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function EffortStateUpdateModal({
  effortIds,
  onSubmit,
  parentId = null,
}: {
  effortIds: number[];
  onSubmit: (data: Dict) => Promise<Dict>;
  parentId?: number | null;
}) {
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _statesContext = useStatesContext();

  const { toast } = useToast();

  let _states = _statesContext.states;

  let _errorContent = undefined;
  if (_states?.length === 0) {
    _errorContent = `You need to add a State ${parentId === null ? "" : "to your project"
      } before you can create tasks!`;
  }

  if (_errorContent) {
    _modalContext.dismiss();
    toast({
      variant: "destructive",
      description: _errorContent,
    });
    return null;
  }

  return (
    <DialogContent title={"Change State"} size={"sm"} fullScreen={false}>
      <Form
        data={{ effortIds }}
        onSubmit={async (d) => {
          d.states = _states?.filter((e) => d.stateList?.includes(e.id)) ?? [];
          d.state = _states?.find((e) => e.id === d.stateId);

          return await onSubmit(d);
        }}
      >
        <Select
          name="stateId"
          label="State"
          required
          options={objList2Options(_states)}
        />
        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent >
  );
}
