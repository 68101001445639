
import Activity, { ActivityFilter } from 'models/Activity';
import Dict from 'models/Dict';
import useApiServices from './UseApiServices';
import { dateAdd, getNotEmptyFields, getOnlyFields } from 'services/UtilServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';

export const BASE_URL = "activities/";

export default function useActivityApi() {

  const apiServices = useApiServices();

  const get = async (filter: ActivityFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        dateAddTo: dateAdd({ date: filter.dateAddTo, days: 1 }),
        dateDoneTo: dateAdd({ date: filter.dateDoneTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result?.map((eachActivity: Activity) => ({
        ...eachActivity,
        ticketsCount: eachActivity.ticketsCount
          .map(e => ({
            ...e,
            ticketId: e.ticket.id
          }))
      })) ?? []
    };
  }


  const create = async (activity: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getNotEmptyFields(
        {
          ...getOnlyFields(activity, [
            "userId",
            "effortId",
            "typeId",
            "dateDone",
            "description",
            { "ticketsCount": ["ticketId", "count", "countApproved"] },
            "duration",
            "isApproved",
          ]),
        }
      )
    });

    return response.id;
  }


  const update = async (activity: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getNotEmptyFields(getOnlyFields(activity, [
        "id",
        "userId",
        "effortId",
        "typeId",
        "dateDone",
        "description",
        { "ticketsCount": ["ticketId", "count", "countApproved"] },
        "duration",
        "isApproved",
      ]))
    });

    return response;
  }


  const remove = async (ids: string[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }

  return {
    get,
    create,
    update,
    remove,
  };
}

