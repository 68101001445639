import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import React, { useRef, useContext } from "react";

import { FaCamera } from "react-icons/fa6";

interface Props {
  children?: React.ReactNode;
  label?: string;
  maxCount?: number;
  multiple?: boolean;
  onChange?: (files: File[] | File) => void;
  wrapperClassName?: string;
  [rest: string]: any;
}

const SelectFileButtonStandalone = ({
  children,
  multiple = false,
  maxCount = 10,
  onChange = (files: File[] | File) => {},
  wrapperClassName,
  ...rest
}: Props) => {
  const upload = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      onChange(
        multiple ? Array.from(event.target.files) : event.target.files[0]
      );
    }

    event.target.value = "";
  };

  return (
    <div className={wrapperClassName}>
      <Button
        size="sm"
        variant={"light"}
        onClick={() => {
          upload.current.click();
        }}
        className=" ms-2 border"
        {...rest}
      >
        {children ?? <FaCamera />}
      </Button>

      <Input
        id="myInput"
        type="file"
        ref={upload}
        className="hidden"
        multiple={multiple}
        onChange={onChangeFile}
      />
    </div>
  );
};

export default SelectFileButtonStandalone;
