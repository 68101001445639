import React from "react";

import Tag from "models/Tag";
import Type from "models/Type";
import Dict from "models/Dict";
import State from "models/State";
import Prefix from "models/Prefix";
import Ticket from "models/Ticket";
import Priority from "models/Priority";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import TicketCount from "models/TicketCount";

import {
  dateGetOnlyDate,
  dateGetToday,
  getItem,
  isEmpty,
  listFirst,
  objList2Options,
} from "services/UtilServices";

import { useTicketsContext } from "providers/TicketsProvider";
import { useTagsContext } from "providers/TagsProvider";
import { useMainContext } from "providers/MainProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useModalContext } from "providers/ModalProvider";
import { useStatesContext } from "providers/StatesProvider";
import { usePrefixsContext } from "providers/PrefixsProvider";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import { useDepartmentsContext } from "providers/DepartmentsProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";

import TextEditor from "components/common/Forms/TextEditor";
import TicketsCountInputField from "./TicketsCountInputField";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useToast } from "components/ui/use-toast";
import { Separator } from "components/ui/separator";
import useValidation from "components/common/Forms/UseValidation";

export default function EffortCreateUpdateModal({
  title,
  formData,
  onSubmit,
  parentId,
}: {
  title?: string;
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
  parentId?: number | null;
}) {
  const _tagsContext = useTagsContext();
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _typesContext = useTypesContext();
  const _statesContext = useStatesContext();
  const _prefixsContext = usePrefixsContext();
  const _ticketsContext = useTicketsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _departmentsContext = useDepartmentsContext();
  const _currentUserContext = useCurrentUserContext();
  const validation = useValidation();

  const { toast } = useToast();

  const isEditing = formData !== undefined && "id" in formData;

  // let _project = _effortUtils.getParentProject(
  //   _mainContext.efforts!,
  //   isEditing ? formData.id : parentId
  // );
  const isProject = !parentId;

  let _tags = _tagsContext.tags;
  let _types = _typesContext.types;
  let _states = _statesContext.states;
  let _tickets = _ticketsContext.tickets;
  let _prefixs = _prefixsContext.prefixs;
  let _priorities = _prioritiesContext.priorities;
  let _departments = _departmentsContext.departments;

  // let _states = _project?.states;
  // let _tags = _project?.tags;
  // let _types = _project?.types;
  // let _prefixs = _project?.prefixes;
  // let _priorities = _project?.priorities;
  // let _tickets = _project?.ticketsAvailable;

  // if (isProject && _currentUserContext.isSudo()) {
  // }

  let _errorContent = undefined;
  if (_states?.length === 0) {
    _errorContent = `You need to add a "State" ${
      isProject ? "" : "to your project"
    } before you can create tasks!`;
  } else if (_priorities?.length === 0) {
    _errorContent = `You need to add a "Priority" ${
      isProject ? "" : "to your project"
    } before you can create tasks!`;
  } else if (_tickets?.length === 0) {
    _errorContent = `You need to add a "Ticket" type ${
      isProject ? "" : "to your project"
    } before you can create tasks!`;
  } else if (_departments?.length === 0) {
    _errorContent = `You need to add a "Department" ${
      isProject ? "" : "to your project"
    } before you can create subtasks!`;
  }

  if (_errorContent) {
    _modalContext.dismiss();
    toast({
      variant: "destructive",
      description: _errorContent,
    });
    return null;
  }

  return (
    <DialogContent
      size="lg"
      title={
        isEditing
          ? title
            ? `Editing "${title}"`
            : "Editing"
          : isProject
          ? "Creating New Project"
          : "Adding New Task" + (title ? ` In "${title}"` : "")
      }
    >
      <Form
        data={
          isEditing
            ? {
                ...formData,
                dateDue: dateGetOnlyDate(formData.dateDue),
                departmentId: formData.department?.id,
                stateId: formData.state?.id,
                prefixId: formData.prefixId,
                priorityId: formData.priority?.id,
                tagList: formData.tags.map((e: Tag) => e.id),
                typeList: formData.types.map((e: Type) => e.id),
                stateList: formData.states.map((e: State) => e.id),
                prefixList: formData.prefixes.map((e: Prefix) => e.id),
                priorities: formData.priorities.map((e: Priority) => e.id),
                ticketsAvailable: formData.ticketsAvailable?.map(
                  (e: Ticket) => e.id
                ),
              }
            : {
                departmentId: isProject ? listFirst(_departments)?.id : null,
                stateId: listFirst(_states)?.id,
                priority: listFirst(_priorities)?.id,
                // prefixId: listFirst( _prefixsContext.prefixs),
                tagList: [],
                typeList: [],
                stateList: [],
                prefixList: [],
                priorities: [],
                ticketsAvailable: [],
                ticketsCount: [],
                avatarUrl: null,
                isActive: true,
                creatorId: _currentUserContext.user?.id,
              }
        }
        onSubmit={async (d) => {
          let _d = {
            ...d,

            tags: _tags?.filter((e) => d.tagList?.includes(e.id)) ?? [],
            types: _types?.filter((e) => d.typeList?.includes(e.id)) ?? [],
            states: _states?.filter((e) => d.stateList?.includes(e.id)) ?? [],
            prefixes:
              _prefixs?.filter((e) => d.prefixList?.includes(e.id)) ?? [],
            priorities:
              _priorities?.filter((e) => d.priorities?.includes(e.id)) ?? [],
            ticketsAvailable:
              _tickets?.filter((e) => d.ticketsAvailable?.includes(e.id)) ?? [],
            ticketsCount:
              d.ticketsCount?.map((eachCount: TicketCount) => ({
                ...eachCount,
                ticket: _tickets?.find((a) => a.id === eachCount.ticketId),
              })) ?? [],

            department: _departments?.find((e) => e.id === d.departmentId),
            state: _states?.find((e) => e.id === d.stateId),
            priority: _priorities?.find((e) => e.id === d.priorityId),
            prefix: _prefixs?.find((e) => e.id === d.prefixId),
          };

          return await onSubmit(_d);
        }}
      >
        <div className="row m-0 p-0">
          <div className="col-12 lg:col-6 lg:col-6 px-2">
            <Input
              name="title"
              required
              autoFocus
              validations={[
                validation.notSpecificCharsUsed([
                  "\\",
                  "/",
                  ":",
                  "*",
                  '"',
                  "<",
                  ">",
                  "|",
                  "?",
                  "=",
                  "#",
                  "'",
                ]),
              ]}
            />

            <Switch name="isActive" label="Active" />

            <Select
              name="stateId"
              label="State"
              required
              options={objList2Options(_states)}
            />

            {isProject && (
              <div className="flex-1">
                <Select
                  name="departmentId"
                  label="Department"
                  required
                  options={objList2Options(_departments)}
                />
              </div>
            )}

            <Select
              name="priorityId"
              label="Priority"
              required
              options={objList2Options(_priorities)}
            />

            <DatePickerInput
              name="dateDue"
              label="Deadline"
              fromDate={isEditing ? undefined : dateGetToday()}
            />
          </div>

          <div className="col-12 lg:col-6 px-2">
            <Separator className="lg:hidden" />

            {isProject ? (
              <>
                <Select
                  name="stateList"
                  label="states List"
                  multiple
                  options={objList2Options(_states)}
                />

                <Select
                  name="prefixList"
                  label="prefixes"
                  multiple
                  options={objList2Options(_prefixs)}
                />

                <Select
                  name="ticketsAvailable"
                  label="ticket types"
                  multiple
                  options={objList2Options(_tickets)}
                />

                <Select
                  name="priorities"
                  label="priority types"
                  multiple
                  options={objList2Options(_priorities)}
                />
              </>
            ) : (
              <>
                <TicketsCountInputField
                  name="ticketsCount"
                  label="Tickets Count"
                  tickets={_tickets}
                />

                <Select
                  name="prefixId"
                  label="Prefix"
                  options={objList2Options(_prefixs)}
                />
              </>
            )}

            <Select
              name="tagList"
              label="tags"
              multiple
              options={objList2Options(_tags)}
            />

            <Select
              name="typeList"
              label="types"
              multiple
              options={objList2Options(_types)}
            />
          </div>
        </div>

        <Separator className="hidden lg:block bg-muted mb-4" />

        <div className="px-2">
          <TextEditor name="description" label="Description" isAdvance />
        </div>

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
