import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Department, {
  DepartmentFilter,
  DepartmentSortTypes,
} from "models/Department";

import useDepartmentApi from "hooks/api/UseDepartmentApi";
import { listUnique, listFlatten } from "services/UtilServices";

import { MainContext } from "./MainProvider";
import { EffortsContext } from "./EffortsProvider";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface DepartmentsContextProps {
  departments: Department[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: DepartmentFilter) => Promise<void>;
  getAll: (parentId?: number | null) => Promise<Dict | undefined>;
  filter: DepartmentFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const DepartmentsContext = React.createContext({} as DepartmentsContextProps);
DepartmentsContext.displayName = "DepartmentsContext";

function DepartmentsProvider({ children }: { children: React.ReactNode }) {
  const itemsRef = React.useRef<Department[] | undefined>(undefined);
  const globalFilterRef = React.useRef<DepartmentFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: DepartmentSortTypes.ADDWHEN,
  });

  const [_items, _setDepartments] = React.useState<Department[]>();
  const [_filter, _setFilter] = React.useState<DepartmentFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const departmentApi = useDepartmentApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = React.useContext(MainContext);
  const _currentUserContext = React.useContext(CurrentUserContext);
  const _effortsContext = React.useContext(EffortsContext);

  React.useEffect(() => {
    setDepartments(undefined);
    setFilter({
      pageNumber: -1,
      ascOrder: false,
      sortBy: DepartmentSortTypes.ADDWHEN,
    });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setDepartments(undefined);
      _setFilter({
        pageNumber: -1,
        ascOrder: false,
        sortBy: DepartmentSortTypes.ADDWHEN,
      });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  const setDepartments = (o?: Department[]) => {
    itemsRef.current = o;
    _setDepartments(o);
  };

  const setFilter = (o: DepartmentFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async (parentId?: number | null) => {
    let _effort = _effortUtils.getEffortById(
      _effortsContext?.currentEffort?.id
    );

    if (!parentId && !_effort?.parentId && _currentUserContext.isSudo()) {
      return await get({
        ..._filter,
        pageNumber: 0,
        numberInPage: 999,
      });
    }

    let _result = _effortsContext.currentEffort
      ? [
          _effortUtils.getParentProject(_effortsContext.currentEffort.id)!
            .department!,
        ]
      : listUnique(
          _mainContext
            .efforts!.filter((e) => _effortUtils.isProject(e))
            .map((e) => e.department)
            .filter((e) => e !== undefined || e !== null) as Department[]
        );

    _result?.sort((a, b) => a.id - b.id);

    setDepartments(_result);
    return _result;
  };

  const get = async (filter: DepartmentFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setDepartments(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setDepartments(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await departmentApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setDepartments([...(itemsRef.current ?? []), ...(items as Department[])]);
    } catch (e) {
      toast({
        description: (e as AxiosError).message,
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setDepartments([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await departmentApi.create(formData);

      setDepartments([formData as Department, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await departmentApi.update(formData);

      setDepartments(
        itemsRef.current?.map((e) =>
          e.id !== formData.id ? e : (formData as Department)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          department:
            eachEffort.department?.id === formData.id
              ? (formData as Department)
              : eachEffort.department,
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Department[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await departmentApi.remove(ids);

      setDepartments(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <DepartmentsContext.Provider
      value={
        {
          departments: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as DepartmentsContextProps
      }
    >
      {children}
    </DepartmentsContext.Provider>
  );
}

export function useDepartmentsContext() {
  const _context = React.useContext(DepartmentsContext);

  if (!_context) {
    throw new Error("cannot use DepartmentsContext outside of its provider.");
  }

  return _context;
}

export { DepartmentsContext, DepartmentsProvider };
export type { DepartmentsContextProps };
