import React from "react";

import MainLayout from "layouts/MainLayout";

export function Error404PageBody() {
  console.log(window.location);

  return (
    <div className="h-full flex flex-col items-center justify-center text-foreground">
      <h1>404</h1>
      <h6>Not Found!</h6>
    </div>
  );
}

function Error404Page() {
  return (
    <MainLayout title="Error 404!" back>
      <Error404PageBody />
    </MainLayout>
  );
}

export default Error404Page;
