import React from "react";

//@ts-ignore
import defaultImg from "images/default.jpg";

import Skeleton from "./Skeleton";
import useExportApi from "hooks/api/UseExportApi";

var ImageSizes = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
};

var ImageDirs = {
  IMAGES: "Images",
  VOICES: "Voices",
  PROJECTS: "Projects",
  USER_AVATAR: "UserAvatars",
};

type SrcType = string | null | undefined;

export type NetworkImageProps = {
  src: SrcType | Blob;
  dir?: string[];
  size?: string;
  secure?: boolean;
  onLoaded?: () => void;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, "dir">;

function NetworkImage({
  src,
  dir = [ImageDirs.USER_AVATAR],
  size = ImageSizes.SMALL,
  secure = true,
  onLoaded,
  ...props
}: NetworkImageProps) {
  const loadingRef = React.useRef(false);
  const prevSrcRef = React.useRef<SrcType>();

  const exportApi = useExportApi();

  const [_src, _setSrc] = React.useState<SrcType>(prevSrcRef.current);

  const setSrc = (s: SrcType) => {
    prevSrcRef.current = s;
    _setSrc(s);
  };

  const getSecurlyFromServer = (src: string) => {
    loadingRef.current = true;

    exportApi
      .getImageSecurly(src)
      .then((r) => {
        setSrc(r);
        onLoaded?.();
      })
      .catch((r) => {
        setSrc(defaultImg);
        onLoaded?.();
      });

    loadingRef.current = false;
  };

  React.useEffect(() => {
    // console.log("image useEffect", _src, src)

    if (_src === src) {
      return;
    }

    if (
      src === "" ||
      src === null ||
      src === undefined ||
      src === "default.jpeg"
    ) {
      setSrc(defaultImg);
      return;
    }

    if (typeof src === "string") {
      if (src.includes("blob") || src.includes("base64")) {
        setSrc(src);
      } else if (secure) {
        getSecurlyFromServer(src);
      } else {
        setSrc(src);
      }
    } else {
      setSrc(URL.createObjectURL(src));
    }
  }, [src]);

  return (
    <>
      {_src ? (
        <img
          {...props}
          loading="lazy"
          src={_src ?? defaultImg}
          onError={(e) => {
            e.currentTarget.onerror = null; // prevents looping
            // e.currentTarget.src = defaultImg;
            _setSrc(defaultImg);
          }}
        />
      ) : (
        <Skeleton
          {...props}
          style={{
            ...props.style,
            width: props.width,
            height: props.height,
          }}
        />
      )}
    </>
  );
}

export { ImageSizes, ImageDirs, NetworkImage };
