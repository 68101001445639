import Cheeps from "components/common/Cheeps";
import { FieldType } from "components/common/ItemsListSection/ItemsListProvider";
import LocalDateTime from "components/common/LocalDateTime";
import Skeleton from "components/common/Skeleton";
import Truncate from "components/common/Truncate";
import DateDueCheeps from "components/pages/DateDueCheeps";
import MiniMembersSection from "components/pages/EachEffort/MembersSection/MiniMembersSection";
import EffortCreateUpdateModal from "components/pages/EffortsSection/EffortCreateUpdateModal";
import EffortFilterModal from "components/pages/EffortsSection/EffortFilterModal";
import EffortStateUpdateModal from "components/pages/EffortsSection/EffortStateUpdateModal";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import useAuthUtils from "hooks/UseAuthUtils";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import Dict from "models/Dict";
import Effort, { EffortFilter } from "models/Effort";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useDepartmentsContext } from "providers/DepartmentsProvider";
import {
  useEffortsContext,
  UpdateStateInputType,
} from "providers/EffortsProvider";
import { useMainContext } from "providers/MainProvider";
import { useModalContext } from "providers/ModalProvider";
import { usePrefixsContext } from "providers/PrefixsProvider";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import { useStatesContext } from "providers/StatesProvider";
import { useTagsContext } from "providers/TagsProvider";
import { useTicketsContext } from "providers/TicketsProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useUsersContext } from "providers/UsersProvider";
import {
  isEmpty,
  cn,
  isFarsi,
  dateGetTime,
  isDateUntil,
  dateCompare,
  objRemoveKeys,
} from "services/UtilServices";
import useSingleFieldUpdate from "components/pages/EffortsSection/SingleFieldUpdateButton";
import useTicketCountUtils from "./UseTicketCountUtils";
import { MemberAccessTypes } from "models/Member";
import useUserUtils from "./UseUserUtils";
import TicketCount from "models/TicketCount";
import useCommentUtils from "./UseCommentUtils";
import TableFillCell from "components/pages/EffortsSection/TableFillCell";
import { useNavigate } from "react-router";
import { UserRoles } from "models/User";

export function useEffortViewUtils(parentId?: number | null) {
  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _usersContext = useUsersContext();
  const _statesContext = useStatesContext();
  const _prefixsContext = usePrefixsContext();
  const _ticketsContext = useTicketsContext();
  const _prioritiesContext = usePrioritiesContext();
  const _departmentsContext = useDepartmentsContext();
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const _effortUtils = useEffortUtils();
  const _ticketCountUtils = useTicketCountUtils();
  const singleFieldEdit = useSingleFieldUpdate();
  const navigate = useNavigate();

  const onRemove = async (datas: Effort[]) => {
    return await _effortsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _effortsContext.update(data)
        : await _effortsContext.create(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openUpdateStateModal = (items: Effort[]) => {
    _statesContext.getAll(parentId);

    _modalContext.open(
      <EffortStateUpdateModal
        parentId={parentId}
        effortIds={items.map((e) => e.id)}
        onSubmit={onUpdateStateSubmit}
      />
    );
  };

  const onUpdateStateSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.updateState(
      data as UpdateStateInputType
    );

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _tagsContext.getAll(parentId);
    _typesContext.getAll(parentId);
    _statesContext.getAll(parentId);
    _prefixsContext.getAll(parentId);
    _ticketsContext.getAll(parentId);
    _prioritiesContext.getAll(parentId);
    _departmentsContext.getAll(parentId);

    // _isHome
    //     ? "Project Information"
    //     : _isProject
    //     ? "Task Information"
    //     : "SubTask Information"
    _modalContext.open(
      <EffortCreateUpdateModal
        formData={formData}
        onSubmit={onSubmit}
        parentId={parentId}
      />
    );
  };

  const onFilterSubmit = (formData: Omit<EffortFilter, "pageNumber">) => {
    _effortsContext.get({
      ...formData,
      pageNumber: -1,
    });

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    console.log(parentId);
    _tagsContext.getAll(parentId);
    _typesContext.getAll(parentId);
    _usersContext.getAll(parentId);
    _statesContext.getAll(parentId);
    _prefixsContext.getAll(parentId);
    _departmentsContext.getAll(parentId);
    _prioritiesContext.getAll(parentId);
    _ticketsContext.getAll(parentId);

    _modalContext.open(
      <EffortFilterModal
        filter={_effortsContext.filter}
        onSubmit={(d) => onFilterSubmit(d as EffortFilter)}
        parentId={parentId}
      />
    );
  };

  const getItemsListFields = () => {
    const _isHome = _effortsContext.currentEffort === undefined;
    const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
    const _isTask = !_isHome && !_isProject;

    return [
      {
        value: "index",
        label: "#",
        className: "text-start ",
        width: "50px",
        isSortable: true,
        // getUiContent: (e, i) => _isHome ? i+1 : extractIndexFromUrl(e.url),
        // getValue: _isHome ? undefined : (e) => parseInt(extractIndexFromUrl(e.url)),
      },

      ...(_isProject || _isTask
        ? ([
            {
              value: "prefix",
              label: "prefix",
              width: "auto",
              isSortable: false,
              className: "text-center",
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "prefix",
                }),
              getUiContent: (e, i) => e.prefix?.name,
            },
          ] as FieldType<Effort>[])
        : []),

      {
        value: "title",
        label: "title",
        isTitleField: true,
        // width: "auto",
        // isSortable: false,
        className: "text-start ",
        onClick: (e) => navigate(e.url!),
        getUiContent: (e, i) => (
          <span
            className={cn("capitalize inline-block ", {
              "direction-rtl": isFarsi(e.title),
            })}
          >
            {e.title}
          </span>
        ),
      },

      ...(_isHome || _isProject
        ? ([
            {
              value: "subTasksCount",
              label: _isHome ? "#tasks" : "#sub task",
              width: "auto",
              className: "text-center",
              // isSortable: false,
              // getUiContent: (e, i) => e.subTasksCount,
              // getValue: (e) => e.subTasksCount,
            },
          ] as FieldType<Effort>[])
        : []),

      {
        value: "effortActivityRecordsCount",
        label: "#Activities",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        // getUiContent: (e, i) => e.subTasksCount,
        // getValue: (e) => e.activityRecordsCount,
      },

      ...(_isHome
        ? ([
            {
              value: "department",
              label: "Department",
              width: "auto",
              className: "text-center",
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "department",
                }),
              getUiContent: (e, i) => e.department?.name,
              getValue: (e) => e.department?.name,
            },
          ] as FieldType<Effort>[])
        : []),

      {
        value: "creator",
        label: "creator",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        getUiContent: (e) =>
          e.creator ? <ProfileAvatar user={e.creator} /> : <Skeleton />,
        getValue: (e) => e.creator?.userName ?? "",
      },

      {
        value: "state",
        label: "state",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "state",
          }),
        getUiContent: (e, i) => (
          <TableFillCell color={e.state === null ? undefined : e.state?.color}>
            {e.state === null ? "no state" : e.state?.name}
          </TableFillCell>
        ),
        getValue: (e) => (e.state === null ? "no state" : e.state?.name ?? ""),
      },

      {
        value: "priority",
        label: "priority",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "priority",
          }),
        getUiContent: (e, i) => (
          <TableFillCell
            color={e.priority === null ? undefined : e.priority?.color}
          >
            {e.priority === null ? "no priority" : e.priority?.name}
          </TableFillCell>
        ),
        getValue: (e) =>
          e.priority === null ? "no priority" : e.priority?.name ?? "",
      },
      {
        value: "members",
        label: "members",
        width: "auto",
        isSortable: false,
        getUiContent: (p) =>
          p.members === undefined ? (
            <Skeleton />
          ) : (
            <MiniMembersSection effort={p} />
          ),
      },

      {
        value: "dateAdd",
        label: "Created",
        width: "100px",
        // isSortable: false,
        className: "text-center",
        getValue: (e) => dateGetTime(e.dateAdd),
        getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
      },

      {
        value: "dateDue",
        label: "Deadline",
        width: "100px",
        // isSortable: false,
        className: "text-center",
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "dateDue",
          }),
        getValue: (e) => dateGetTime(e.dateDue),
        getUiContent: (e, i) => <DateDueCheeps effort={e} />,
      },

      {
        value: "tags",
        label: "tags",
        width: "auto",
        isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "tags",
          }),
        getUiContent: (p) =>
          p.tags === undefined ? (
            <Skeleton />
          ) : (
            <Truncate>
              {p.tags?.map((e, i) => (
                <Cheeps key={"eachTag" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
      },

      {
        value: "types",
        label: "types",
        width: "auto",
        isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "types",
          }),
        getUiContent: (p) =>
          p.types === undefined ? (
            <Skeleton />
          ) : (
            <Truncate>
              {p.types?.map((e, i) => (
                <Cheeps key={"eachType" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
      },

      ...(_isHome
        ? ([
            {
              value: "states",
              label: "states",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "states",
                }),
              getUiContent: (p) =>
                p.states === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.states?.map((e, i) => (
                      <Cheeps key={"eachType" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "prefixes",
              label: "prefixes",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "prefixes",
                }),
              getUiContent: (p) =>
                p.prefixes === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.prefixes?.map((e, i) => (
                      <Cheeps key={"eachPrefix" + i}>{e.name}</Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "ticketsAvailable",
              label: "tickets",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "Available tickets",
                }),
              getUiContent: (p) =>
                p.ticketsAvailable === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.ticketsAvailable?.map((e, i) => (
                      <Cheeps key={"eachType" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "priorities",
              label: "priorities",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "priorities",
                }),
              getUiContent: (p) =>
                p.priorities === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.priorities?.map((e, i) => (
                      <Cheeps key={"eachPriority" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      // ...(_isHome
      // ? ([
      {
        value: "effortTicketSum",
        label: _isHome ? "effortTicketSum" : "Tickets",
        width: "auto",
        // isSortable: false,
        className: "whitespace-nowrap",
        onClick: _isHome
          ? undefined
          : (e) =>
              singleFieldEdit.onClick({
                effort: e,
                fieldName: "tickets Count",
              }),
        getUiContent: (e) => (
          <TicketsCheepsSection ticketCounts={e.ticketsCount} visibleIfEmpty />
        ),
        getValue: (e) => _ticketCountUtils.sumTotal(e.ticketsCount),
      },
      // ] as FieldType<Effort>[])
      // : []),

      // ...(_isHome
      // ? ([
      {
        value: "activitySum",
        label: "activitySum",
        width: "auto",
        // isSortable: false,
        className: "whitespace-nowrap",
        getUiContent: (e) => (
          <TicketsCheepsSection ticketCounts={e.activitySum} />
        ),
        getValue: (e) => _ticketCountUtils.sumTotal(e.activitySum),
      },
      // ] as FieldType<Effort>[])
      // : []),

      // ...(_isHome
      // ? ([

      {
        value: "activitySumApproved",
        label: "activitySumApproved",
        width: "auto",
        // isSortable: false,
        getUiContent: (e) => (
          <TicketsCheepsSection
            ticketCounts={e.activitySum}
            fieldName="countApproved"
          />
        ),
        getValue: (e) =>
          _ticketCountUtils.sumTotal(e.activitySum, "countApproved"),
      },
    ] satisfies FieldType<Effort>[];
  };

  return {
    onRemove,
    openUpdateStateModal,
    onUpdateStateSubmit,
    openCreateUpdateModal,
    openFilterModal,
    getItemsListFields,
    onFilterSubmit,
  };
}

export function useEffortAuthUtils() {
  const _authUtils = useAuthUtils();
  const _effortUtils = useEffortUtils();
  const _currentUserContext = useCurrentUserContext();
  const _userUtils = useUserUtils();

  const canCreate = (parentId?: number | null) => {
    let accessType = _authUtils!.authorize(parentId);
    return accessType >= MemberAccessTypes.Create;
  };

  const canEdit = (items: Effort[]) => {
    if (_userUtils.isSudo(_currentUserContext.user)) {
      return true;
    } else if (items.some((e) => !e.isActive)) {
      return false;
    }

    let accessType = Math.min(...items.map((e) => _authUtils!.authorize(e.id)));

    return (
      accessType >= MemberAccessTypes.Edit ||
      (accessType >= MemberAccessTypes.Create &&
        items.every((e) => !_effortUtils.isProject(e)) &&
        items.every((e) => _currentUserContext.user?.id === e.creatorId))
    );
  };

  const canDelete = (items: Effort[]) => {
    if (_currentUserContext.user?.role === UserRoles.ADMIN) {
      return true;
    } else if (items.some((e) => !e.isActive || _effortUtils.isProject(e))) {
      return false;
    }

    let accessType = Math.min(...items.map((e) => _authUtils!.authorize(e.id)));

    return (
      accessType >= MemberAccessTypes.Delete || //items.every((e) => !_effortUtils.isProject(e))
      (accessType >= MemberAccessTypes.Edit && //items.every((e) => !_effortUtils.isProject(e))
        items.every((e) => _currentUserContext.user?.id === e.creatorId)) ||
      (accessType >= MemberAccessTypes.Create && //items.every((e) => !_effortUtils.isProject(e))
        items.every((e) => _currentUserContext.user?.id === e.creatorId) &&
        items.every((e) => isDateUntil({ date: e.dateAdd, minutes: 2 })))
    );
  };

  return {
    canCreate,
    canEdit,
    canDelete,
  };
}

export function useEffortUtils() {
  const _mainContext = useMainContext();
  const _commentUtils = useCommentUtils();
  const textEditorUtils = useTextEditorUtils();

  const getEffortByUrl = ({
    projTitle,
    taskUrl,
    subtaskUrl,
  }: {
    projTitle?: string;
    taskUrl?: number;
    subtaskUrl?: number;
  }) => {
    let _result: Effort | undefined = undefined;

    const proj = _mainContext.efforts?.find(
      (e) => e.title.trim().toLowerCase() === projTitle?.trim().toLowerCase()
    );
    if (proj !== undefined) {
      _result = proj;
      const task = getSubEfforts(proj.id)?.find(
        (e) => e.url === taskUrl?.toString().trim().toLowerCase()
      );
      if (task !== undefined) {
        _result = task;
        const subtask = getSubEfforts(task.id)?.find(
          (e) => e.url === subtaskUrl?.toString().trim().toLowerCase()
        );
        if (subtask !== undefined) {
          _result = subtask;
        }
      }
    }

    return _result;
  };

  const isProject = (effort?: Effort | Dict) => {
    return (
      effort && (effort?.parentId === null || effort?.parentId === undefined)
    );
  };

  const isFilterActive = ({
    filter,
    parentId = null,
  }: {
    filter: EffortFilter;
    parentId?: number | null;
  }) => {
    return Object.values(
      objRemoveKeys(filter, [
        "pageNumber",
        "ascOrder",
        "sortBy",
        "numberInPage",
        "parentId",
        ...(parentId === null ? [] : ["departmentId"]),
      ])
    ).some((a) => !isEmpty(a));
    // return hasOtherKeysExcept(filter, [
    //   "pageNumber",
    //   "ascOrder",
    //   "sortBy",
    //   "numberInPage",
    //   "parentId",
    //   ...(parentId === null ? [] : ["departmentId"]),
    // ]);
  };

  const getUrl = (effortId?: number) => {
    let _result = undefined;

    let effort = getEffortById(effortId);
    if (effort) {
      const parentEffortsDeep = [...getParentEffortsDeep(effort.id), effort];

      const proj = parentEffortsDeep.find((e) => isProject(e));
      if (proj) {
        const tasks = parentEffortsDeep.filter((e) => e.id !== proj.id);
        _result =
          "/effort/" + [proj.title, ...tasks.map((e) => e.url)].join("/");
      }
    }

    return _result;
  };

  const getParentProject = (effortId?: number | null) => {
    let _effort = getEffortById(effortId);
    let _efforts = getParentEffortsDeep(effortId);

    return _efforts.length === 0 ? _effort : _efforts[_efforts.length - 1];
  };

  const getParentEffortsDeep = (effortId?: number | null) => {
    let _result: Effort[] = [];

    let effort = getEffortById(effortId);
    if (effort) {
      let _currentParent = getEffortById(effort.parentId);
      while (_currentParent !== undefined) {
        _result.push(_currentParent);
        _currentParent = getEffortById(_currentParent.parentId);
      }
    }

    return _result;
  };

  const getEffortById = (effortId?: number | null) => {
    return _mainContext.efforts?.find((e) => e.id === effortId);
  };

  const serverResponse2Effort = (d: Dict) => {
    return {
      ...d,

      description: textEditorUtils.server2Dict(d.description),

      members:
        d.memberships?.map((eachMember: Dict) => ({
          ...eachMember,
          isDeleted: eachMember.recordStatus === 1,
        })) ?? undefined,

      prefixes: d.prefixes ?? undefined,
      states: d.states ?? undefined,
      priorities: d.priorities ?? undefined,
      attachments: d.attachments ?? undefined,
      types: d.types ?? undefined,
      tags: d.tags ?? undefined,
      comments:
        d.comments?.map((e: Dict) => _commentUtils.serverResponse2Comment(e)) ??
        undefined,
      ticketsAvailable: d.ticketsAvailable ?? undefined,
      ticketsCount:
        d.ticketsCount?.map((e: TicketCount) => ({
          ...e,
          ticketId: e.ticketId ?? e.ticket?.id,
        })) ?? undefined,

      departmentId: d.departmentId ?? undefined,
      department: d.department ?? undefined,
      creatorId: d.creatorId ?? undefined,
      creator: d.creator ?? undefined,
      prefix: d.prefix ?? undefined,
      effortActivityRecordsCount: d?.effortActivityRecordsCount ?? 0,
      // state: d.state ?? undefined,
      // priority: d.priority ?? undefined,
    } as Effort;
  };

  const getSubEfforts = (effortId?: number) => {
    return _mainContext.efforts?.filter((e) => e.parentId === effortId);
  };

  const getSubEffortsDeep = ({
    id,
    includeSelf = true,
  }: {
    id?: number;
    includeSelf?: boolean;
  }) => {
    let effort = _mainContext.efforts?.find((e) => e.id === id);
    let _result: Effort[] = [];

    if (effort) {
      const subWfforts = getSubEfforts(id) ?? [];
      for (const eachEffort of subWfforts) {
        _result = [..._result, ...getSubEffortsDeep({ id: eachEffort.id })];
      }

      if (includeSelf) _result = [effort, ..._result];
    }

    return _result;
  };

  const filter = (
    efforts?: Effort[],
    filter?: Omit<EffortFilter, "pageNumber" | "ascOrder">
  ) => {
    return efforts?.filter((e) => isMatch(e, filter));
  };

  const isMatch = (
    effort: Effort,
    filter?: Omit<EffortFilter, "pageNumber" | "ascOrder">
  ) => {
    let _result = true;

    if (filter?.title) {
      _result =
        _result &&
        effort.title.toLowerCase().includes(filter.title.toLowerCase());
    }

    if (!isEmpty(filter?.stateIds)) {
      _result =
        _result &&
        !isEmpty(effort.state) &&
        filter!.stateIds!.includes(effort.state!.id);
    }

    if (!isEmpty(filter?.priorityIds)) {
      _result =
        _result &&
        !isEmpty(effort.priority) &&
        filter!.priorityIds!.includes(effort.priority!.id);
    }

    if (!isEmpty(filter?.tagIds)) {
      _result =
        _result &&
        !isEmpty(effort.tags) &&
        filter!.tagIds!.some((e) => effort.tags?.some((a) => a.id === e));
    }

    if (!isEmpty(filter?.typeIds)) {
      _result =
        _result &&
        !isEmpty(effort.types) &&
        filter!.typeIds!.some((e) => effort.types?.some((a) => a.id === e));
    }

    if (!isEmpty(filter?.ticketIds)) {
      _result =
        _result &&
        !isEmpty(effort.ticketsAvailable) &&
        filter!.ticketIds!.some((e) =>
          effort.ticketsAvailable?.some((a) => a.id === e)
        );
    }

    if (!isEmpty(filter?.prefixIds) && effort.parentId !== null) {
      _result =
        _result &&
        !isEmpty(effort.prefix) &&
        filter!.prefixIds!.includes(effort.prefix!.id);
    }

    if (!isEmpty(filter?.prefixIds) && effort.parentId === null) {
      _result =
        _result &&
        !isEmpty(effort.prefixes) &&
        filter!.prefixIds!.some((e) =>
          effort.prefixes?.some((a) => a.id === e)
        );
      // _result = _result && !isEmpty(effort.prefix) && filter!.prefixIds!.includes(effort.prefix!.id)
    }

    if (!isEmpty(filter?.departmentIds) && effort.parentId === null) {
      _result =
        _result &&
        !isEmpty(effort.department) &&
        filter!.departmentIds!.includes(effort.department!.id);
    }

    if (!isEmpty(filter?.creatorIds)) {
      _result =
        _result &&
        !isEmpty(effort.creator) &&
        filter!.creatorIds!.includes(effort.creatorId!);
    }

    if (!isEmpty(filter?.memberIds)) {
      _result =
        _result &&
        !isEmpty(effort.members) &&
        filter!.memberIds!.some((e) =>
          effort.members?.some((a) => !a.isDeleted && a.userId === e)
        );
    }

    if (filter?.dateAddFrom) {
      _result =
        _result &&
        Boolean(effort.dateAdd) &&
        dateCompare(effort.dateAdd, filter.dateAddFrom)! >= 0;
    }

    if (filter?.dateAddTo) {
      _result =
        _result &&
        Boolean(effort.dateAdd) &&
        dateCompare(effort.dateAdd, filter.dateAddTo)! <= 0;
    }

    if (filter?.dateDueFrom) {
      _result =
        _result &&
        Boolean(effort.dateDue) &&
        dateCompare(effort.dateDue, filter.dateDueFrom)! >= 0;
    }

    if (filter?.dateDueTo) {
      _result =
        _result &&
        Boolean(effort.dateDue) &&
        dateCompare(effort.dateDue, filter.dateDueTo)! <= 0;
    }

    if (filter?.isActive !== undefined && filter.isActive !== null) {
      _result = _result && effort.isActive === filter.isActive;
    }

    if (filter?.timeMaxFrom) {
      _result =
        _result &&
        Boolean(effort.timeMax) &&
        effort.timeMax!.localeCompare(filter.timeMaxFrom) >= 0;
    }
    if (filter?.timeMaxTo) {
      _result =
        _result &&
        Boolean(effort.timeMax) &&
        effort.timeMax!.localeCompare(filter.timeMaxTo) >= 0;
    }

    if (filter?.timeEstimateFrom) {
      _result =
        _result &&
        Boolean(effort.timeEstimate) &&
        effort.timeEstimate!.localeCompare(filter.timeEstimateFrom) >= 0;
    }
    if (filter?.timeEstimateTo) {
      _result =
        _result &&
        Boolean(effort.timeEstimate) &&
        effort.timeEstimate!.localeCompare(filter.timeEstimateTo) >= 0;
    }

    if (filter?.timeApprovedFrom) {
      _result =
        _result &&
        Boolean(effort.timeApproved) &&
        effort.timeApproved!.localeCompare(filter.timeApprovedFrom) >= 0;
    }
    if (filter?.timeApprovedTo) {
      _result =
        _result &&
        Boolean(effort.timeApproved) &&
        effort.timeApproved!.localeCompare(filter.timeApprovedTo) >= 0;
    }

    if (filter?.timeActualFrom) {
      _result =
        _result &&
        Boolean(effort.timeActual) &&
        effort.timeActual!.localeCompare(filter.timeActualFrom) >= 0;
    }
    if (filter?.timeActualTo) {
      _result =
        _result &&
        Boolean(effort.timeActual) &&
        effort.timeActual!.localeCompare(filter.timeActualTo) >= 0;
    }

    return _result;
  };

  return {
    getEffortByUrl,
    isProject,
    isFilterActive,
    getUrl,
    getParentProject,
    getParentEffortsDeep,
    getEffortById,
    serverResponse2Effort,
    getSubEfforts,
    getSubEffortsDeep,
    filter,
    isMatch,
  };
}
