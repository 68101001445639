import React from "react";
import { useNavigate } from "react-router";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { cn, isEmpty } from "services/UtilServices";
import UserLog, {
  UserLogActionType,
  UserLogFilter,
  UserLogSortType,
  UserLogType,
} from "models/UserLog";

import { useMainContext } from "providers/MainProvider";
import { useEachEffortContext } from "providers/EachEffortProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import { useUsersContext, UsersProvider } from "providers/UsersProvider";
import {
  useUserLogsContext,
  UserLogsProvider,
} from "providers/UserLogsProvider";

import UserLogFilterModal from "./UserLogFilterModal";
import LoadingButton from "components/common/LoadingButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import LocalDateTime from "components/common/LocalDateTime";
import EmptyListIndicator from "components/common/EmptyListIndicator";

import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import { Badge } from "components/ui/badge";
import useEffectStrictMode from "hooks/UseEffectStrictMode";
import useUserLogUtils from "hooks/utils/UseUserLogUtils";
import { VscEye } from "react-icons/vsc";
import useMediaQuery from "components/common/useMediaQuery";

function EachUserLogTitleRow({
  userLog,
  index,
}: {
  userLog: UserLog;
  index: number;
}) {
  const _currentUserContext = useCurrentUserContext();

  return (
    <div className={cn("flex items-center flex-1 gap-1 ")}>
      <ProfileAvatar size={30} user={userLog.user} />
      <span className="text-xs">{userLog.user.userName}</span>

      <div className="flex-1"></div>

      {_currentUserContext.user?.logs?.some((a) => a.id === userLog.id) && (
        <div>
          <Badge>New</Badge>
        </div>
      )}
      <span className="text-xs">
        <LocalDateTime date={userLog.dateAdd} />
      </span>
    </div>
  );
}

function EachUserLogItem({
  userLog,
  index,
}: {
  userLog: UserLog;
  index: number;
}) {
  const _currentUserContext = useCurrentUserContext();

  const isActive = false;

  return (
    <div className="text-start whitespace-normal">
      <span className="">{userLog.action}</span>
    </div>
  );
}

export function UserLogsPageBody({
  sectionTitle,
}: {
  sectionTitle?: "Logs" | "Feed" | "Notifications";
}) {
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _usersContext = useUsersContext();
  const _userLogsContext = useUserLogsContext();
  const _eachEffortContext = useEachEffortContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortUtils = useEffortUtils();
  const userLogUtils = useUserLogUtils();
  const mediaQuery = useMediaQuery();

  const navigate = useNavigate();
  const isFeed = sectionTitle === "Feed";
  const isNotif = sectionTitle === "Notifications";

  const onFilterSubmit = (formData: Dict) => {
    _userLogsContext.get({
      ...formData,
      pageNumber: -1,
    } as UserLogFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _usersContext.getAll(_eachEffortContext.effort?.parentId);

    _modalContext.open(
      <UserLogFilterModal
        filter={_userLogsContext.filter}
        onSubmit={onFilterSubmit}
        effortId={_eachEffortContext.effort?.id}
      />
    );
  };

  const markAsRead = async () => {
    return await _currentUserContext.markLogsAsRead(
      _currentUserContext.user!.logs?.map((e) => e.id)
    );
  };

  const { data, onLoadMore, title } = useItemsListContext();
  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <div className="">
      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : (
        <ItemsListSection<UserLog>
          title={sectionTitle}
          data={
            isNotif
              ? _currentUserContext.user!.logs?.filter((e) =>
                  _mainContext.efforts?.some((a) => a.id === e.effortId)
                )
              : _userLogsContext.userLogs
          }
          tableBreakPointSize={"never"}
          btnTextResponsiveBreakPoint={"never"}
          fields={[
            // {
            //   value: "index",
            //   label: "#",
            //   className: "text-start whitespace-nowrap",
            //   width: "70px",
            //   isSortable: false,
            //   getUiContent: (e, i) => i + 1,
            // },

            {
              value: "",
              label: "New",
              width: "10px",
              isSortable: false,
              getUiContent: (e, i) => <>{e.isNew && <Badge>New</Badge>}</>,
            },

            {
              value: "user",
              label: "user",
              width: "auto",
              isSortable: !isFeed,
              getUiContent: (e, i) => <ProfileAvatar user={e.user} />,
              className: "whitespace-nowrap",
            },

            {
              value: "action",
              label: "action",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">{e.action}</span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "actionType",
              label: "Action Type",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">
                  {UserLogActionType.options[e.actionType]}
                </span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "type",
              label: "Type",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">
                  {UserLogType.options[e.type]}
                </span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "dateAdd",
              label: "date",
              width: "auto",
              isSortable: true,
              // getValue: (e) => dateGetTime(e.dateAdd),
              // getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
              // className: "whitespace-nowrap",
            },
          ]}
          onLoadMore={
            !_userLogsContext.hasNext || isNotif
              ? undefined
              : async () => {
                  await _userLogsContext.get({
                    ..._userLogsContext.filter,
                    pageNumber: _userLogsContext.filter.pageNumber + 1,
                  });
                }
          }
          onSortField={
            isNotif
              ? undefined
              : (v) => {
                  _userLogsContext.get({
                    ..._userLogsContext.filter,
                    sortBy: v as UserLogSortType,
                    pageNumber: -1,
                  });
                }
          }
          onSortDir={
            isNotif
              ? undefined
              : (v) => {
                  _userLogsContext.get({
                    ..._userLogsContext.filter,
                    ascOrder: v === 1,
                    pageNumber: -1,
                  });
                }
          }
          onSearch={null}
          initialSelectedSortFieldValue={"dateAdd"}
          buildCardTitleRow={(v, i) => (
            <EachUserLogTitleRow userLog={v} index={i} />
          )}
          buildCardBodyItem={(v, i) => (
            <EachUserLogItem userLog={v} index={i} />
          )}
          onItemClick={
            isFeed || isNotif
              ? (u) => () => {
                  const _url = _effortUtils.getUrl(u.effortId);

                  _modalContext.dismiss();
                  navigate(_url ? _url + "/Logs" : "/404/");
                }
              : undefined
          }
          actionsSection={
            <>
              {(isFeed || isNotif) &&
                !isEmpty(_currentUserContext.user!.logs) && (
                  <LoadingButton onClick={markAsRead}>
                    <VscEye />
                    {/* <span className="hidden md:inline ms-1">Mark As Read</span> */}
                  </LoadingButton>
                )}

              {!isNotif && (
                <FilterButton
                  needText={false}
                  className="border-0"
                  count={_userLogsContext.totalNumber}
                  onClick={openFilterModal}
                  isActive={userLogUtils.isFilterActive({
                    filter: _userLogsContext.filter,
                  })}
                />
              )}
              {/* 
              {isNotif &&
                (mediaQuery <= MediaQuery2Num.sm ? (
                  // <DialogClose />
                  <Button variant="faded" className="ms-auto" onClick={}>
                      <LuX />
                    </Button>
                ) : (
                  <PopoverClose>
                    <Button variant="faded" className="ms-auto">
                      <LuX />
                    </Button>
                  </PopoverClose>
                ))} */}
            </>
          }
        />
      )}
    </div>
  );
}

export default function UserLogsSection({
  effortId,
  onExitClear,
  type,
}: {
  effortId?: number;
  onExitClear?: boolean;
  type?: "Logs" | "Feed" | "Notifications";
}) {
  const _currentUserContext = useCurrentUserContext();

  useEffectStrictMode(() => {
    if (onExitClear) {
      _currentUserContext.markLogsAsRead(
        _currentUserContext
          .user!.logs?.filter((e) => e.effortId === effortId)
          .map((e) => e.id)
      );
    }
  });

  return (
    <UsersProvider>
      <UserLogsProvider effortId={effortId}>
        <ModalProvider>
          <UserLogsPageBody
            sectionTitle={type ?? (effortId ? "Logs" : "Feed")}
          />
        </ModalProvider>
      </UserLogsProvider>
    </UsersProvider>
  );
}
