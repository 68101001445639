import React from "react";

export default function useEffectStrictMode(
  callback: () => void,
  deps: React.DependencyList = []
) {
  const initialized = React.useRef(true); //process.env.NODE_ENV === "production"

  React.useEffect(() => {
    return () => {
      if (initialized.current) {
        callback();
      } else {
        initialized.current = true;
      }
    };
  }, deps);
}
